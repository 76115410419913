<template>

    <div class="avatar">
		<div v-bind="$attrs" :class="[disabled ? 'opacity-25' : '']">
			<img :src="this.icon.url" />
		</div>
	</div>

</template>

<script lang="ts">

import { Icon } from "@/models/Icon.model";

export default {
    inheritAttrs: false,

    props: {
        icon: {
            type: Icon,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
    }
}
</script>