<template>

    <div v-show="open" class="relative z-30" aria-labelledby="drawer-title" role="dialog" aria-modal="true">

        <Transition
                enter-from-class="opacity-0"
                enter-active-class="ease-out duration-300"
                enter-to-class="opacity-100"
                leave-from-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-to-class="opacity-0"
            >
            <div v-show="open" class="fixed inset-0 bg-black/30"></div>
        </Transition>

        <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition
                        enter-from-class="translate-x-full"
                        enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                        enter-to-class="translate-x-0"
                        leave-from-class="translate-x-0"
                        leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-to-class="translate-x-full"
                    >
                        <div v-show="open" v-bind="$attrs" id="smasy-drawer" class="pointer-events-auto relative w-screen bg-base">

                            <!-- <div class="md:absolute top-1 md:left-0 flex md:-ml-14">
                                <button class="btn btn-ghost btn-circle bg-base bg-opacity-40" @click="$emit('requestClose')">
                                    <span class="sr-only">Close panel</span>
                                    <XMarkIcon class="w-8 h-8"></XMarkIcon>
                                </button>
                            </div> -->
                            <div v-if="showDefaultCloseButton" class="absolute top-4 right-0 z-40 w-8 -translate-x-6">
                                <button
                                    class="p-3 border text-gray-600 active:bg-neutral active:text-base w-auto rounded-full shadow-none bg-base-100 hover:bg-base-200 border-base-100 scale-125"
                                    @click="$emit('requestClose')"
                                >
                                    <ChevronLeftIcon class="w-4 h-4 transition-transform duration-500 rotate-180" />
                                </button>
                            </div>

                            <div class="flex h-full flex-col py-6 shadow-xl">
                                <div class="px-4 sm:px-6">
                                    <slot name="header">
                                        <div class="text-md font-bold uppercase" id="drawer-title">
                                            {{ panelTitle }}
                                        </div>
                                    </slot>
                                </div>
                                <!-- <div class="relative mt-6 flex-1 px-4 sm:px-6"> -->
                                <div class="flex-grow overflow-y-auto overflow-x-hidden flex flex-col">

                                    <slot>
                                        <div class="diagonal-bg-pattern w-full h-96 max-h-full text-center pt-2">Panel content</div>
                                    </slot>

                                </div>
                            </div>
                        </div>
                    </Transition>

                </div>
            </div>
        </div>
    </div>

</template>


<style>
.diagonal-bg-pattern {
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
</style>


<script lang="ts">
import {
    XMarkIcon,
    ChevronLeftIcon,
 } from '@heroicons/vue/24/outline';

export default {
	inheritAttrs: false,

    props: {
        open: Boolean,
        panelTitle: String,
        showDefaultCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['requestClose'],
    data() {
        return {
            classEventListener: null,
        }
    },
    components: {
        XMarkIcon,
        ChevronLeftIcon
    },
    watch: {
        open(newState, oldState) {
            if(oldState === false && newState === true) {
                this.setFocusToFirstField();
            }
        }
    },
    methods: {
        setFocusToFirstField() {
            setTimeout(() => {
                // console.log('timeout enter');
                const firstInput = document.querySelector('#smasy-drawer input[autofocus');
                if (firstInput) {
                    // console.log(firstInput);
                    firstInput.focus();
                }
            }, 200);
        }
    },
    mounted() {


        this.classEventListener = document.addEventListener('applyClass', function(e) {
            // this.$refs['drawer'].classList.add()
            console.log(e);
        });
    },
    unmounted() {
        document.removeEventListener('applyClass', this.classEventListener);
        this.classEventListener = null;
    }
}
</script>
