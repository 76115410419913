import { defineStore } from "pinia";
import { Theme } from "@/models/Theme.model";
import { AgendaStore, AgendaStoreFilters, AgendaStoreState } from "@/stores/AbstractAgenda.store";
import { orgApi } from "@/utils/Api.util";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { Interval } from "luxon";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { useLessonsStore } from "@/stores/Lessons.store";
import AlteredEntityHelper from "@/stores/AlteredEntity.helper";


interface ThemeStoreState extends AgendaStoreState<Theme> {

}

const BaseStore = AgendaStore<Theme, ThemeStoreState>();

export const useThemesStore = defineStore('themes', {

	state: (): ThemeStoreState => ({
		errorMessage: null,
		events: [],
		loadingRanges: 0,
		loadedRanges: [],
		lastLoadedRange: null
	}),

	getters: {

		...BaseStore.getters,

		apiPath(state: ThemeStoreState) {
			return "themes";
		},

		defaultMask(state: ThemeStoreState) {
			return [
				'*',
				'goals.*',
			];
		},

	},

	actions: {

		...BaseStore.actions,

		new(interval: Interval): Theme {

			this.clearErrorMessage();

			const theme = new Theme();
			theme.dateRange = interval;

			return theme;

		},

		async findThemes(name: string): Promise<Theme[]> {

			const classroomId = useClassroomsStore().currentClassroom.id;

			const response = await orgApi.get(
				'themes',
				{
					params: {
						query: name,
						classroomIds: classroomId,
					}
				}
			);

			return response.data.data.map((themeData) => {
				return Theme.mapFromServer(themeData);
			});
		},

		async store(theme: Theme) {

			this.clearErrorMessage();
			if (theme.id) {
				return this.update(theme);
			}

			const serverData: any = theme.asApiObject();

			serverData.classroom = {
				id: useClassroomsStore().currentClassroom.id.toString()
			};

			try {
				const response = await orgApi.post(this.apiPath, serverData, {
					params: {
						mask: this.defaultMask.join(',')
					}
				});
				theme.setFromServerData(response.data.data);

				this.events.push(theme);

				// Alas, we also need to reload all lessons, as the theme might have changed
				AlteredEntityHelper.processAlteredEntities(response.data.altered);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async update(theme: Theme) {

			this.clearErrorMessage();
			const serverData: any = theme.asApiObject();

			try {
				const response = await orgApi.put(this.apiPath + '/' + theme.id, serverData, {
					params: {
						mask: this.defaultMask.join(',')
					}
				});

				// theme might be a clone; update the original
				this.getFromId(theme.id).setFromServerData(response.data.data);

				// Alas, we also need to reload all lessons, as the theme might have changed
				AlteredEntityHelper.processAlteredEntities(response.data.altered);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async delete(theme: Theme) {

			this.clearErrorMessage();

			try {
				// Remove from the API
				await orgApi.delete("themes/" + theme.id);
				this.removeFromId(theme.id);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		transformToApiFilter(filters: AgendaStoreFilters): any {
			return {};
		},

		mapEventFromServer(event: any): Theme {
			return Theme.mapFromServer(event);
		}
	}

});
