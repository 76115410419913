<template>
  <label v-if="label" class="block input-label">{{ label }}</label>
  <textarea class="textarea textarea-bordered" :class="classes" :disabled="disabled" ref="textInput" :value="modelValue" @input="updateValue()"></textarea>

  <span v-if="validationError" class="text-red-600 text-xs">
    {{ validationError.toString() }}
  </span>
</template>

<script lang="ts">

import {FieldValidationError} from "@/stores/errors/FieldValidationError";

export default {
  props: {
    name: { type: String, default: 'inputItem' },
    disabled: { default: false },
    modelValue: { type: String, default: '' },
    label: { type: String },
    validationError: { type: FieldValidationError }
  },

  data() {
    let classes = 'block mt-1 w-full h-20 rounded-md shadow-sm border-gray-300 sm:text-sm ' +
      'focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 block';

    return {
      classes: classes
    };
  },

  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.$refs.textInput.value)
    }
  }
}
</script>
