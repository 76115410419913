<template>

	<transition appear name="fade">
	<div id="backdrop" class="fixed inset-0 bg-black z-20 bg-opacity-50">

		<div class="absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center text-gray-100">

			<div class="mb-4 text-2xl text-center">
				{{ $t('This lesson has ended')}}
			</div>
			<div class="mb-4 text-sm text-center">
				{{ $t('You can continue to use this board by extending the lesson in the agenda') }}
			</div>

			<div class="mt-3">
				<a class="btn btn-default" @click="close()">{{ $t('Close the activity board')}}</a>
			</div>
		</div>

	</div>
	</transition>

</template>

<script lang="ts">
export default {

	emits: [
		'close'
	],

	methods: {
		close() {
			this.$emit('close');
		}
	}
}
</script>
