export enum OrderDirection {
	ASC = 'asc',
	DESC = 'desc'
}

export class OrderParameter {
	public property = '';
	public direction : OrderDirection = OrderDirection.ASC;

	constructor(property: string, direction: OrderDirection = OrderDirection.ASC) {
		this.property = property;
		this.direction = direction;
	}

	toggleDirection() {
		if (this.direction === OrderDirection.ASC) {
			this.direction = OrderDirection.DESC;
		} else {
			this.direction = OrderDirection.ASC;
		}
	}

	getApiProperty() {
		if (this.direction === OrderDirection.ASC) {
			return this.property;
		} else {
			return '-' + this.property;
		}
	}
}
