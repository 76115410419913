<template>
	<div class="relative">
		<div v-if="hovering" class="z-10 w-full h-full absolute box-border left-0 top-0 overflow-hidden" @drop.prevent="onDrop">
			<div class="flex flex-col items-center justify-center h-full border-2 border-gray-200 border-dashed bg-base-100/90">
				<CloudArrowUpIcon class="w-16 h-16" />
				<div class="text-2xl">{{ $t('Drop your files here to upload') }}</div>
			</div>
		</div>
		<slot></slot>
	</div>
</template>

<script lang="ts">

import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';

export default {

	components: {
		CloudArrowUpIcon
	},

	data() {
		return {
			hovering: false,
			enterTarget: null
		}
	},

	emits: [
		'files-dropped',
	],

	methods: {
		onDrop(e) {
			this.$emit('files-dropped', [ ...e.dataTransfer.files ]);
			this.hovering = false;
		},

		onWindowDrop(e) {
			this.hovering = false;
			e.preventDefault();
			e.stopImmediatePropagation();
		},

		onWindowDragEnter(e) {
			this.hovering = true;
			this.enterTarget = e.target;

			e.preventDefault();
			e.stopImmediatePropagation();
		},

		onWindowDragLeave(e) {
			if (this.enterTarget === e.target) {
				this.hovering = false;
			}

			e.preventDefault();
			e.stopImmediatePropagation();
		},

		onWindowDragOver(e) {
			this.hovering = true;
			e.preventDefault();
			e.stopImmediatePropagation();
		}
	},

	mounted() {
		window.addEventListener('dragenter', this.onWindowDragEnter)
		window.addEventListener('dragover', this.onWindowDragOver)
		window.addEventListener('dragleave', this.onWindowDragLeave)
		window.addEventListener('drop', this.onWindowDrop)
	},

	unmounted() {
		window.removeEventListener('dragenter', this.onWindowDragEnter)
		window.removeEventListener('dragover', this.onWindowDragOver)
		window.removeEventListener('dragleave', this.onWindowDragLeave)
		window.removeEventListener('drop', this.onWindowDrop)
	}

}
</script>
