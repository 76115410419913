<template>

	<transition appear name="fade">
	<div id="backdrop" class="fixed inset-0 bg-black z-20 bg-opacity-50">

		<div class="absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center text-gray-100">
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" viewBox="0 0 448 512" fill="currentColor">
					<path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"/>
				</svg>
			</div>

			<div class="mt-4 text-2xl">{{ $t('All activities have been paused')}}</div>
		</div>

	</div>
	</transition>

</template>

<script lang="ts">
export default {}
</script>
