import config from "@/config/app.config";

export const CALENDAR_SETTINGS = {
    droppable: true,
    initialView: 'timeGridWeek',
    headerToolbar: false,
    selectable: true,
    unselectCancel: '#fc-event-form',
    editable: true,
    // eventOverlap: false,
    eventResizableFromStart: true,
    eventDurationEditable: true,
    snapDuration: '00:05:00',
    contentHeight: 1000,
    dayHeaders: false,
    nowIndicator: true,
    selectConstraint: {
        startTime: '00:00',
        endTime: '24:00'
    },
}

export const TIMEGRID_SETTINGS = {
    allDaySlot: true,
    allDayContent: '',
    slotDuration: '00:15:00',
    eventShortHeight: 45,
    selectMirror: true,
    slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
    }
}
