<template>
	<TheStdLayout :title="$t('Insights')">

		<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

			<template v-if="loaded">

				<InsightsFilterComponent :filter="filter" @apply-filter-request="applyFilter"></InsightsFilterComponent>

				<template v-if="filter && filter.report === 'activity'">
					<InsightsActivities v-if="pupil" :pupil="pupil" :filter="filter"></InsightsActivities>
					<p v-if="!pupil">{{ $t('Select the pupil for which you would like to get insights') }}</p>
				</template>

				<template v-if="filter && filter.report === 'feedback'">
					<InsightsFeedback :filter="filter"></InsightsFeedback>
				</template>

				<template v-if="filter && filter.report === 'social'">
					<InsightsSocial :filter="filter" :pupil="pupil"></InsightsSocial>
				</template>

			</template>

		</div>

	</TheStdLayout>
</template>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import {InsightsFilter, useInsightsStore} from "@/stores/Insights.store";
import { DateTime, Interval } from "luxon";
import { usePupilsStore } from "@/stores/Pupils.store";
import InsightsActivities from "@/components/InsightsActivities.vue";
import InsightsFilterComponent from "@/components/InsightsFilter.vue";
import InsightsFeedback from "@/components/InsightsFeedback.vue";
import InsightsSocial from "@/components/InsightsSocial.vue";

export default {
	components: {
		InsightsFeedback,
		InsightsFilterComponent,
		InsightsActivities,
		InsightsSocial,
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: false,
			pupil: null,
			filter: null,
			loaded: false
		}
	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore),
		...mapState(useInsightsStore, [ 'activities', 'maxTotalTimeSpentInActivity' ]),
	},

	async mounted() {

		this.filter = new InsightsFilter();
		this.filter.dateRange = Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'));
		this.filter.report = 'feedback';
		this.filter.pupilId = null;

		this.loaded = true;

	},

	methods: {
		applyFilter(filter: InsightsFilter) {
			this.filter = filter;
			if (filter.pupilId) {
				this.pupil = this.pupilsStore.findById(filter.pupilId);
			} else {
				this.pupil = null;
			}
		},
	}

}
</script>
