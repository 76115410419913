<template>
<TheStdLayout :title="$tc('Activity', 2)">

	<ActivityEditor
		v-if="selectedActivityForEditing"
		:activity="selectedActivityForEditing"
		@requestClose="hideEditor"
	/>

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<FloatingPlusButton @push="handleActivityCreateRequest" />

		<EmptyState v-if="loaded && activitiesStore.activities.length === 0"
			:title="$t('No activities yet')"
			:description="$t('Start with creating your first activity')"
			:cta-label="$t('New activity')"
			:cta-callback="handleActivityCreateRequest"
		/>

		<table v-else class="divide-y divide-base-200 w-full">

			<thead>
				<tr>
					<th><span class="sr-only">{{$t('Icon')}}</span></th>
					<th scope="col" class="table-col-title">
						{{ $t('Name') }}
					</th>
					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr v-show="!loaded">
					<td colspan="3">
						<Skeleton content-type="userlist" :items="3" class="m-4" thumbnail-classes="mask mask-squircle" />
					</td>
				</tr>

				<tr v-show="loaded" v-for="(activity, key) in activitiesStore.activities" :key="key">
					<td class="px-4 py-3 print:py-1 w-16">
						<activity-symbol :activity="activity" class="w-14 bg-base-100 rounded-lg" />
					</td>
    				<td class="px-4 py-3 print:py-1 whitespace-nowrap" valign="top">
						{{ activity.name }}
						<div class="text-sm text-base-content-light lowercase" :class="[activity.seats == 0? 'text-danger':'']">
                            {{ activity.seats }} {{ $tc('Seat', activity.seats) }}
                        </div>
					</td>
    				<td class="px-4 py-3 print:py-1 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4">
      					<div class="flex-row print:hidden">
							<button class="btn btn-ghost btn-circle bg-base-100" @click="handleActivityEditRequest(activity)">
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>
				</tr>

			</tbody>
		</table>
  	</div>

</TheStdLayout>
</template>

<script lang="ts">

import Skeleton from '@/components/ui/Skeleton.vue';
import ActivitySymbol from "@/components/ui/symbols/ActivitySymbol.component.vue";

import {mapStores} from "pinia";
import {useIconsStore} from "@/stores/Icons.store";
import {useActivitiesStore} from "@/stores/Activities.store";
import {useIconLibrariesStore} from "@/stores/IconLibraries.store";
import {Feature, Features} from "@/utils/Features.util";
import {useClassroomsStore} from "@/stores/Classrooms.store";

import ActivityEditor from "@/components/ActivityEditor.vue";
import { PlusIcon, PencilIcon } from '@heroicons/vue/24/outline';

export default {

	data() {
		return {
			loaded: false,
			selectedActivityForEditing: null,
		};
	},

	components: {
		Skeleton,
		ActivitySymbol,
		ActivityEditor,
		PlusIcon, PencilIcon,
	},

	computed: {
		...mapStores(useIconsStore, useActivitiesStore, useIconLibrariesStore),
	},

	mounted() {
		this.loadActivities();
	  },

	methods: {

		handleActivityCreateRequest() {
			this.selectedActivityForEditing = this.activitiesStore.new();
		},

		handleActivityEditRequest(activity) {
			this.selectedActivityForEditing = activity;
		},

		async loadActivities() {
			await this.activitiesStore.load();
			this.loaded = true;
		},

		hideEditor() {
			this.selectedActivityForEditing = null;
		},

	},
};

</script>
