<template>
	<Teleport to="head">
		<link rel="icon" href="/app-icons/default/apple-touch-icon_120.png" />
	</Teleport>

	<div class="h-[80vh] flex flex-col gap-8 items-center justify-center">
		<p><img src="/smartsymbols-logo.png" class="w-64 mb-8" alt="Smart Symbols logo" /></p>
		<p class="text-2xl">{{ $t("Sorry, we couldn't find the page you're looking for.") }}</p>
		<a href="/" class="btn btn-primary">{{ $t('Go to start page') }}</a>
	</div>
</template>

<script lang="ts">
import TheBoardLayout from './layout/TheBoardLayout.vue';
export default {
	components: {
	}
}
</script>
