<template>

	<ActivitySymbolCustomIcon v-bind="$attrs"
		v-if="activity.icon"
		:icon="activity.icon"
		:disabled="disabled"
		style="display: inline-block"
	/>

	<ActivitySymbolStandardIcon v-bind="$attrs"
		v-else-if="!activity.icon && activity.symbol"
		:symbol="activity.symbol"
		:disabled="disabled"
	/>

	<ActivitySymbolStandardIcon v-bind="$attrs"
		v-else
		:symbol="null"
		:disabled="disabled"
	/>

</template>


<script lang="ts">

import ActivitySymbolCustomIcon from './ActivitySymbolCustomIcon.vue';
import ActivitySymbolStandardIcon from './ActivitySymbolStandardIcon.vue';
import {AbstractHasSymbol} from "@/models/AbstractHasSymbol.model";

export default {
	inheritAttrs: false,
	props: {
		activity: {
			required: true,
      type: AbstractHasSymbol
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},

	components: {
		ActivitySymbolCustomIcon,
		ActivitySymbolStandardIcon
	},
}

</script>
