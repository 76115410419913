<template>
	<!-- <div id="week-toolbar"
			 class="grid grid-cols-4 mb-4 border-b pb-2"> -->
	<div id="week-toolbar" class="flex justify-between items-center mb-4 border-b pt-4 pb-2 bg-base">
		<div class="flex gap-4 items-center">
			<div class="flex">
				<div class="pr-1 pt-1 flex flex-col items-end">
					<span class="block font-bold leading-3 lowercase" :class="[ isPast ? 'text-base-content-light' : '',]">{{ $t('Wk') }}</span>
					<span class="block text-xs" :class="[ isPast ? 'text-base-content-light' : '',]">{{ calendarDateObject.toFormat('kkkk') }}</span>
				</div>
				<div class="font-bold text-3xl" :class="[ isPast ? 'text-base-content-light' : '',]">{{ calendarDateObject.toFormat('WW') }}</div>
			</div>
			<div class="shrink-0 flex items-center print:hidden">
				<button class="btn btn-ghost bg-base-100 btn-sm rounded-r-none border-r-0" @click="goToPrev()">
					<ChevronLeftIcon class="w-4 h-4"/>
				</button>
				<button class="btn btn-ghost bg-base-100 btn-sm rounded-none hidden sm:inline-block" @click="goToCurrent()">
					<span class="inline-block w-2 h-2 rounded-full border border-neutral"></span>
				</button>
				<button class="btn btn-ghost bg-base-100 btn-sm rounded-l-none" @click="goToNext()">
					<ChevronRightIcon class="w-4 h-4"/>
				</button>
			</div>

			<div class="flex" id="ag-head-dp-wrapper">
				<DateSelector
					:asInput="false"
					class="print:hidden"
					:disabled-week-days="[6, 0]"
					@date-update="goToSelectedDate"
				/>
			</div>
			<button type="button" @click="$emit('addThemePeriod')" class="btn btn-ghost btn-sm normal-case bg-base-100 print:hidden">
				<PlusIcon class="w-4 h-4" />
				{{ $t('Theme period') }}
			</button>
		</div>


		<div class="flex gap-1 justify-end items-center print:hidden">
			<button @click="addWeekToClipboard()" class="btn btn-ghost bg-base-100 btn-sm" :title="$t('Add week to clipboard')">
				<DocumentDuplicateIcon class="w-5 h-5" />
			</button>
			<Dropdown>
				<template #trigger>
					<button class="btn btn-ghost bg-base-100 btn-sm indicator"  :disabled="!clipboard.length" :title="$t('Paste')">
						<span v-show="clipboard.length" class="indicator-item badge bg-danger border-none text-white">{{ clipboard.length }}</span>
						<ClipboardDocumentListIcon class="w-5 h-5" />
					</button>
				</template>
				<template #content>
					<DropdownMenu class="w-96">
						<DropdownMenuItem v-if="clipboard.length === 0">
							<div class="py-6 text-center italic">{{ $t('Clipboard is empty') }}</div>
						</DropdownMenuItem>
						<DropdownMenuItem v-for="(item, index) in clipboard" :key="index">
							<div class="py-4 px-4 flex justify-between items-start">
								<div class="shrink-0">
									<div class="flex items-center">
										{{$t('Wk')}} {{item.interval.start.weekNumber}} / {{item.interval.start.year}}
									</div>
									<span class="text-xs">{{item.interval.start.toLocaleString({ day: '2-digit', month: 'long' })}} - {{item.interval.end.toLocaleString({ day: '2-digit', month: 'long' })}}</span>
								</div>
								<div>
									<span v-for="themeName in item.themeNames" class="badge badge-accent ml-2 inline-block overflow-hidden whitespace-nowrap text-ellipsis" style="max-width: 8rem">{{  themeName }}</span>
								</div>
								<button :disabled="pastingFromClipboard || isCompletelyPast" type="button" class="btn btn-sm btn-primary" @click="pasteFromClipboard(item)">{{ $t('Paste') }}</button>
							</div>
						</DropdownMenuItem>
						<DropdownMenuItem class="border-t">
							<div class="py-4 px-4 flex justify-end">
								<button type="button" class="btn btn-sm btn-outline" @click="this.clipboardStore.clear()">{{ $t('Clear clipboard') }}</button>
							</div>
						</DropdownMenuItem>
					</DropdownMenu>
				</template>
			</Dropdown>
			<Dropdown>
				<template #trigger>
					<button class="btn btn-ghost bg-base-100 btn-sm ml-2">
						<EllipsisVerticalIcon class="w-5 h-5"/>
					</button>
				</template>
				<template #content>
					<DropdownMenu class="w-48">
            <!-- temporary hidden
						<DropdownMenuItem>
							<a href="">
								<PrinterIcon class="w-4 h-4"/>
								<span>{{ $t('Print week') }}</span>
							</a>
						</DropdownMenuItem>
						-->
						<DropdownMenuItem :disabled="!hasLessons">
							<a @click="clearWeek">
								<TrashIcon class="w-4 h-4"/>
								{{ $t('Clear week') }}
							</a>
						</DropdownMenuItem>
					</DropdownMenu>
				</template>
			</Dropdown>
		</div>
	</div>

	<Modal v-if="showClearWeekConfirmation" @requestUnmount="closeClearWeekConfirmation">
		<template #header>&nbsp;</template>

		<template #default="modalActions">
			{{ $t('Are you sure you want to delete the entire week\'s schedule?') }}

			<template v-if="lessonsStore.errorMessage?.genericError">
				<div class="alert alert-error mb-4 w-full sm:max-w-2xl m-auto">
					{{ $t(lessonsStore.errorMessage?.genericError.toString()) }}
				</div>
			</template>

			<div class="modal-footer flex justify-end gap-1">
				<button class="btn btn-ghost" @click="modalActions.hide">{{ $t('Cancel') }}</button>
				<button class="btn btn-danger" @click="confirmClearWeek()" :class="[ clearingWeek ? 'loading' : '' ]">{{ $t('Clear week') }}</button>
			</div>
		</template>
	</Modal>

	<Modal v-if="showOverridePasteConfirmation" @requestUnmount="closeConfirmOverridePaste">
		<template #header>&nbsp;</template>

		<template #default="modalActions">
			{{ $t('This week already contains a schedule. Are you sure you want to overwrite it?') }}

			<div class="modal-footer flex justify-end gap-1">
				<button class="btn btn-ghost" @click="modalActions.hide" :disabled="pastingFromClipboard">{{ $t('Cancel') }}</button>
				<button class="btn btn-danger" @click="confirmPasteFromClipboard()" :disabled="pastingFromClipboard" :class="[ pastingFromClipboard ? 'loading' : '' ]">{{ $t('Overwrite schedule') }}</button>
			</div>
		</template>
	</Modal>

</template>

<style>
#week-toolbar button.btn:disabled {
	@apply opacity-75 bg-base-100;
}
/* bit hacky, but vue-tailwind-datepicker doesn't support a 'button without input' scenario very well */
div#ag-head-dp-wrapper div#vtd > div > div {
	@apply w-80;
}
</style>

<script lang="ts">
import {DateTime, Interval} from "luxon";
import Dropdown from './ui/Dropdown.vue';
import DropdownMenu from "./ui/DropdownMenu.vue";
import DropdownMenuItem from "./ui/DropdownMenuItem.vue";
import DateSelector from "@/components/ui/DateSelector.vue";
import {
	ChevronLeftIcon, ChevronRightIcon, DocumentDuplicateIcon, PrinterIcon, ViewfinderCircleIcon,
	CalendarDaysIcon, EllipsisVerticalIcon, TrashIcon, QuestionMarkCircleIcon, ClipboardDocumentListIcon,
	PencilIcon, PlusIcon,
} from '@heroicons/vue/24/outline';
import {mapState, mapStores} from "pinia";
import {useLessonsStore} from "@/stores/Lessons.store";
import Modal from "@/components/ui/Modal.vue";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {useClipboardStore} from "@/stores/Clipboard.store";
import {CopySelection} from "@/models/CopySelection.model";
import {useThemesStore} from "@/stores/Themes.store";

export default {
	props: {
		calendarIsoDate: {
			type: String,   // ISO format (yyyy-mm-dd)
			required: true,
		}
	},
	emits: ['calendarGoTo', 'addThemePeriod'],
	components: {
		Dropdown, DropdownMenu, DropdownMenuItem,
		DateSelector,
		ChevronLeftIcon, ChevronRightIcon, DocumentDuplicateIcon, PrinterIcon, ViewfinderCircleIcon,
		CalendarDaysIcon, EllipsisVerticalIcon, TrashIcon, QuestionMarkCircleIcon, ClipboardDocumentListIcon,
		PencilIcon, PlusIcon,
		Modal,
	},
	data() {
		return {
			showClearWeekConfirmation: false,
			showOverridePasteConfirmation: false,
			selectedCopySelection: null as CopySelection | null,
			clearingWeek: false,
			pastingFromClipboard: false
		}
	},
	computed: {
		...mapStores(useLessonsStore, useThemesStore),
		...mapStores(useClipboardStore),
		...mapState(useClipboardStore, [ 'clipboard'] ),

		calendarDateObject() {
			return DateTime.fromISO(this.calendarIsoDate);
		},

		isPast() {
			return this.calendarDateObject.endOf('week') < DateTime.local().startOf('day');
		},

		isCompletelyPast() {
			return this.calendarDateObject.startOf('week') < DateTime.local().endOf('day').minus({days: 7});
		},

		hasLessons() {
			if (!this.calendarDateObject) {
				return false;
			}

			return this.lessonsStore.hasBetween(
				this.calendarDateObject.startOf('week').toJSDate(),
				this.calendarDateObject.endOf('week').toJSDate()
			);
		}
	},

	methods: {
		goToNext() {
			this.$emit('calendarGoTo', 'next');
		},
		goToPrev() {
			this.$emit('calendarGoTo', 'prev');
		},
		goToCurrent() {
			this.$emit('calendarGoTo', 'today');
		},
		goToSelectedDate(date: Date) {
			let dt = DateTime.fromJSDate(date);
			if (dt.weekday > 5) {
				// subtract 3 days to arrive midweek
				dt = dt.minus({ days: 3 });
			}

			this.$emit('calendarGoTo', 'date', dt);
		},

		async addWeekToClipboard() {

			if (!this.hasLessons) {
				this.$notify({
					text: this.$t('This week is empty'),
					type: "error",   // optional styling: info, success, error
				});
				return;
			}

			// Also add all themes to clipboard
			let themes = await this.themesStore.getBetween(
				this.calendarDateObject.startOf('week').toJSDate(),
				this.calendarDateObject.endOf('week').toJSDate()
			);

			this.clipboardStore.copy(Interval.fromDateTimes(
					this.calendarDateObject.startOf('week'),
					this.calendarDateObject.endOf('week')
			), themes.map(theme => theme.name));

			this.$notify({
				// title: "Klaar!",
				text: this.$t('This week was added to your clipboard'),
				type: "success",   // optional styling: info, success, error
			});
		},

		async pasteFromClipboard(copiedItem: CopySelection) {
			this.showOverridePasteConfirmation = false;
			this.selectedCopySelection = copiedItem;
			await this._processPaste(this.selectedCopySelection);
		},

		async confirmPasteFromClipboard() {
			await this._processPaste(this.selectedCopySelection);
		},

		/**
		 * @param copiedItem
		 */
		async _processPaste(copiedItem: CopySelection) {

			if (this.clipboard.length === 0) {
				return;
			}

			// can't copy to the past.
			if (this.isCompletelyPast) {
				return;
			}

			this.pastingFromClipboard = true;

			let start = this.calendarDateObject.startOf('week');
			let end = start.plus(copiedItem.interval.toDuration());

			let sourceInterval = copiedItem.interval;

			if (start.valueOf() < DateTime.local().valueOf()) {
				// The api doesn't like it when we paste in the past, so we need to make sure the destination is completely in the
				// future. And as the source duration must match the destination duration, we need to make sure of that too.
				start = DateTime.local().plus({ minutes: 10 });
				sourceInterval = Interval.fromDateTimes(
					sourceInterval.end.minus(Interval.fromDateTimes(start, end).toDuration()),
					sourceInterval.end
				);
			}

			const pasteDestination = Interval.fromDateTimes(start, end);
			try {

				await this.lessonsStore.copyTo(sourceInterval, [ pasteDestination ], this.showOverridePasteConfirmation);
				this.showOverridePasteConfirmation = false;

				this.clipboardStore.remove(copiedItem);

				this.$notify({
					// title: "Klaar!",
					text: this.$t('Pasted from clipboard'),
					type: "success",   // optional styling: info, success, error
				});

			} catch (e) {

				if (e.response?.status === 406) {
					this.showOverridePasteConfirmation = true;
				} else if (e instanceof ApiErrors) {
					this.$notify({
						// title: "Klaar!",
						text: e.toString(),
						type: "error",   // optional styling: info, success, error
					});
				} else {
					throw e;
				}

			} finally {
				this.pastingFromClipboard = false;
			}

		},

		clearWeek() {
			this.showClearWeekConfirmation = true;
		},

		closeConfirmOverridePaste() {
			this.showOverridePasteConfirmation = false;
		},

		closeClearWeekConfirmation() {
			this.showClearWeekConfirmation = false;
		},

		async confirmClearWeek() {
			if (this.clearingWeek) {
				return;
			}

			this.clearingWeek = true;

			try {
				await Promise.all([
					this.lessonsStore.clearWeek(this.calendarDateObject),
					this.themesStore.clearWeek(this.calendarDateObject)
				]);

				this.closeClearWeekConfirmation();
				this.clearingWeek = false;
			} catch (e) {
				if (!(e instanceof ApiErrors)) {
					this.clearingWeek = false;
					throw e;
				}
			}
		}
	},
}
</script>
