// Activity board has a special procedure that makes sure the page is reloaded
// at least once every 22 hours. We do this by storing the last time the page
// was loaded in local storage. If the page is loaded more than 22 hours ago,
// we reload the page.

class ReloadSchedulerClass {

	private intervals = new Map<string, Interval>();

	scheduleReload(name: string, intervalMilliseconds: number) {

		if (this.intervals.has(name)) {
			return;
		}

		try {
			localStorage.setItem('last-reload', (new Date()).getTime().toString());

			const interval = setInterval(() => {
				if (parseInt(localStorage.getItem('last-reload')) < (new Date()).getTime() - intervalMilliseconds) {
					window.location.reload();
				} else {
					console.log('not reloading');
				}
			}, intervalMilliseconds / 24);
		} catch (e) {
			// do nothing, but log to console
			console.log(e);
		}

	}

}

export const ReloadScheduler = new ReloadSchedulerClass();
