// @ts-ignore
export default {

	// @ts-ignore
	VERSION: APP_VERSION,
	RELEASE_NAME: APP_RELEASE_NAME,

	// @ts-ignore
	ENV: import.meta.env.VITE_ENVIRONMENT || 'development',

	// @ts-ignore
	MAINTENANCE_MODE: import.meta.env.VITE_MAINTENANCE_MODE !== 'false',

	// @ts-ignore
	BUILD_TIMESTAMP: new Date(APP_BUILD_TIMESTAMP),

	/**
	 * API URL: location of the
	 */
	// @ts-ignore
	API_URL: import.meta.env.VITE_SMARTSYMBOLS_API_URL || '/api/v1/',

	// @ts-ignore
	SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || null,

	// @ts-ignore
	SENTRY_TRACES_SAMPLE_RATE: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) : 1.0,

	// @ts-ignore
	GA_MEASUREMENT_ID: import.meta.env.VITE_GA_MEASUREMENT_ID || null,

	ORG_ID: null,

	OAUTH: {
		// @ts-ignore
		client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,

		// @ts-ignore
		redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URI || 'http://localhost:5173/',

		// @ts-ignore
		authorization_endpoint: import.meta.env.VITE_OAUTH_AUTHORIZATION_URI || 'https://127.0.0.1/oauth/authorize',

		// @ts-ignore
		token_endpoint: import.meta.env.VITE_OAUTH_TOKEN_URI || 'https://127.0.0.1/oauth/token',

		requested_scopes: 'full',
	},

	GOOGLE: {
		// @ts-ignore
		client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,

		// @ts-ignore
		developer_key: import.meta.env.VITE_GOOGLE_DEVELOPER_KEY,
	},

	AZURE: {

		// @ts-ignore
		client_id: import.meta.env.VITE_AZURE_CLIENT_ID,

	}

}
