<template>
	<div class="flex flex-col gap-6 p-6">
		<div>
			<h2 class="font-bold mb-2 text-lg">{{ $t('Avatar display') }}</h2>
			<div class="flex flex-col gap-8">
				<div class="flex flex-col gap-2">
					<label class="flex items-center gap-3">
						<input type="radio" name="avatar-format" class="radio"
							:checked="activityBoardStore.avatarWithSymbol"
							@click="setAvatarLook(true, false)"
						/>
						<span>{{ $t('Show symbol only') }}</span>
					</label>
					<label class="flex items-center gap-3">
						<input type="radio" name="avatar-format" class="radio"
							:checked="activityBoardStore.avatarWithName"
							@click="setAvatarLook(false, true)"
						/>
						<span>{{ $t('Show name only') }}</span>
					</label>
					<label class="flex items-center gap-3">
						<input type="radio" name="avatar-format" class="radio"
							:checked="activityBoardStore.avatarWithSymbol && activityBoardStore.avatarWithName"
							@click="setAvatarLook(true, true)"
						/>
						<span>{{ $t('Show both name and symbol') }}</span>
					</label>
				</div>
				<div class="flex flex-col items-start">
					<span class="">{{ $t('Show symbols with a specific color only') }}:</span>
					<ActivityBoardAvatarColorFilter class="mt-4" />
				</div>
				<label class="flex items-center">
					<input type="checkbox" class="checkbox" name="color-filter-display" @click="enableAvatarColorFilter($event.target.checked)" />
					<span class="ml-3">{{ $t('Allow pupils to filter by symbol color') }}</span>
				</label>
			</div>
		</div>
		<div>
			<h2 class="font-bold mb-2 text-lg">{{ $t('Actions') }}</h2>
			<div class="flex flex-col gap-4">
				<button v-if="!fullscreen" class="btn btn-outline" @click="activateFullscreen">
					<ArrowsPointingOutIcon class="w-5 h-5 mr-2" />
					{{ $t('Enter fullscreen mode') }}
				</button>
				<button v-else class="btn btn-outline" @click="deactivateFullscreen">
					<ArrowsPointingInIcon class="w-5 h-5 mr-2" />
					{{ $t('Exit fullscreen mode') }}
				</button>
				<button v-if="!activityBoardStore.activitiesPaused" class="btn btn-outline" @click="setActivitiesPause(true)">
					<PauseCircleIcon class="w-5 h-5 mr-2" />
					{{ $t('Pause activities') }}
				</button>
				<button v-else class="btn btn-primary" @click="setActivitiesPause(false)">
					<PlayCircleIcon class="w-5 h-5 mr-2" />
					{{ $t('Resume activities') }}
				</button>
				<Dropdown :visible="showClosingBoardConfirmation" position="top-start">
					<template #trigger>
						<button class="btn btn-danger btn-outline w-full" @click="initializeCloseBoardTime">
							<NoSymbolIcon class="w-5 h-5 mr-2" />
							{{ $t('Close the activity board') }}
						</button>
					</template>
					<template #content="dialogActions">
						<div class="flex justify-between items-center p-4 rounded-md bg-base shadow-lg ring-1 ring-neutral ring-opacity-5 focus:outline-none">
							<div>
								<div class="grid grid-cols-1 divide-y divide-base-300">
									<div class="py-5">
										<div class="font-bold text-lg">{{ $t('End the activity board & all activities') }}</div>
										<span class="block mb-2">{{ $t('and sign off pupils at') }}...</span>
										<div class="flex items-end justify-between ">
											<div>
												<form class="">
													<input type="number" v-model="closeBoardTime.hours" min="0" max="23" required class="input" /> :
													<input type="number" v-model="closeBoardTime.mins" min="0" max="23" required class="input" />
													<p v-if="!validClosingBoardTime" class="text-red-600 text-sm mt-2 text-center h-5">{{ $t('Invalid time') }}</p>
												</form>
											</div>

											<button type="button" class="btn btn-danger" @click="handleBoardClosing(dialogActions, true)">
												{{ $t('Confirm') }}
											</button>
										</div>
										<p class="alert alert-warning text-sm py-1 mt-3">
											<ExclamationTriangleIcon class="w-5 h-5 shrink-0" />
											{{ $t('This will also end the activity board on other active devices currently in use.') }}
										</p>
									</div>
									<div class="py-5 flex items-center justify-between gap-4">
										<div>
											<div class="font-bold text-lg">{{ $t('Leave the activity board on this device') }}</div>
											<span>{{ $t('without ending the activities') }}</span>
										</div>
										<button type="button" class="btn btn-neutral" @click="handleBoardClosing(dialogActions, false)">
											{{ $t('Confirm') }}
										</button>
									</div>
									<div class="py-5 flex justify-end">
										<button type="button" class="btn" @click="dialogActions.close">
											{{ $t('Cancel') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</template>
				</Dropdown>
				<button class="btn btn-outline mt-8" :disabled="activityBoardStore.activitiesPaused" @click="$emit('requestReloadBoard')">
					<ArrowPathIcon class="w-5 h-5 mr-2" />
					{{ $t('Reload board') }}
				</button>

			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useActivityBoardStore } from "@/stores/ActivityBoard.store";
import { mapStores } from "pinia";

import { DateTime } from "luxon";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import ActivityBoardAvatarColorFilter from "@/components/ActivityBoardAvatarColorFilter.vue";
import { ArrowPathIcon, PauseCircleIcon, PlayCircleIcon, NoSymbolIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

export default {
	emits: [
		'requestReloadBoard',
		'requestCloseBoard',
	],
	components: {
		Dropdown, DropdownDialogConfirm, ActivityBoardAvatarColorFilter,
		ArrowPathIcon, PauseCircleIcon, PlayCircleIcon, NoSymbolIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, ExclamationTriangleIcon
	},
	data() {
		return {
			fullscreen: false,
			showClosingBoardConfirmation: false,
			closeBoardTime: {
				hours: 0,
				mins: 0,
			},
		};
	},
	computed: {
		...mapStores(useActivityBoardStore),

		validClosingBoardTime() {
			return this.closeBoardTime.hours
				&& this.closeBoardTime.mins
				&& isNaN(this.closeBoardTime.hours) === false
				&& isNaN(this.closeBoardTime.mins) ===  false
				&& this.closeBoardTime.hours >= 0
				&& this.closeBoardTime.hours <= 23
				&& this.closeBoardTime.mins >= 0
				&& this.closeBoardTime.mins <= 59;
		}
	},
	beforeMount() {
		// toggle this.fullscreen this way to also handle the case where the user leaves fullscreen mode by pressing ESC
		document.documentElement.addEventListener('fullscreenchange', () => {
			this.fullscreen = document.fullscreenElement !== null;
		});
	},
	methods: {
		setAvatarLook(withSymbol: Boolean, withName: Boolean) {
			this.activityBoardStore.setAvatarLook(withSymbol, withName);
			if(this.$gtag) {
				this.$gtag.event('change-avatar-look', {
					'event_category': 'activity-board',
					'event_label': 'configuration',
					'value': (withSymbol ? 'symbol' : '') + (withName ? 'name' : '')
				});
			}
		},

		setActivitiesPause(paused: Boolean) {
			this.activityBoardStore.setActivitiesPause(paused);
			if(this.$gtag) {
				this.$gtag.event('pause-activities', {
					'event_category': 'activity-board',
					'event_label': 'configuration',
					'value': paused ? 'on' : 'off'
				});
			}
		},

		enableAvatarColorFilter(enabled: Boolean) {
			this.activityBoardStore.enableAvatarColorFilter(enabled);
			if(this.$gtag) {
				this.$gtag.event('color-filter-toggling', {
					'event_category': 'activity-board',
					'event_label': 'configuration',
					'value': enabled ? 'on' : 'off'
				});
			}
		},

		activateFullscreen() {
			const element = document.documentElement;
			if(element.requestFullscreen) {
				element.requestFullscreen();        // W3C spec
			}
			else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();     // Firefox
			}
			else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen();  // Safari
			}
			else if(element.msRequestFullscreen) {
				element.msRequestFullscreen();      // IE/Edge
			}

			if(this.$ua) {
				this.$ua.trackEvent(
					'activity-board', 'configuration', 'fullscreen', 'on'
				);
			}
		},

		deactivateFullscreen() {
			if(document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}

			if(this.$ua) {
				this.$ua.trackEvent(
					'activity-board', 'configuration', 'fullscreen', 'off'
				);
			}
		},

		initializeCloseBoardTime() {
			// add 1 minute to avoid pupils getting checked out at a time BEFORE check-in time
			const now = DateTime.local().plus({ minutes: 1 });
			[ this.closeBoardTime.hours, this.closeBoardTime.mins ] = now.toLocaleString(DateTime.TIME_24_SIMPLE).split(':');
		},

		handleBoardClosing(dialogActions, endActivities: Boolean = false) {

			if(!this.validClosingBoardTime) {
				return;
			}

			dialogActions.close();

			if(endActivities) {
				this.$emit('requestCloseBoard', DateTime.fromObject({
					hour: this.closeBoardTime.hours,
					minute: this.closeBoardTime.mins,
				}));
			} else {
				this.$emit('requestCloseBoard', null);
			}
		},
	}
}
</script>
