<template>
	<div class="mb-6 border-b">
		<Tabs v-model="localFilter.report" :options="availableReports" @update:modelValue="changeMode" class="text-md"></Tabs>
	</div>

	<div class="grid grid-cols-6 gap-3 pb-4 mb-6">
		<div class="col-span-6 md:col-span-2 2xl:col-span-1">
			<DateRangeSelector
				v-model="dateRangeArray"
				@update:model-value="applyFilter"
				:clearable="false"
				:disabled="localFilter.report === 'social'"
				:readonly="localFilter.report === 'social'"
			/>
		</div>
		<div class="col-span-6 md:col-span-2 2xl:col-span-1">
			<sms-select @change="applyFilter"  v-model="localFilter.pupilId" label="" class="select select-sm leading-4 bg-base" :options="pupilsForFilter"></sms-select>
		</div>
	</div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { useActivitiesStore } from "@/stores/Activities.store";
import { usePupilsStore } from '@/stores/Pupils.store';

import DateRangeSelector from "./ui/DateRangeSelector.vue";
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { InsightsFilter } from "@/stores/Insights.store";
import { DateTime, Interval } from "luxon";
import Tabs from "./ui/Tabs.vue";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";

export default {

	props: {
		filter: {
			type: InsightsFilter
		},
	},

	emits: [
		'apply-filter-request',
	],

	components: {
		DateRangeSelector,
		MagnifyingGlassIcon,
		Tabs,
	},

	data() {
		return {
			localFilter: this.filter,
			previousReport: null,
		}
	},

	created() {
		this.pupilsStore.orderBy(this.defaultOrderStore.pupilsOrder);
	},

	computed: {
		...mapStores(usePupilsStore, useActivitiesStore, useDefaultOrderStore),

		dateRangeArray: {
			get(): [Date, Date] {
				if(!this.localFilter.dateRange) {
					return null;
				} else {
					return [
						this.localFilter.dateRange.start,
						this.localFilter.dateRange.end
					];
				}
			},
			set(value) {
				this.localFilter.dateRange = {
					start: DateTime.fromJSDate(value[0]).startOf('day'),
					end: DateTime.fromJSDate(value[1]).endOf('day'),
				};
			}
		},

		filterApplied() {
			return this.localFilter.dateRange
				|| this.localFilter.pupil;
		},

		pupilsForFilter() {
			let list = [];

			list.push({
				value: '',
				label: this.$t('All pupils'),
			});

			for (let pupil of this.pupilsStore.pupils) {
				list.push({
					value: pupil.id,
					label: pupil.firstName + ' ' + pupil.lastName,
				});
			}
			return list;
		},

		availableReports() {
			return [
				{ value: 'activity', label: this.$tc('Activity') },
				{ value: 'feedback', label: this.$t('Feedback') },
				{ value: 'social', label: this.$t('Social interaction') },
			];
		},
	},

	mounted() {
		this.loadPupils();

		if (this.filter) {
			this.localFilter = this.filter.clone();
		}

		this.previousReport = this.localFilter.report;
	},

	methods: {
		async loadPupils() {
			await this.pupilsStore.load();
		},

		handleUpdatedRange(newRange) {
			this.dateRangeArray = newRange;
			this.applyFilter();
		},

		applyFilter() {
			this.$emit('apply-filter-request', this.localFilter.clone()); // always emit a new value
		},

		initializeFilter() {
			this.localFilter = new InsightsFilter();
		},

		handleFilterReset() {
			this.initializeFilter();
			this.$emit('apply-filter-request', this.localFilter);
		},

		showLoadIndicator() {
			alert('showLoadIndicator');
		},

		changeMode(mode) {
			// reset filter
			this.localFilter.report = mode;

			// Has report changed?
			if (this.previousReport !== this.localFilter.report) {

				if (this.localFilter.report === 'social') {
					// last 31 days
					this.localFilter.dateRange = Interval.fromDateTimes(
						DateTime.now().endOf('day').minus({ days: 31 }),
						DateTime.now().endOf('day')
					);
				} else if (this.previousReport === 'social') {
					// just today
					this.localFilter.dateRange = Interval.fromDateTimes(
						DateTime.now().startOf('day'),
						DateTime.now().endOf('day')
					);
				}

				this.previousReport = this.localFilter.report;
			}

			this.applyFilter();
		},
	},
}

</script>
