export default class SmaSyLocalStorage {

	private static setItemNormalized(key: string, value: any, keyPrefix: string = ''): void {
		let stringifiedValue = '';
		try {
			stringifiedValue = JSON.stringify(value);
		  } catch (error) {
			stringifiedValue = value;
		  }
		localStorage.setItem(keyPrefix + this.convertCamelToUnderscore(key), stringifiedValue);
	}

	private static getItemNormalized(key: string, keyPrefix: string = ''): string {
		const item = localStorage.getItem(keyPrefix + this.convertCamelToUnderscore(key));
		try {
			const jsonItem = JSON.parse(item);
			return jsonItem;
		} catch (error) {
			return item;
		}
	}

	private static removeItemNormalized(key: string, keyPrefix: string = ''): void {
		localStorage.removeItem(keyPrefix + this.convertCamelToUnderscore(key));
	}

	public static setItem(key: string, value: string): void {
		this.setItemNormalized(key, value);
	}

	public static setUserItem(
		userId: string,
		key: string,
		value: any,
		expiresInSeconds: number = null
	): void {
		this.setItemNormalized(key, value, "user." + userId + ".");

		if (expiresInSeconds) {
			this.setItemNormalized(
				key + ".expiresAt",
				new Date().getTime() + expiresInSeconds * 1000,
				"user." + userId + "."
			);
		} else {
			this.removeItemNormalized(
				key + ".expiresAt",
				"user." + userId + "."
			);
		}
	}

	public static getItem(key: string): any {
		return this.getItemNormalized(key);
	}

	public static getUserItem(userId: string, key: string): any {
		// Has an 'expired' key?
		const expiresAt = this.getItemNormalized(key + ".expiresAt", "user." + userId + ".");
		if (expiresAt && parseInt(expiresAt) < new Date().getTime()) {
			this.removeUserItem(userId, key);
			return null;
		}

		return this.getItemNormalized(key, "user." + userId + ".");
	}

	public static removeItem(key: string): void {
		this.removeItemNormalized(key);
	}

	public static removeUserItem(userId: string, key: string): void {
		this.removeItemNormalized(key, "user." + userId + ".");
	}

	public static clearUser(userId: string): void {
		for (let i = 0; i < localStorage.length; i++) {
			let key = localStorage.key(i);
			if (key.startsWith("user." + userId + ".")) {
				localStorage.removeItem(key);
			}
		}
	}

	public static clearAll(): void {
		localStorage.clear();
	}

	private static convertCamelToUnderscore(inputString) {
		// Check if the input string is in camelCase format
		if (/^[a-z][a-zA-Z0-9]*$/.test(inputString)) {
			return inputString.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
		} else {
			return inputString;
		}
	}

}
