import { orgApi } from "@/utils/Api.util";

export class Files {
	static async toBase64(file: File) {
		return new Promise(
			(resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			}
		)
	}
}
