<template>
	<button type="button" v-if="available" @click="openFilePicker($event)" class="" :disabled="!loaded">
		<img src="/images/icons/one-drive.svg" class="w-8 h-8" alt="One-Drive icon"/>
		<span class="hidden sm:inline-block">

			<template v-if="fetchingOauthTokenCompleted">
				<!-- successfully connected, but briefly showing spinner for UX purposes -->
				<span class="loading loading-spinner"></span>
			</template>

			<template v-else-if="oauthToken">
				{{ label }}
			</template>

			<template v-else>
				{{ $t('Connect to OneDrive' )}}
			</template>
		</span>

		<span v-if="!loaded">
			<span class="loading loading-spinner"></span>
		</span>
	</button>
</template>

<script lang="ts">

// Manual: https://learn.microsoft.com/en-us/onedrive/developer/controls/file-pickers/?view=odsp-graph-online

import {FileExternal} from "@/models/FileExternal.model";
import config from "@/config/app.config";
import {mapStores} from "pinia";
import {useUsersStore} from "@/stores/Users.store";
import Modal from "@/components/ui/Modal.vue";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

// the options we pass to the picker page through the querystring
const oneDriveFilePickerOptions = {
	sdk: "8.0",
	entry: {
		oneDrive: {
			files: {},
		}
	},
	authentication: {},
	messaging: {
		origin: window.location.protocol + "//" + window.location.host,
		channelId: uuidv4()
	},
	typesAndSources: {
		mode: "files",
		pivots: {
			oneDrive: true,
			recent: true,
		},
	},
	selection: {
		mode: "multiple",
		typesAndSources: true,
	},
};

const baseUrl = "https://onedrive.live.com/picker";

const msalParams = {
	auth: {
		authority: "https://login.microsoftonline.com/consumers",
		clientId: config.AZURE.client_id,
		redirectUri: window.location.protocol + "//" + window.location.host,
	},
}

let oneDriveLibrariesLoadedPromise = null;
let popupWindow = null;
let msApp = null;

export default {

	computed: {
		...mapStores(useUsersStore),

		available() {
			if (!config.AZURE?.client_id) {
				return false;
			}

			return true;
		},
	},

	components: {
		Modal
	},

	props: {
		label: {
			type: String,
			required: false,
		}
	},

	data() {
		return {
			oauthToken: null,
			port: null,
			loaded: false,
			fetchingOauthTokenCompleted: false,
		};
	},

	emits: [
		'filesSelected'
	],

	async created() {
		await this.loadOneDriveApi();
	},

	mounted() {
		oneDriveLibrariesLoadedPromise.then(
			() => {
				this.loaded = true;
			}
		);
	},

	methods: {

		async loadOneDriveApi() {

			if (!oneDriveLibrariesLoadedPromise) {

				oneDriveLibrariesLoadedPromise = new Promise<void>(
					(resolve, reject) => {

						this.loadScripts([
							"https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js"
						]).then(
							async () => {

								msApp = new msal.PublicClientApplication(msalParams);

								// Try to fetch an access token (silently) to see if we are already authenticated
								await this.fetchTokenOrPopup(true);

								resolve();

							}
						);
					}
				);
			}

			return oneDriveLibrariesLoadedPromise;
		},

		/**
		 * Return values:
		 * - TRUE: access token was available, popup did not open. Proceed to open picker popup.
		 * - FALSE: access token was not available, popup was opened. Do not proceed to open picker popup.
		 * @param silent Do not open popup if token is not available.
		 */
		async fetchTokenOrPopup(silent = false) {

			let accessToken = "";
			let authParams = null;

			authParams = {scopes: ["OneDrive.ReadWrite"]};

			try {

				// see if we have already the idtoken saved
				const resp = await msApp.acquireTokenSilent(authParams);
				this.oauthToken = resp.accessToken;
				return true;

			} catch (e) {

				if (silent) {
					return false;
				}

				const resp = await msApp.loginPopup(authParams);
				msApp.setActiveAccount(resp.account);

				if (resp.idToken) {

					const resp2 = await msApp.acquireTokenSilent(authParams);
					accessToken = resp2.accessToken;

					this.fetchingOauthTokenCompleted = true;
					setTimeout(() => {
						this.fetchingOauthTokenCompleted = false;
					}, 2000);

				}
			}

			this.oauthToken = accessToken;
			return false;

		},

		loadScripts(scripts: string[]) {
			const promises = [];

			scripts.forEach((script: string) => {

				promises.push(new Promise<void>((resolve, reject) => {

					let gDrive = document.createElement("script");
					gDrive.setAttribute("type", "text/javascript");
					gDrive.setAttribute("src", script);

					gDrive.onload = () => {
						resolve();
					}

					document.head.appendChild(gDrive);

				}));

			});

			return Promise.all(promises);
		},

		openFilePicker(event) {

			if (!oneDriveLibrariesLoadedPromise) {
				console.error('OneDrive libraries not loaded (yet?)');
				// Can't make a promise as otherwise popup blocking kicks in.
			}

			this.launchPicker(event);
		},

		async launchPicker(e) {

			e.preventDefault();
			if (popupWindow) {
				popupWindow.close();
			}

			let hasLoadedAccessToken = await this.fetchTokenOrPopup();
			if (!hasLoadedAccessToken) {
				return;
			}

			popupWindow = window.open("", "Picker", "width=800,height=600");

			const queryString = new URLSearchParams({
				filePicker: JSON.stringify(oneDriveFilePickerOptions),
			});

			const url = `${baseUrl}?${queryString}`;

			const form = popupWindow.document.createElement("form");
			form.setAttribute("action", url);
			form.setAttribute("method", "POST");
			popupWindow.document.body.append(form);

			const input = popupWindow.document.createElement("input");
			input.setAttribute("type", "hidden")
			input.setAttribute("name", "access_token");
			input.setAttribute("value", this.oauthToken);
			form.appendChild(input);

			form.submit();

			window.addEventListener("message", (event) => {

				if (event.source && event.source === popupWindow) {

					const message = event.data;

					if (
						message.type === "initialize" &&
						message.channelId === oneDriveFilePickerOptions.messaging.channelId
					) {

						this.port = event.ports[0];

						this.port.addEventListener("message", this.messageListener);

						this.port.start();

						this.port.postMessage({
							type: "activate",
						});
					}
				}
			});
		},

		async messageListener(message) {
			switch (message.data.type) {

				case "notification":
					//console.log('notification: ', message.data);
					break;

				case "command":

					this.port.postMessage({
						type: "acknowledge",
						id: message.data.id,
					});

					const command = message.data.data;

					switch (command.command) {

						case "authenticate":

							const token = this.oauthToken;
							if (typeof token !== "undefined" && token !== null) {

								this.port.postMessage({
									type: "result",
									id: message.data.id,
									data: {
										result: "token",
										token,
									}
								});

							} else {
								console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
							}

							break;

						case "close":

							popupWindow.close();
							break;

						case "pick":

							await this.filesPicked(command.items);

							this.port.postMessage({
								type: "result",
								id: message.data.id,
								data: {
									result: "success",
								},
							});

							popupWindow.close();
							break;

						default:

							console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);

							this.port.postMessage({
								result: "error",
								error: {
									code: "unsupportedCommand",
									message: command.command
								},
								isExpected: true,
							});
							break;
					}

					break;
			}
		},

		async filesPicked(files: {
			// File picker typing taken from https://github.com/OneDrive/samples/tree/master/samples/file-picking/sdk-pnptimeline
			"@sharePoint.embedUrl": string;
			"@sharePoint.endpoint": string;
			"@sharePoint.listUrl": string;
			folder?: any;
			id: string;
			name: string;
			parentReference: {
				driveId: string;
				sharepointIds: {
					listId: string;
					siteId: string;
					siteUrl: string;
					webId: string;
				}
			}
			sharepointIds: {
				listId: string;
				listItemId: string;
				listItemUniqueId: string;
				siteId: string;
				siteUrl: string;
				webId: string;
			}
			size: number;
			webDavUrl: string;
			webUrl: string;
		}[]) {

			const apiClient = axios.create({
				headers: {
					"Authorization": "Bearer " + this.oauthToken
				}
			});

			const promises = files.map(
				(file) => {
					return (async () => {
						// Fetch the file metadata
						// https://graph.microsoft.com/v1.0/me/drive/items/{file.id}
						const fileId = file.id;
						const endpoint = file["@sharePoint.endpoint"];

						// Fetch the file metadata
						const response = await apiClient.get(endpoint + "/drive/items/" + fileId);

						const mimeType = response.data.file.mimeType;

						let externalFile = new FileExternal();
						externalFile.service = "onedrive";
						externalFile.name = file.name;
						externalFile.url = file.webUrl;
						externalFile.mimeType = mimeType;
						externalFile.size = file.size;

						return externalFile;

					})();
				}
			);

			const externalFiles = await Promise.all(promises);
			this.$emit('filesSelected', externalFiles);
		}
	},

}
</script>
