<template>
    <TheStdLayout :title="$tc('Lesson plan', 2)">

		<LessonPlanEditor v-if="showEditor" :lessonPlan="lessonPlan" @requestClose="closeEditor" />

        <div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

            <FloatingPlusButton @push="handleCreateIntent" />

            <!-- empty state -->
			<EmptyState v-if="loaded && lessonPlansStore.items.length === 0 && this.filter?.isEmpty()"
				:title="$t('No lesson plans yet in your library')"
				:description="$t('Start with creating your first lesson plan')"
				:cta-label="$t('New lesson plan')"
				:cta-callback="handleCreateIntent"
			/>

            <template v-else>

				<LessonPlanFilter
					v-show="loaded"
					:collapsable="false"
					:collapsed="false"
					:resultCounter="null"
					class="mt-6 relative z-10"
				/>

				<table class="divide-y divide-base-200 w-full relative z-0">

					<thead>
						<tr>

							<th scope="col" class="table-col-title">
								<SortableHeader :order="lessonPlansStore.order" property="title" @change="changeOrder">{{ $t('Title') }}</SortableHeader>
							</th>
							<th scope="col" class="table-col-title">{{ $tc('Goal', 2) }}</th>

							<th scope="col" class="table-col-title">
								<SortableHeader :order="lessonPlansStore.order" property="created_at" @change="changeOrder" defaultDirection="desc">{{ $t('Created at') }}</SortableHeader>
							</th>

							<th scope="col" class="px-6 py-3">
								<span class="sr-only">{{$t('Actions')}}</span>
							</th>
						</tr>
					</thead>

					<tbody class="bg-base divide-y divide-base-200">

						<tr v-if="!loaded">
							<td colspan="3">
								<Skeleton content-type="userlist" :items="3" class="m-4" :withThumb="false" />
							</td>
						</tr>

						<tr v-else-if="lessonPlansStore.items.length > 0" v-for="(lessonPlan, key) in lessonPlansStore.items" :key="key">
							<td class="px-4 py-3 print:py-1 align-top">
								{{ lessonPlan.title }}
								<div v-if="lessonPlan.tags?.length || lessonPlan.attachments?.length" class="flex gap-2 mt-1">
									<span v-if="lessonPlan.attachments?.length" class="badge">
										<PaperClipIcon class="w-3 h-3 mr-1"></PaperClipIcon>
										{{ lessonPlan.attachments.length }}
									</span>
									<Tag v-for="(tag, key) in sortedLessonPlanTags(lessonPlan)" :key="key" class="tag-xs" :class="[tag.type === 'activity' ? 'tag-activity' : 'tag-theme']">
										{{ tag.name }}
									</Tag>
								</div>
							</td>
							<td class="px-4 py-3 print:py-1 align-top max-w-sm">
								<ul v-if="lessonPlan.goals?.length">
									<!-- <li v-for="goal in sortedLessonPlanGoals(lessonPlan)" class="flex items-start text-base-content-light" :class="[goal.isSubgoal()? 'pl-4' : '']"> -->
									<li v-for="goal in sortedLessonPlanGoals(lessonPlan)" :key="goal.id" class="flex items-start text-base-content-light gap-2">
										<template v-if="!goal.isSubgoal()">
											<MapPinIcon class="shrink-0" :class="[goal.isSubgoal()? 'mt-1 w-3 h-3' : 'mt-0.5 w-4 h-4']" :style="{ color: goal.color ?? '#86efac' }"></MapPinIcon>
											<span class="text-sm">{{ goal.fullPath }}</span>
											<span class="whitespace-nowrap overflow-hidden text-ellipsis w-32 text-sm">
												<GoalTitle :goal="goal" :short="true" class="text-sm" />
											</span>
										</template>
									</li>
								</ul>
							</td>

							<td class="px-4 py-3 print:py-1 align-top text-sm opacity-50">
								{{ localDate(lessonPlan.createdAt) }}
							</td>

							<td class="px-4 py-3 align-top whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4 print:py-1">
								<div class="flex-row print:hidden">
									<button class="btn btn-ghost btn-circle bg-base-100" @click="handleEditIntent(lessonPlan)">
										<PencilIcon class="w-5 h-5"></PencilIcon>
									</button>
								</div>
							</td>
						</tr>
						<tr v-else>
							<td colspan="3" class="px-4 pt-12 text-center">
								{{ $tc('Results', 0) }}
							</td>
						</tr>
					</tbody>
				</table>

				<Pagination v-if="lessonPlansStore.currentPage" :page="lessonPlansStore.currentPage" @requestPage="loadPage"  />

			</template>

        </div>

    </TheStdLayout>
</template>

<script lang="ts">

import { mapStores, mapState } from "pinia";
import { useUsersStore } from "@/stores/Users.store";
import { useLessonPlansStore } from "@/stores/LessonPlans.store";
import LessonPlanEditor from "@/components/LessonPlanEditor.vue";

import { PlusIcon, PencilIcon, FolderIcon, FolderOpenIcon, FunnelIcon, ChevronDownIcon, CheckIcon, XMarkIcon, PaperClipIcon } from '@heroicons/vue/24/outline';
import { MapPinIcon } from "@heroicons/vue/24/solid";
import LessonPlanFilter from "@/components/LessonPlanFilter.vue";
import Skeleton from '@/components/ui/Skeleton.vue';
import Tag from "@/components/ui/Tag.vue";
import GoalTitle from "@/components/GoalTitle.vue";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";
import {ZillLoader} from "@/goals/zill/utils/ZillLoader.util";
import Pagination from "@/components/ui/Pagination.vue";
import {PaginationState} from "@/models/PaginationState.model";
import SortableHeader from "@/components/ui/SortableHeader.vue";
import {DateTime} from "luxon";
import {OrderParameter} from "@/models/OrderParameter.model";


export default {

	components: {
		SortableHeader,
		Pagination,
		Skeleton,
        LessonPlanEditor,
        PlusIcon,
		PencilIcon,
		FolderIcon,
		FolderOpenIcon,
		FunnelIcon,
		ChevronDownIcon,
		CheckIcon,
		XMarkIcon,
		PaperClipIcon,
		MapPinIcon,
		LessonPlanFilter,
		Tag,
		GoalTitle,
    },

	data() {
		return {
			loaded: false,
			lessonPlan: null,
			showEditor: false,
			filterPanelOpen: false,
		};
	},

    computed: {
        ...mapStores(useLessonPlansStore),
		...mapState(useUsersStore, ['me']),

		localDate() {
			return (date: Date) => {
				return DateTime.fromJSDate(date).toLocaleString();
			};
		},
    },

	mounted() {
		this.loadPage();
	},

    methods: {

		sortedLessonPlanGoals(lessonPlan) {
			return lessonPlan.goals.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
		},

		sortedLessonPlanTags(lessonPlan) {
			const alphabeticalButThemesFirst = (a, b) => {
				if (a.type === 'theme' && b.type === 'activity') return -1;
				if (a.type === 'activity' && b.type === 'theme') return 1;
				return a.name.localeCompare(b.name);
			};
			return lessonPlan.tags.sort(alphabeticalButThemesFirst);
		},
		handleCreateIntent() {
			this.showEditor = true;
		},

		handleEditIntent(lessonPlan) {
			this.lessonPlan = lessonPlan;
			this.showEditor = true;
		},

		closeEditor() {
			this.showEditor = false;
			this.lessonPlan = null;
		},

		async loadPage(page: PaginationState = null) {
			this.loaded = false;
			await this.lessonPlansStore.load(page);
			this.loaded = true;
		},

		async changeOrder(order: OrderParameter) {
			this.loaded = false;
			await this.lessonPlansStore.changeOrder(order);
			this.loaded = true;
		}
	}


};

</script>
