<template>
    <div :class="[ pulse ? 'animate-pulse' : '']">
        <div class="flex flex-col gap-4">
            <div v-for="i in items" class="flex gap-10 items-center">
                <template v-if="context == 'menu'">
                    <div class="flex px-2 items-center">
                        <div v-show="withThumb" class="w-6 h-6 mr-3 bg-base-200"></div>
                        <div class="bg-base-200 h-4 rounded-lg" :class="getRandomTxtLength()"></div>
                    </div>
                </template>
                <template v-else>
                    <div v-show="withThumb" class="avatar shrink-0">
                        <div class="w-16 h-16 bg-base-200" :class="thumbnailClasses">
                            <div class="w-full h-full flex justify-center items-center">
                                <CameraIcon class="w-8 text-base" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3">
                        <div class="flex gap-2">
                            <div class="bg-base-200 h-4 rounded-lg" :class="getRandomTxtLength()"></div>
                            <div class="bg-base-200 h-4 rounded-lg" :class="getRandomTxtLength()"></div>
                        </div>
                        <div class="flex">
                            <div class="bg-base-200 h-2 rounded" :class="getRandomTxtLength()"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { CameraIcon } from '@heroicons/vue/24/solid';

export default {
    props: {
        context: {
            type: String,
            default: 'datalist',
        },
        withThumb: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Number,
            default: 3,
        },
        thumbnailClasses: {
            type: String,
            default: '',
        },
        pulse: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        CameraIcon,
    },
    methods: {
        getRandomTxtLength() {
            const sizes = ['w-24', 'w-32', 'w-40', 'w-48'];
            return sizes[ Math.floor(Math.random() * sizes.length) ];
        }
    }
}
</script>