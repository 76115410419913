<template>
	<div class="w-full relative mt-1 flex justify-center">
		<button
			type="button"
			@click="requestOpenPopover"
			class="input input-sm input-bordered w-full flex justify-between items-center text-base-content-light"
		>
			<span>{{ $t('Search') }}</span>
			<MagnifyingGlassIcon class="w-4 h-4"></MagnifyingGlassIcon>
		</button>

		<Transition
			leave-from-class="opacity-100"
			leave-active-class="transition ease-in duration-100"
			leave-to-class="opacity-0"
		>
			<div v-show="openPopover"
				class="absolute -top-2 z-20 sm:max-w-lg overflow-auto rounded-md bg-base pb-1 shadow-lg ring-1 ring-neutral ring-opacity-10 focus:outline-none sm:text-sm"
				style="width: 28rem; max-height: 75vh;"
				tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3"
			>

				<div class="sticky top-0 z-10 bg-base pt-1">
					<div class="relative mx-4 py-2" style="animation: button-pop 0.25s ease-out;">
						<input type="text" id="itemSearchField" class="input input-bordered w-full pr-16"
							:placeholder="$t('Search week by theme')"
							v-model="searchQuery"
						/>
						<button v-show="searchQuery" @click="clearSearch" class="text-accent hover:text-accent-focus absolute inset-y-0 right-9">
							<XMarkIcon class="w-5 h-5"></XMarkIcon>
						</button>
						<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<MagnifyingGlassIcon class="w-5 h-5"></MagnifyingGlassIcon>
						</div>
					</div>
				</div>

				<template v-if="searching">
					<div class="text-center py-2">
						<span class="loading loading-md text-base-content-light"></span>
					</div>
				</template>
				<template v-else>
					<div v-for="(item,index) in searchResults" :key="index">
						<slot name="item" v-bind="item"></slot>
					</div>
				</template>
			</div>
		</Transition>
	</div>
</template>

<script lang="ts">
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
	emits: ['update:modelValue'],
	props: {
		// search query
		modelValue: {
			type: String,
			default: '',
		},
		searchResults: {
			type: Array,
			required: true,
		},
		searching: {
			type: Boolean,
			default: false,
		}
	},

	components: {
		MagnifyingGlassIcon, XMarkIcon,
	},

	data() {
		return {
			openPopover: false,
		};
	},

	computed: {
		searchQuery: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		}
	},

	methods: {

		showPopover() {
			this.openPopover = true;
			setTimeout(() => document.getElementById("itemSearchField").focus(), 200);
		},

		hidePopover() {
			this.openPopover = false;
		},

		requestOpenPopover(event) {
			event.stopPropagation();
			window.addEventListener('click', this.closeIfPopoverClickOutside);
			this.showPopover();
		},

		closeIfPopoverClickOutside(event) {
			if(!this.$el.contains(event.target)) {
				window.removeEventListener('click', this.closeIfPopoverClickOutside);
				this.clearSearch();
				this.hidePopover();
			}
		},

		clearSearch() {
			this.searchQuery = '';
			const el = document.getElementById("itemSearchField");
			el.focus();
		},
	}
}
</script>
