import { useLessonsStore } from "@/stores/Lessons.store";
import { useThemesStore } from "@/stores/Themes.store";

export default new class {

	private hasReloaded: { [ key: string ]: boolean };

	processAlteredEntities(response: {
		removed: { id: string, type: string }[] | undefined,
		updated: any[] | undefined,
	} | undefined) {

		this.hasReloaded = {};
		if (typeof(response) === 'undefined') return;

		if (typeof (response.removed) !== 'undefined') {
			response.removed.forEach((model) => {
				this.removeEntity(model.id, model.type);
			});
		}

		if (typeof (response.updated) !== 'undefined') {
			response.updated.forEach((model) => {
				this.updateEntity(model.id, model.type);
			});
		}

	}

	removeEntity(id: string, type: string) {
		switch (type) {
			case 'lesson':
				useLessonsStore().removeFromId(id);
				break;

			case 'theme':
				useThemesStore().removeFromId(id);
				break;

			default:
				console.error('Unknown entity type', type);
		}
	}

	updateEntity(id: string, type: string) {
		switch (type) {
			case 'lesson':
				if (!this.hasReloaded['lesson']) {
					this.hasReloaded['lesson'] = true;
					useLessonsStore().reload();
				}
				break;

			case 'theme':
				if (!this.hasReloaded['theme']) {
					this.hasReloaded['theme'] = true;
					useThemesStore().reload();
				}
				break;

			default:
				console.error('Unknown entity type', type);
		}
	}

}
