<template>

	<div v-if="loading">
		<div class="flex justify-center">
			<span class="loading loading-spinner loading-md"></span>
		</div>
	</div>

	<template v-if="!loading">

		<table>

			<template v-for="(activity, index) in activities" :key="index">
				<tr>
					<td class="px-4 py-2">{{ activity.activityName }}</td>
					<td class="px-4 py-2">
						<div class="flex">
							<div class="h-3 overflow-hidden rounded-md" :style="'width: ' + calcBarWidth(activity.totalTimeSpent) + 'px;'">
								<div class="h-3 flex bg-green-400 overflow-hidden growing-bar">
								<template v-for="(activity,index) in activity.activities" :key="index">
									<div class="h-3 odd:bg-green-300" :style="'width: ' + calcBarWidth(activity.timeSpent) + 'px;'" :title="activity.startTime"></div>
								</template>
								</div>
							</div>
							<span class="text-xs pl-2 text-gray-500">{{ readableDuration(activity.totalTimeSpent) }}</span>
							<span class="text-xs pl-2 text-gray-500" >{{ '(' + percentageOfAllTimeSpent(activity.totalTimeSpent) + '%)' }}</span>
						</div>
					</td>
				</tr>
			</template>

		</table>

		<p v-if="activities.length === 0">{{ $t('No activity was recorded during this period') }}</p>
	</template>



</template>

<style>
.growing-bar {
	animation-name: grow;
	animation-duration: .5s;
	animation-timing-function: ease-out;
	animation-iteration-count: 1;
}
@keyframes grow {
	0% { width: 0%; }
  	100% { width: 100%; }
}
</style>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import {InsightsFilter, useInsightsStore} from "@/stores/Insights.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import {Pupil} from "@/models/Pupil.model";
import { Duration } from "luxon";

export default {


	props: {
		pupil: {
			type: Pupil,
			required: true
		},

		filter: {
			type: InsightsFilter,
			required: true
		}
	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore),
		...mapState(useInsightsStore, [ 'activities', 'maxTotalTimeSpentInActivity', 'totalTimeSpent' ]),
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: false
		}
	},

	async mounted() {
		this.reload();
	},

	watch: {
		filter() {
			this.reload();
		},

		pupil() {
			this.reload();
		}
	},

	methods: {

		async reload() {
			this.loading = true;
			await this.insightsStore.loadActivities(this.pupil, this.filter);
			this.loading = false;
		},

		calcBarWidth(value) {
			return value * (this.maxBarWidth / this.maxTotalTimeSpentInActivity);
		},

		readableDuration(seconds) {
			let duration = Duration.fromObject({ seconds: seconds });
			let roundedDuration = duration.mapUnits(x => Math.round(x / 60) * 60);
			return roundedDuration.shiftTo('minutes').toHuman({
				unitDisplay: 'short',
				type: 'unit',
			});
		},

		percentageOfAllTimeSpent(seconds) {
			return Math.round(100 * seconds / this.totalTimeSpent);
		}

	}

}
</script>
