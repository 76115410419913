import './style.css';

import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createPinia } from 'pinia';
import { setupI18n } from '@/i18n';
import { setupRouter } from '@/router';
import Notifications from  'notiwind';
import { connectToPusher} from '@/connectToPusher.js';
import TheStdLayout from "@/components/layout/TheStdLayout.vue";
import Select from '@/components/ui/base/Select.component.vue';
import Input from '@/components/ui/base/Input.component.vue';
import FileInput from '@/components/ui/base/FileInput.component.vue';
import Textarea from '@/components/ui/base/Textarea.component.vue';
import EmptyState from '@/components/ui/EmptyState.vue';
import FloatingPlusButton from '@/components/ui/FloatingPlusButton.vue';
import * as Sentry from "@sentry/vue";
import config from "@/config/app.config";
import {MaintenanceMode} from "@/utils/MaintenanceMode.util";
import VueGtag from "vue-gtag";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import * as ConfirmDialog from 'vuejs-confirm-dialog'


async function launch() {

	//await requireAccessToken();

	const app = createApp(App);

	const i18n = await setupI18n({ locale:  localStorage.getItem('locale') || window.navigator.language });
	app.use(i18n);

	// Check maintenance mode
	if (!MaintenanceMode.checkMaintenanceMode(i18n)) {
		return;
	}

	// Router
	const router = setupRouter();

	// Pinia
	const pinia = createPinia();
	app.use(pinia);

	// Notifications
	app.use(Notifications);

	// Confirm dialog
	app.use(ConfirmDialog);

	// Axios
	app.use(VueAxios, { axios: axios });

	// Register a bunch of global components
	app.component('TheStdLayout', TheStdLayout);
	app.component('sms-select', Select);
	app.component('sms-input', Input);
	app.component('sms-textarea', Textarea)
	app.component('sms-file-input', FileInput);
	app.component('EmptyState', EmptyState);
	app.component('FloatingPlusButton', FloatingPlusButton);

	if (config.SENTRY_DSN) {
		Sentry.init({
			app,
			dsn: config.SENTRY_DSN,
			environment: config.ENV,
			release: config.RELEASE_NAME,
			integrations: [
				new Sentry.BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				}),
				new Sentry.Replay(),
			],

			// Ignore handled errors from API
			beforeSend(event, hint) {
				if (hint.originalException) {
					const error = hint.originalException;
					if (error instanceof ApiErrors) {
						return null;
					}
				}
				return event;
			},

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE,

			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", config.API_URL],

			// Capture Replay for 10% of all sessions,
			// plus for 100% of sessions with an error
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	}

	if (config.GA_MEASUREMENT_ID) {
		// Google Analytics
		app.use(VueGtag, {
			config: { id: config.GA_MEASUREMENT_ID },
		}, router);
	}

	app.use(router);
	app.mount('#app');

}

launch();
