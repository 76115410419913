import {defineStore, mapActions} from 'pinia';
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {Classroom} from "@/models/Classroom.model";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import {User} from "@/models/User.model";
import {orgApi} from "@/utils/Api.util";
import { Organisation } from '@/models/Organisation.model';
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { usePupilsStore } from "@/stores/Pupils.store";

interface ClassroomStoreState {
	errorMessage: ErrorMessage | null,
	currentClassroom: Classroom | null,
	classrooms: Classroom[]
}

export const useClassroomsStore = defineStore('classrooms', {

	state: (): ClassroomStoreState => ({
		errorMessage: null,
		currentClassroom: null,
		classrooms: []
	}),

	actions: {

		new() {
			let newClassroom = new Classroom();
			return newClassroom;
		},

		async load() {

			const response = await orgApi.get('classrooms', {
				params: {
					mask: [
						'id',
						'name',
						'pupilCount',
						'teachers.id'
					].join(',')
				}
			});
			this.classrooms = response.data.data.map(Classroom.mapFromServer);

			this.sortItemsBy('name');
		},

		async save(classroom: Classroom) {

			try {
				if (classroom.hasId()) {
					const response = await orgApi.put('classrooms/' + classroom.id,
						classroom.getServerData(),
						{
							params: {
								mask: [
									'id',
									'name',
									'pupilCount',
									'teachers.id'
								].join(',')
							}
						}
					);
					this.updateStoreItem(classroom);
				} else {
					const response = await orgApi.post('classrooms',
						classroom.getServerData(),
						{
							params: {
								mask: [
									'id',
									'name',
									'pupilCount',
									'teachers.id'
								].join(',')
							}
						}
					);
					classroom.id = response.data.data.id;
					this.addStoreItem(classroom);
				}

				this.sortItemsBy('name');
			} catch (e) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		setDefaultCurrentClassroom(user: User, organisation: Organisation) {

			// Check for query path name, as those are used for deep linking
			const pathName = window.location.pathname;
			const pathNameParts = pathName.split('/');

			let classroomId = null;
			if (pathNameParts.length > 2 && pathNameParts[1] === 'classrooms') {
				classroomId = pathNameParts[2];
			}

			if (!classroomId) {
				// check localstorage
				classroomId = localStorage.getItem('classroom_id');
			}

			if (classroomId) {
				const classroom = organisation.classrooms.find(item => item.id === classroomId);

				if (classroom) {
					this.setCurrentClassroom(classroom);
					return;
				}
			}

			// if that didn't work, set the first one
			if (organisation.classrooms.length > 0) {
				this.setCurrentClassroom(organisation.classrooms[0]);
			} else {
				useOrganisationsStore().setCurrentOrganisation(organisation);
			}
		},

		setCurrentClassroom(classroom: Classroom) {

			// Store classroom in localstorage
			localStorage.setItem('classroom_id', classroom.id);

			this.currentClassroom = classroom;

			if (this.currentClassroom.organisation) {
				useOrganisationsStore().setCurrentOrganisation(this.currentClassroom.organisation);
			}
		},

		clearCurrentClassroom() {
			localStorage.removeItem('classroom_id');
			this.currentClassroom = null;
		},

		switchClassroom(classroom: Classroom) {
			this.setCurrentClassroom(classroom);

			// using router in Pinia requires another plugin/wrapper, so for the sake of simplicity,
			// using 'window.location' instead
			usePupilsStore().clear();	// otherwise you may notice some strange behaviour while waiting for the page to reload

			// location.reload does NOT work as the classroom id in the route is not updated and takes priority
			// over the value stored in localstorage. So we need to navigate to the same page.
			//window.location.reload();

			// Can't seem to access the router so just going to use the location path name.
			const pathName = window.location.pathname;
			const pathNameParts = pathName.split('/');
			if (pathNameParts[1] === 'classrooms' && pathNameParts.length > 2) {
				pathNameParts[2] = classroom.id;
			}

			// We always need to reload the page as currentClassroom is used in too many places, and is not
			// always reactive. Refactoring this would be a big job, and frankly, not worth it.
			window.location = pathNameParts.join('/');
		},

		sortItemsBy(attr) {
			this.classrooms.sort(
				(a: Classroom, b: Classroom) => {
					return a[attr].localeCompare(b[attr]);
				}
			);
		},

		updateStoreItem(classroom: Classroom) {
			let storeIndex = this.classrooms.findIndex(
				storeItem => storeItem.id == classroom.id
			);
			this.classrooms[storeIndex] = classroom.clone();
		},

		addStoreItem(classroom: Classroom) {
			const clonedClassroom = classroom.clone();
			this.classrooms.push(clonedClassroom);

			// Also add to the current organisation
			useOrganisationsStore().currentOrganisation.classrooms.push(clonedClassroom);

			// Is this the first classroom? If so, set it as current
			if (this.classrooms.length === 1) {
				this.setCurrentClassroom(clonedClassroom);
			}
		},

		removeStoreItem(classroom: Classroom) {

			const index = this.classrooms.findIndex(v => v.id === classroom.id);
			if (index >= 0) {
				this.classrooms.splice(index, 1);
			}

			if (this.currentClassroom && this.currentClassroom.id == classroom.id) {
				if (this.classrooms.length > 0) {
					this.switchClassroom(this.classrooms[0]);
				} else {
					this.clearCurrentClassroom();
					window.location.reload(); // yucky and hacky, but works.
				}
			}

		},

		async delete(classroom: Classroom) {
			try {
				await orgApi.delete("classrooms/" + classroom.id);
				this.removeStoreItem(classroom);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}
		}
	}

});
