export class Tag {
	public id;
	public name;
	public type;
	public inUse;

	static mapManyFromServer(data: any) {
		return data.map((item: any) => Tag.mapFromServer(item));
	}

	static mapFromServer(data: any) {
		const newObject = new Tag();
		newObject.setFromServerData(data);
		return newObject;
	}

	setFromServerData(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.type = data.type;
		this.inUse = data.inUse;
	}

	getServerData() {
		return {
			id: this.id,
			name: this.name,
			type: this.type
		};
	}
}
