<template>
	<TheStdLayout :title="$tc('Goal', 2)">

		<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

			<div v-if="canImportGoals" class="text-right mb-4">
				<button class="btn btn-primary rounded-full inline-flex items-center" @click="goToImport">
					<PlusIcon class="w-5 h-5 mr-2" />
					{{ $t('Import') }}
				</button>
			</div>

			<Skeleton v-if="!loaded" :withThumb="false" :items="5" class="mt-10" />

			<template v-else-if="goalsStore.curriculumItems.length">
				<GoalFilter
					:initialFilter="goalsStore.filter"
					:goalSource="goalSource"
					:collapsable="true"
					:collapsed="false"
					class="mb-8"
				/>

				<GoalList
					:goalSource="goalSource"
					:action="canEditGoals ? 'edit' : 'view'"
					@requestEdit="handleGoalEditRequest"
					@requestRemoval="handleGoalRemoveRequest"
				/>
			</template>

			<EmptyState v-else-if="canImportGoals"
				:title="$t('No goals added yet')"
				:description="$t('#instruction: import first goals')"
				:cta-label="$t('Import goals')"
				:cta-callback="goToImport"
			/>

			<EmptyState v-else-if="this.usersStore.me?.hasAdminPermissions()"
				:title="$t('Goals are not available yet for this school account')"
				:description="$t('#instruction: curriculum not set')"
			/>

			<EmptyState v-else
				:title="$t('No goals available yet')"
				:description="$t('The account admin has not added any goals yet')"
			/>

		</div>

		<GoalEditModal v-if="showGoalFormModal" :goal="goal" @requestClose="closeEditPopup" />

	</TheStdLayout>
</template>



<script lang="ts">
import { mapStores } from "pinia";
import { useGoalsStore } from "@/stores/Goals.store";
import {useUsersStore} from "@/stores/Users.store";
import GoalFilter from '@/components/GoalFilter.vue';
import GoalList from "@/components/GoalList.vue";
import GoalEditModal from '@/components/GoalEditModal.vue';
import Skeleton from '@/components/ui/Skeleton.vue';
import EmptyState from "@/components/ui/EmptyState.vue";
import { PlusIcon } from '@heroicons/vue/24/outline';
import {useOrganisationsStore} from "@/stores/Organisations.store";


export default {

	data() {
		return {
			loaded: false,
			goal: null,
			showGoalFormModal: false,
		};
	},

	components: {
		Skeleton,
		GoalFilter,
		GoalList,
		GoalEditModal,
		EmptyState,
		PlusIcon,
	},

	computed: {
		...mapStores(useGoalsStore, useUsersStore, useOrganisationsStore),

		canImportGoals() {
			return this.goalsStore.curriculum &&
				this.usersStore.me?.hasAdminPermissions();
		},

		canEditGoals() {
			return this.goalsStore.curriculum &&
				this.usersStore.me?.hasAdminPermissions();
		},

		goalSource() {
			return this.goalsStore.source;
		},
	},

	async mounted() {
		if (this.goalsStore.curriculum) {
			await this.goalsStore.load();
			this.goalsStore.filter.clear();
		}

		this.loaded = true;
	},

	methods: {

		handleGoalCreateRequest() {
			this.showGoalFormModal = true;
		},

		handleGoalEditRequest(goal) {
			this.goal = goal;
			this.showGoalFormModal = true;
		},

		handleGoalRemoveRequest(goal) {
			this.goalsStore.deleteItem(goal);
		},

		closeEditPopup() {
			this.goal = null;
			this.showGoalFormModal = false;
		},

		goToImport() {
			if (!this.canImportGoals) {
				return;
			}

			this.$router.push({ name: 'goals.import' });
		},

	},

};

</script>
