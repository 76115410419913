<template>
	<div class="flex justify-between  border-t border-base-200">
		<div class="pt-4 text-sm font-medium text-base-content-light">
			{{ $t('paginatorResults', {startRecord: page.from, endRecord: page.to, totalRecords: page.total}) }}
		</div>
		<nav class="flex gap-1 items-center pt-2">
			<button class="btn btn-sm btn-ghost" @click="requestPageDown" :disabled="!page.hasPrevious()">
				<ChevronLeftIcon class="h-5 w-5 text-base-content-light" aria-hidden="true" />
			</button>
			<div class="text-sm font-bold text-base-content-light">
				<span class="text-accent">{{ page.currentPage }}</span> / {{ page.lastPage }}
			</div>
			<button class="btn btn-sm btn-ghost" @click="requestPageUp"  :disabled="!page.hasNext()">
				<ChevronRightIcon class="h-5 w-5 text-base-content-light" aria-hidden="true" />
			</button>
		</nav>
	</div>
</template>

<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import {PaginationState} from "@/models/PaginationState.model";

export default {
	emits: [ 'requestPage' ],

	props: {
		page: {
			type: PaginationState,
			required: true
		}
	},

	components: {
		ChevronLeftIcon,
		ChevronRightIcon
	},

	computed: {
		totalPages() {
			return Math.ceil(this.totalRecords / this.perPage);
		},
	},

	methods: {
		requestPageUp() {
			const page = this.page.getNext();
			if (page) {
				this.$emit('requestPage', page);
			}
		},

		requestPageDown() {
			const page = this.page.getPrevious();
			if (page) {
				this.$emit('requestPage', page);
			}
		}
	}
}
</script>
