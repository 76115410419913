import {defineStore} from "pinia";
import {CopySelection} from "@/models/CopySelection.model";
import {Interval} from "luxon";

interface ClipboardStoreState {
	clipboard: CopySelection[]
}

export const useClipboardStore = defineStore('clipboard', {
	state: (): ClipboardStoreState => {
		const clipboard = [];

		// Load from localStorage
		try {
			const clipboardData = localStorage.getItem('clipboard');
			if (clipboardData) {
				const data = JSON.parse(clipboardData);
				data.forEach((copySelection) => {
					clipboard.push(CopySelection.deserialize(copySelection));
				});
			}
		} catch (e) {
			console.error(e);
		}

		return {
			clipboard: clipboard
		};
	},

	actions: {
		copy(interval: Interval, themeNames: string[] = null) {
			const copySelection = new CopySelection()
			copySelection.interval = interval;
			copySelection.themeNames = themeNames;

			// Make sure it's not in the board already
			const existingIndex = this.clipboard.findIndex((item: CopySelection) => {
				return item.interval.start.toString() === interval.start.toString()
					&& item.interval.end.toString() === interval.end.toString();
			});

			if (existingIndex >= 0) {
				this.clipboard.splice(existingIndex, 1);
			}

			this.clipboard.push(copySelection);
			this.storeInLocalStorage();
		},

		clear() {
			this.clipboard = [];
			this.storeInLocalStorage();
		},

		remove(copySelection: CopySelection) {
			const index = this.clipboard.indexOf(copySelection);
			if (index >= 0) {
				this.clipboard.splice(index, 1);
			}
			this.storeInLocalStorage();
		},

		storeInLocalStorage() {
			try {
				const data = [];
				this.clipboard.forEach((copySelection) => {
					data.push(copySelection.serialize());
				});
				localStorage.setItem('clipboard', JSON.stringify(data));
			} catch (e) {
				console.error(e);
			}
		}
	}
});
