<template>

	<a v-if="file" :href="file.getVariationOrDefault('lg').url" target="_blank">
		<img :src="file.getVariationOrDefault('sm').url" class="w-full h-full object-contain rounded-md" />
	</a>

</template>

<script lang="ts">
import { File } from "@/models/File.model";

export default {
	props: {
		file: {
			type: File,
			required: false,
		}
	}
}
</script>
