<template>
	<div class="join border">
		<button type="button" @click="decrementSeats()" class="btn btn-ghost join-item px-2">
			<MinusIcon class="w-5 h-5" />
		</button>
		<input id="seats" type="text" inputmode="numeric" pattern="\d+" readonly required name="seats" v-model="number" v-bind="$attrs" class="input text-center join-item" />
		<button type="button" @click="incrementSeats()" class="btn btn-ghost join-item px-2">
			<PlusIcon class="w-5 h-5" />
		</button>
	</div>
</template>

<script lang="ts">
import {
    PlusIcon,
	MinusIcon
 } from '@heroicons/vue/24/outline';

export default {
	inheritAttrs: false,
	props: {
		modelValue: {
			type: Number,
			default: 0,
		},
		stepValue: {
			type: Number,
			default: 1,
		},
	},
	components: {
		PlusIcon,
		MinusIcon
	},
	data() {
        return {
            number: this.modelValue,
        };
    },
	methods: {
		incrementSeats() {
			this.number += this.stepValue
			this.$emit('update:modelValue', this.number);
		},
		decrementSeats() {
			this.number -= this.stepValue;
			if (this.number < 0) {
				this.number = 0;
			}
			this.$emit('update:modelValue', this.number);
		},
	},
}
</script>