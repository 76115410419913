import { Organisation } from "@/models/Organisation.model";

export class UserRole {

	public organisation: Organisation;
	public role: string;
	public active: boolean;

	static mapFromServer(data: any) {
		const orgUser = new UserRole();
		orgUser.setFromServerData(data);

		return orgUser;
	}

	setFromServerData(data: any) {
		this.organisation = Organisation.mapFromServer(data.organisation);
		this.role = data.role;
		this.active = data.active;
	}

	hasOwnerPermissions() {
		return this.role === 'owner';
	}

	hasAdminPermissions() {
		return this.role === 'admin' || this.hasOwnerPermissions();
	}

	hasTeacherPermissions() {
		return true;
	}

	clone() {
		const clonedInstance = new UserRole();
		clonedInstance.organisation = this.organisation.clone();
		clonedInstance.role = this.role;
		clonedInstance.active = this.active;

		return clonedInstance;
	}
}
