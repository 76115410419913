<template>

	<Modal @requestUnmount="hide" :fullscreen="false" width="2xl">

		<template #header>
			<div class="font-semibold text-xl mb-4">
				<span v-if="inUpdateMode" v-text="$t('Edit goal')"></span>
				<span v-else v-text="$t('Add goal')"></span>
			</div>
		</template>

		<template #default="modalActions">

			<form @submit.prevent="save">

				<p v-if="goalsStore.errorMessage?.genericError" class="text-red-600 text-xs">
						{{ goalsStore.errorMessage?.genericError.toString() }}
				</p>

				<div class="grid grid-cols-1 gap-6 mb-6">

					<div class="italic border rounded p-2 whitespace-pre-wrap" v-text="editableModel.title">
					</div>

					<div class="">
						<label class="text-sm" v-text="$t('Custom description')"></label>
						<textarea v-model="editableModel.customTitle" rows="3" class="textarea w-full"></textarea>
					</div>

				</div>


				<div class="modal-footer flex" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
					<div></div>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="modalActions.hide"
										:class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>


<script lang="ts">
import {mapStores} from 'pinia';

import {useGoalsStore} from "@/stores/Goals.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";

import {Goal} from "@/models/Goal.model";

import Modal from "@/components/ui/Modal.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import {TrashIcon} from '@heroicons/vue/24/outline';

export default {
	props: {
		goal: {
			type: Goal,
			required: false,
		},
	},
	components: {
		Modal,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon,
	},
	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
		}
	},
	computed: {
		...mapStores(useGoalsStore),

		inUpdateMode() {
			return (this.goal && this.goal.id) ? true : false;
		}
	},

	methods: {

		hide() {
			this.goalsStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		async save() {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.goalsStore.saveItem(this.editableModel);

				this.saving = false;
				this.hide();

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},
	},

	beforeMount() {
		if (this.goal && this.goal.id) {
			this.originalModel = this.goal;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.goal.clone();
		} else {
			this.editableModel = this.goalsStore.new();
		}
	},
}
</script>
