<template>
    <div ref="fbcontainer">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
    props: {
        options: Object,
    },
    mounted() {
        Fancybox.bind('[data-fancybox="gallery"]', {
            ...(this.options || {}),
        });
    },
    updated() {
        Fancybox.unbind(this.$refs.fbcontainer);
        Fancybox.close();

        Fancybox.bind('[data-fancybox="gallery"]', {
            ...(this.options || {}),
        });
    },
    unmounted() {
        Fancybox.destroy();
    },
};
</script>