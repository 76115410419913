<template>

	<p class="text-xs font-bold text-base-content flex items-center mb-4" v-if="header">
		<PaperClipIcon class="w-4 h-4 mr-1" />
		{{ $t('Attachments' )}}

		<label :for="'input-' + inputId" class="cursor-pointer ml-4" @click="openFileDialog()">
			<span class="btn btn-primary btn-circle btn-xs">
				<PlusIcon class="w-4 h-4"></PlusIcon>
			</span>
		</label>
	</p>

	<template v-if="!readOnly">
		<sms-file-input ref="fileInput" class="hidden" :id="'input-' + inputId" :accept="accept" @selectFile="selectFile" :clearAfterSelect="true" />

		<div class="flex items-center justify-center gap-2 mb-6">
			<GoogleDrivePicker v-if="externalFilesButton && usersStore.me.hasCapability('google-drive')" @filesSelected="selectExternalFiles" ref="googleFilePicker" class="btn btn-outline" :label="$t('Pick from Google Drive')" />
			<OneDrivePicker v-if="externalFilesButton && usersStore.me.hasCapability('onedrive')" @filesSelected="selectExternalFiles" ref="oneDriveFilePicker" class="btn btn-outline" :label="$t('Pick from OneDrive')" />

			<button v-if="uploadButton" type="button" class="btn btn-primary" @click="openFileDialog()">
				<DocumentArrowUpIcon class="w-5 h-5" />
				<span class="hidden sm:inline-block">{{ $t('Upload file') }}</span>
			</button>
		</div>
	</template>


	<div v-if="!attachmentsLocalCopy.length && !uploads.length"  class="text-base-content-light text-sm italic text-center">
		{{ $t('No attachments added yet') }}
	</div>
	<template v-else>

		<!-- Preview mode -->
		<template v-if="preview">

			<div class="flex gap-1">

				<template  v-for="file in attachmentsLocalCopy">

					<!-- Image -->
					<div v-if="file.type === 'image'" class="w-24 mr-3 p-2 bg-gray-100 rounded border-2 border-gray-200 relative">
						<a class="right-0 bottom-0 absolute bg-gray-100 rounded-xl p-2 cursor-pointer" @click="removeAttachment(file);">
							<TrashIcon class="w-5 h-5"></TrashIcon>
						</a>
						<FilePreview :file="file" data-fancybox="gallery" />
					</div>

					<!-- Something else -->
					<div v-else >
						<AttachmentsUploadedFile :file="file" @remove="removeAttachment(file)" />
					</div>

				</template>
			</div>

			<div class="flex flex-col gap-1 mt-3">
				<AttachmentsUploadingFile v-for="file in uploads" :upload="file" @cancel="cancelUpload(file)" />
			</div>

		</template>

		<!-- List mode -->
		<div v-else class="flex flex-col gap-2">

			<AttachmentsUploadingFile v-for="file in uploads" :upload="file" @cancel="cancelUpload(file)" />
			<AttachmentsUploadedFile :file="file" @remove="removeAttachment(file)" v-for="file in attachmentsLocalCopy" :readOnly="readOnly" />

		</div>


	</template>

	<p v-if="validationError" class="text-red-600 text-xs">
        {{ validationError.toString() }}
    </p>

</template>

<script lang="ts">

import { mapStores } from "pinia";
import { useUploadStore } from "@/stores/Upload.store";
import AttachmentsUploadingFile from "@/components/AttachmentsUploadingFile.vue";
import AttachmentsUploadedFile from "@/components/AttachmentsUploadedFile.vue";
import { PaperClipIcon, PlusIcon, TrashIcon, DocumentArrowUpIcon } from "@heroicons/vue/24/outline";
import FilePreview from "@/components/FilePreview.vue";
import { FieldValidationError } from "@/stores/errors/FieldValidationError";
import GoogleDrivePicker from "@/components/GoogleDrivePicker.vue";
import {useUsersStore} from "@/stores/Users.store";
import OneDrivePicker from "@/components/OneDrivePicker.vue";
import {FileExternal} from "@/models/FileExternal.model";


export default {
	components: {
		OneDrivePicker,
		FilePreview,
		AttachmentsUploadedFile,
		AttachmentsUploadingFile,
		PaperClipIcon, PlusIcon, TrashIcon, DocumentArrowUpIcon,
		GoogleDrivePicker
	},

	props: {
		modelValue: {
			type: Array
		},
		preview: {
			type: Boolean,
			default: false
		},
		accept: {
			type: String,
			default: '*'
		},
		uploading: {
			type: Boolean,
			default: false
		},
		header: {
			type: Boolean,
			default: false
		},
		uploadButton: {
			type: Boolean,
			default: false
		},
		externalFilesButton: {
			type: Boolean,
			default: false
		},
		readOnly: {
			type: Boolean,
			default: false
		},
	},

	computed: {
		...mapStores(useUploadStore, useUsersStore),
	},

	data() {
		return {
			uploads: [],
			attachmentsLocalCopy: [],
			inputId: null,
			validationError: null,
			uploadingFiles: 0,
		}
	},

	mounted() {
		this.inputId = Math.floor(Math.random() * 1000000);
		this.attachmentsLocalCopy = [ ... this.modelValue ].sort((a, b) => b.created_at.localeCompare(a.created_at));
	},

	methods: {
		selectFile(file) {
			if (!file) {
				return;
			}

			this.uploadingFiles ++;
			this.emitUploadingStatus();

			let uploadingFile = this.uploadStore.upload(file, (file) => {

				this.uploadingFiles --;
				this.emitUploadingStatus();

				this.attachmentsLocalCopy.unshift(file);
				this.uploads = this.uploads.filter(f => f !== uploadingFile);

				this.$emit('update:modelValue', this.attachmentsLocalCopy);
			}, (error) => {

				this.uploadingFiles --;
				this.emitUploadingStatus();

			});

			this.uploads.unshift(uploadingFile);
		},

		emitUploadingStatus() {
			this.$emit('update:uploading', this.uploadingFiles > 0);
		},

		filesDropped(files) {
			files.forEach(file => {
				this.selectFile(file);
			});
		},

		cancelUpload(file) {
			this.uploads = this.uploads.filter(f => f !== file);
		},

		removeAttachment(file) {
			this.attachmentsLocalCopy = this.attachmentsLocalCopy.filter(f => f !== file);
			this.$emit('update:modelValue', this.attachmentsLocalCopy);
		},

		openFileDialog() {
			this.$refs.fileInput.openFileDialog();
		},

		selectExternalFiles(files: FileExternal[]) {
			files.forEach(file => {
				this.selectExternalFile(file);
			});
		},

		async selectExternalFile(file: FileExternal) {

			this.uploadingFiles ++;
			this.emitUploadingStatus();

			try {
				let uploadedFile = await this.uploadStore.addFromExternalStorage(file);

				this.uploadingFiles--;
				this.emitUploadingStatus();

				this.attachmentsLocalCopy.unshift(uploadedFile);
				this.$emit('update:modelValue', this.attachmentsLocalCopy);

			} catch (e) {
				this.uploadingFiles--;
				this.emitUploadingStatus();
			}

		}

	}

}

</script>
