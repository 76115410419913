<template>
	<label v-if="label" class="block input-label" :class="[validationError? 'text-error-content' : '']">{{ label }}</label>
	<input v-bind="$attrs" class="input input-bordered w-full" :class="[validationError? 'input-error' : '']" ref="textInput" :value="modelValue" @input="updateValue()" />

	<!-- <span v-if="validationError" class="text-red-600 text-xs">
		{{ validationError.toString() }}
	</span> -->
</template>

<script lang="ts">

import {FieldValidationError} from "@/stores/errors/FieldValidationError";

export default {
	inheritAttrs: false,
	props: {
		modelValue: { type: String, default: '' },
		label: { type: String },
		validationError: { type: FieldValidationError },
	},

	methods: {
		updateValue() {
			if (this.$refs.textInput.value !== '') {
				this.$emit('update:modelValue', this.$refs.textInput.value)
			} else {
				this.$emit('update:modelValue', null)
			}
		}
  	}
}
</script>
