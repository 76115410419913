import { Symbol } from "@/models/Symbol.model";
import { Icon } from "@/models/Icon.model";
import { HasSymbol } from "@/interfaces/HasSymbol";

export abstract class AbstractHasSymbol implements HasSymbol {
	public symbol: Symbol | null = null;
	public icon: Icon | null = null;

	/**
	 * WARNING.
	 * This method will EITHER return a Symbol or an Icon.
	 * This method is thus NOT the same as Pupil.symbol or Pupil.icon
	 */
	getSymbol(): Symbol | Icon {
		if (this.icon) {
			return this.icon;
		}

		return this.symbol;
	}

	/**
	 * WARNING.
	 * This method can be used to either set a Symbol or an Icon.
	 * @param symbol
	 */
	setSymbol(symbol: Symbol | Icon) {
		if (symbol instanceof Symbol) {
			this.symbol = symbol;
			this.icon = null;
		} else if (symbol instanceof Icon) {
			this.icon = symbol;
			this.symbol = null;
		}
		return this;
	}
}
