<template>
    <TheStdLayout :title="$t('Report a problem')">

        <div class="py-6 px-4 flex flex-col gap-2">
            <p>{{ $t('Found a problem?') }}</p>
            <p>{{ $t('Is something not quite clear?') }}</p>
            <p>{{ $t('Or do you have a general suggestion to make Smart Symbols even better?') }}</p>
            <p v-html="$t('Let us know at {email}.', { email: emailLink })" class="mt-4"></p>
        </div>

    </TheStdLayout>
</template>

<script lang="ts">
    import TheStdLayout from "@/components/layout/TheStdLayout.vue";

    export default {
        components: {
            TheStdLayout,
        },
        data() {
            return {
                emailLink: '<a href="mailto:support@smartsymbols.com" target="_blank" class="font-bold inline-block px-1 border-b-4 border-primary">support@smartsymbols.com</a>',
            };
        },
    }
</script>