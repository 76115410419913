import {LessonItem} from "@/models/LessonItem.model";
import {Theme} from "@/models/Theme.model";
import { Classroom } from "@/models/Classroom.model";
import { Comparable } from "@/interfaces/Comparable.interface";
import { AbstractAgendaItemModel } from "@/models/AbstractAgendaItem.model";

export class Lesson extends AbstractAgendaItemModel implements Comparable {

	static BOARD_TYPE_ACTIVITIES = 'activities';	// lesson with choice activities
	static BOARD_TYPE_REFLECTION = 'reflection'; 	// lesson using reflection board
	static DEFAULT_COLOR = '#bae6fd';
	static AVAILABLE_COLORS = ['#d9f99d', '#fef08a', '#fed7aa', '#fecaca', '#e9d5ff', '#c7d2fe', '#bae6fd', '#e5e7eb'];

    public id: string;
	public originalId: string;

    public title: string;

    public classroomId: string;

    public color: string;

    public boardType: string;

    public backgroundColor: string;
    public borderColor: string;

    public lessonItems: LessonItem[] = [];
    public theme: Theme = null
	public paused: boolean = false;
	public broadcast_channel;
	public classroom: Classroom = null;

	constructor() {
		super();
		this.color = Lesson.DEFAULT_COLOR;
	}

	// to deal with legacy (tailwind) colors
	get hexColor() {
		if (!this.color) {
			return Lesson.DEFAULT_COLOR;
		}

		if (this.color.startsWith('#')) {
			return this.color;
		} else {
			switch (this.color) {
				case 'sky':
					return '#bae6fd';
				case 'lime':
					return '#d9f99d';
				case 'yellow':
					return '#fef08a';
				case 'red':
					return '#fecaca';
				case 'purple':
					return '#e9d5ff';
				default: // gray
					return '#e5e7eb';
			}
		}
	}

    hasId() {
        return this.id !== undefined;
    }

    addLessonItem(lessonItem: LessonItem) {
        this.lessonItems.unshift(lessonItem);
    }

    /**
     * @param lessonItem
     */
    removeLessonItem(lessonItem: LessonItem): boolean {

        let index = this.lessonItems.findIndex((item: LessonItem) => {
            return item === lessonItem ||
                (item.id && item.id === lessonItem.id || item === lessonItem);
        });

        if (index >= 0) {
            this.lessonItems.splice(index, 1);
            return true;
        }
        return false;
    }

    public isEmpty(): boolean {
        return this.lessonItems.length === 0;
    }

    public static mapFromServer(data: any) {
        return (new Lesson()).setFromServerData(data);
    }

    public setFromServerData(data: any) {

        this.id = data.id;
        this.start = new Date(data.start);
        this.end = new Date(data.end);
        this.title = data.title;
        this.color = data.color;
        this.boardType = data.type === 'collective' ? '' : data.type;

        this.backgroundColor = this.color;
        this.borderColor = this.color;

        if (data.lessonItems) {
            this.lessonItems = data.lessonItems.map((item: any) => {
                return LessonItem.mapFromServer(item);
            });
        }

        if (data.theme) {
            this.theme = Theme.mapFromServer(data.theme);
        }

		if (typeof(data.paused) !== 'undefined') {
			this.paused = data.paused;
		}

		if (typeof(data.broadcast_channel) !== 'undefined') {
			this.broadcast_channel = data.broadcast_channel;
		}

		if (typeof(data.classroom) !== 'undefined') {
			this.classroom = Classroom.mapFromServer(data.classroom);
		}

        return this;
    }

    public setFromModel(lesson: Lesson) {
        this.id = lesson.id;
		this.originalId = lesson.originalId;
        this.start = new Date(lesson.start);
        this.end = new Date(lesson.end);
        this.title = lesson.title;
        this.color = lesson.color;
        this.boardType = lesson.boardType;
        this.lessonItems = lesson.lessonItems.map((item: LessonItem) => {
            return item.clone();
        });

        this.backgroundColor = this.color;
        this.borderColor = this.color;
        this.theme = lesson.theme;

        return this;
    }

    asApiObject() {
        const out: any = {
			originalId: this.originalId,
            title: this.title,
            start: this.start.toISOString(),
            end: this.end.toISOString(),
            color: this.color,
            type: this.boardType && this.boardType !== '' ? this.boardType : 'collective',
        };

        if (this.lessonItems) {
            out.lessonItems = this.lessonItems.map((item: LessonItem) => {
                return item.getServerData();
            });
        }

        if (this.theme) {
            out.theme = {
                id: this.theme.id
            };
        }

        return out;
    }

	public asFullCalendarEvent() {
		return {
			id: this.id,
			title: this.title ?? '',
			start: this.start,
			end: this.end,
			extendedProps: {
				type: 'lesson',
				color: this.hexColor,
				lessonItemLength: this.lessonItems.length,
				lessonSummary: this.composedLessonSummary(),
				short: (this.end.getTime() - this.start.getTime()) < 1000 * 60 * 30, // 30 minutes
			},
			constraint: {
				startTime: '00:00',
				endTime: '24:00'
			},
		}
	}

	private composedLessonSummary() {
		if(!this.lessonItems || this.lessonItems.length === 0) {
			return '';
		} else {
			if(this.boardType === Lesson.BOARD_TYPE_ACTIVITIES) {
				return this.lessonItems.map(item => item.activity?.name).join(', ');
			} else {
				return this.lessonItems.map(item => item.lessonPlan?.title).join(', ');
			}
		}
	};

    public clone() {
        return (new Lesson()).setFromModel(this);
    }

	public equals(other: Comparable) {
		if (!(other instanceof Lesson)) {
			return false;
		}

		return JSON.stringify(this.asApiObject()) === JSON.stringify(other.asApiObject());
	}
}
