import {defineStore} from "pinia";
import { api, orgApi, setAccessToken, setOrganisationId } from "@/utils/Api.util";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {Classroom} from "@/models/Classroom.model";
import {useUsersStore} from "@/stores/Users.store";
import {Lesson} from "@/models/Lesson.model";
import { DateTime } from "luxon";
import { useLessonsStore } from "@/stores/Lessons.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";

interface ActivityBoardConnectToken {
	accessToken: any,
	classroom: Classroom
}

interface ActivityBoardConnectStoreState {
	loaded: boolean,
	token: string | null,
	availableClassrooms: ActivityBoardConnectToken[],
	currentClassroom: Classroom | null,
	classroomLessons: Lesson[],
	hasReflectionBoard: boolean
}

export const useActivityBoardConnectStore = defineStore('activityBoardConnect', {
	state: (): ActivityBoardConnectStoreState => {
		return {
			loaded: false,
			token: null,
			availableClassrooms: [],
			currentClassroom: null,
			classroomLessons: [],
			hasReflectionBoard: false
		}
	},

	actions: {

		async loadFromLocalStorage() {

			if (this.loaded) {
				return;
			}

			this.loaded = true;

			// Load tokens from localStorage
			const storedClassrooms = [];
			let localStorageTokens = window.localStorage.getItem('authenticated_classrooms');
			if (localStorageTokens) {
				localStorageTokens = JSON.parse(localStorageTokens);

				// @ts-ignore
				localStorageTokens.forEach((item: any) => {

					const classroom = Classroom.mapFromServer(item.classroom);

					storedClassrooms.push({
						accessToken: item.accessToken,
						classroom: classroom
					});
				});
			}

			this.availableClassrooms = storedClassrooms;

		},

		async generateActivityBoardConnectToken() {

			const classroomId = useClassroomsStore().currentClassroom.id;

			const response = await orgApi.post('pupil-app-connect', {
				classroom: {
					id: classroomId
				}
			});
			this.token = response.data.data.token;

		},

		async connect(token: string) {

			try {
				const response = await api.post('pupil-app-connect/connect', {
					token: token,
				}, {
					params: {
						mask: [
							'accessToken',
							'classroom.id',
							'classroom.name',
							'classroom.broadcast_channel'
						].join(',')
					}
				});

				const classroom = Classroom.mapFromServer(response.data.data.classroom);

				// Remove duplicates
				this.removeClassroom(classroom);

				const classroomToken = {
					accessToken: response.data.data.accessToken,
					classroom: classroom
				};

				// Add new token
				this.availableClassrooms.push(classroomToken);

				this.storeInLocalStorage();

				return classroomToken;

			} catch (e) {
				throw ApiErrors.fromAxiosException(e);
			}

		},

		async removeToken(token: ActivityBoardConnectToken) {
			this.removeClassroom(token.classroom);
		},

		async selectClassroomId(classroomId: number) {
			for (let i = 0; i < this.availableClassrooms.length; i ++) {
				if (this.availableClassrooms[i].classroom.id === classroomId) {
					return await this.selectToken(this.availableClassrooms[i]);
				}
			}
			return false;
		},

		async selectToken(token: ActivityBoardConnectToken) {
			//auth.setAccessToken(token.accessToken);
			setAccessToken(token.accessToken);

			await useUsersStore().clearMe();
			const user = await useUsersStore().loadMe();

			useClassroomsStore().clearCurrentClassroom();
			for (let o = 0; o < user.roles.length; o++) {
				const organisation = user.roles[o].organisation;
				for (let i = 0; i < organisation.classrooms.length; i ++) {
					if (organisation.classrooms[i].id === token.classroom.id) {
						this.currentClassroom = organisation.classrooms[i];
						useClassroomsStore().setCurrentClassroom(organisation.classrooms[i]);
						setOrganisationId(organisation.id);
						return true;
					}
				}
			}

			// Show an error message and remove the token from the list
			await this.removeToken(token);
			throw new Error('This token expired or you are not a member of this classroom anymore.');
		},

		async deselectToken() {
			//auth.clearAccessToken();
			this.currentClassroom = null;
			useClassroomsStore().clearCurrentClassroom();
		},

		removeClassroom(classroom: Classroom) {
			const existingIndex = this.availableClassrooms.findIndex((item) => {
				return item.classroom.id === classroom.id;
			});
			if (existingIndex >= 0) {
				this.availableClassrooms.splice(existingIndex, 1);
			}
			this.storeInLocalStorage();
		},

		storeInLocalStorage() {
			const out = [];
			for (let i = 0; i < this.availableClassrooms.length; i++) {
				out.push({
					accessToken: this.availableClassrooms[i].accessToken,
					classroom: {
						id: this.availableClassrooms[i].classroom.id,
						name: this.availableClassrooms[i].classroom.name,
						broadcast_channel: this.availableClassrooms[i].classroom.broadcast_channel
					}
				});
			}

			window.localStorage.setItem('authenticated_classrooms', JSON.stringify(out));
		},

		async loadClassroomLessons(reset = true) {

			const classroom = useClassroomsStore().currentClassroom;
			if (!classroom) {
				return;
			}

			const lessonsStore = useLessonsStore();

			if (reset) {
				lessonsStore.reset();
			}

			this.classroomLessons = await lessonsStore.loadBetween(
				DateTime.now().toJSDate(),
				DateTime.now().endOf('day').toJSDate(),
				{
					type: [ 'activities', 'reflection' ].join(','),
					hasLessonItems: true,
				}
			);

		},

		handleLessonCreated(classroom: Classroom, lesson: any) {
			// Message is not for current classroom; ignore.
			if (!this.currentClassroom || this.currentClassroom.id !== classroom.id) {
				return;
			}

			this.loadClassroomLessons(false);
		},

		handleLessonUpdated(classroom: Classroom, lesson: any) {
			// Message is not for current classroom; ignore.
			if (!this.currentClassroom || this.currentClassroom.id !== classroom.id) {
				return;
			}

			this.loadClassroomLessons(false);
		},

		enableReflectionBoard() {
			this.hasReflectionBoard = true;
		},

	}
});
