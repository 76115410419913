<template>
    <div class="ddm-item" :class="[ disabled ? 'disabled' : '' ]">
        <slot></slot>
    </div>
</template>

<style>
.ddm-item a {
    @apply flex gap-2 w-full px-4 py-3 hover:bg-base-100 hover:cursor-pointer;
}
.ddm-item.disabled a {
	@apply opacity-30 cursor-not-allowed hover:bg-inherit;
}
</style>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	}
}
</script>
