<template>

	<ul class="flex">
		<li>
			<input type="radio" id="radio-yellow" name="color" value="yellow" class="sr-only peer" v-model="value" />
			<label for="radio-yellow" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-yellow-200 ring-offset-2 ring-yellow-300 peer-checked:ring"></label>
		</li>
		<li>
			<input type="radio" id="radio-sky" name="color" value="sky" class="sr-only peer" v-model="value" />
			<label for="radio-sky" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-sky-200 ring-offset-2 ring-sky-300 peer-checked:ring"></label>
		</li>
		<li>
			<input type="radio" id="radio-lime" name="color" value="lime" class="sr-only peer" v-model="value" />
			<label for="radio-lime" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-lime-200 ring-offset-2 ring-lime-300 peer-checked:ring"></label>
		</li>
		<li>
			<input type="radio" id="radio-red" name="color" value="red" class="sr-only peer" v-model="value" />
			<label for="radio-red" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-red-200 ring-offset-2 ring-red-300 peer-checked:ring"></label>
		</li>
		<li>
			<input type="radio" id="radio-purple" name="color" value="purple" class="sr-only peer" v-model="value" />
			<label for="radio-purple" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-purple-200 ring-offset-2 ring-purple-300 peer-checked:ring"></label>
		</li>
		<li>
			<input type="radio" id="radio-gray" name="color" value="gray" class="sr-only peer" v-model="value" />
			<label for="radio-gray" class="inline-block w-6 h-6 mx-1.5 cursor-pointer rounded-full bg-gray-200 ring-offset-2 ring-gray-300 peer-checked:ring"></label>
		</li>
	</ul>

	<span v-if="validationError" class="text-red-600 text-xs">
			{{ $t(validationError.toString()) }}
	</span>

</template>

<script lang="ts">
import {FieldValidationError} from "@/stores/errors/FieldValidationError";

export default {
	props: {
		modelValue: {
			type: String,
			default: 'yellow',
		},

		validationError: {
			type: FieldValidationError
		}
	},
	emits: [
		'update:modelValue'
	],
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value: String) {
				this.$emit('update:modelValue', value);
			}
		}
	}
}
</script>
