import { defineStore } from "pinia";
import { Tag } from "@/models/Tag.model";
import { orgApi } from "@/utils/Api.util";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { useUsersStore } from "@/stores/Users.store";

interface TagStoreState {
	items: Tag[],
	loaded: boolean,
	errorMessage: ErrorMessage | null,
}

export const useTagsStore = defineStore('tags', {

	state: (): TagStoreState => ({
		items: [],
		loaded: false,
		errorMessage: null,
	}),

	getters: {

		getByType: (state) => {
			return (type: string, inUse: boolean | null = null): Tag[] => {
				const items = state.items.filter((tag: Tag) => tag.type === type);

				if (inUse !== null) {
					return items.filter((tag: Tag) => tag.inUse === inUse);
				}

				return items;
			}
		},

	},

	actions: {
		new() {
			let newTag = new Tag();
			return newTag;
		},

		async load(
			forceReload: boolean = false,
			awaitNewDataBeforeResettingList: boolean = false
		) {

			if (this.loaded && !forceReload) {
				return;
			}

			let response;
			try {
				response = await orgApi.get('tags', {
					params: {
						mask: ['*'].join(','),
						users: useUsersStore().me.id,
					}
				});

				this.loaded = true;
				this.items = response.data.data.map(
					v => Tag.mapFromServer(v)
				);

			} catch (e) {
				this.loadedClassroomId = null;
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		findByName(name: string, caseSensitive: boolean = false) {
			if(caseSensitive) {
				return this.items.find(tag => tag.name === name);
			} else {
				return this.items.find(tag => tag.name.toLowerCase() === name.toLowerCase());
			}
		},
	}

});
