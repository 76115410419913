<template>
	<TheStdLayout :title="'Doelen importeren'">

		<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<div class="bg-info py-4 px-6 my-6 text-sm rounded-lg">
			<p>Kopieer de eigen of de officiële doelen uit een spreadsheet (MS Excel, Google Sheets, Numbers, ...) en plak ze in
				onderstaande tabel.
			</p>
			<p>De gegevens moeten de volgende kolommen bevatten:</p>

			<table class="table mt-4" id="import-instructions">
				<tr>
					<td class="font-bold align-top">Doelreferentie curriculum</td>
					<td>De referentie naar het doel in het curriculum, bvb. <code>1.3</code> of <code>MZzo1</code>.
						<br>Het is belangrijk dat dit exact overeenstemt.
					</td>
				</tr>
				<tr>
					<td class="font-bold align-top">Subdoel</td>
					<td>
						Omschrijving van het te evalueren subdoel.<br>
						bvb. <code>De leerling kan gericht waarnemen met alle zintuigen</code>
					</td>
				</tr>
				<tr>
					<td class="font-bold">Leeftijdsvork, ondergrens</td>
					<td>Hier kan je optioneel de ondergrens van de leeftijd invullen waarop het doel van toepassing is.</td>
				</tr>
				<tr>
					<td class="font-bold">Leeftijdsvork, bovengrens</td>
					<td>Hier kan je optioneel de bovengrens van de leeftijd invullen waarop het doel van toepassing is.</td>
				</tr>
			</table>
		</div>

		<div class="overflow-x-auto">
			<table
				id="spreadsheet"
				ref="spreadsheet"
				@keydown.up="navigateSheet($event, 'up')"
				@keydown.down="navigateSheet($event, 'down')"
				@keydown.left="navigateSheet($event, 'left')"
				@keydown.right="navigateSheet($event, 'right')"
			>
				<thead>
					<tr>
						<td>Doelreferentie</td>
						<td>Subdoel-omschrijving</td>
						<td colspan="2">Leeftijdsvork</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, index) in spreadsheetData" :data-row="index">
						<td class="relative w-40">
							<span class="row-number">{{ index + 1 }}</span>
							<textarea
								@input="handleFirstCellInput($event, index)"
								@change="handleFirstCellChange($event, index)"
								:value.trim="row[0].value"
								rows="1"
								:data-row="index"
								data-col="0"
								:class="[row[0].error ? 'bg-red-100' : 'bg-white']"
							>
							</textarea>
						</td>
						<td>
							<div class="textarea-grow-wrap">
								<textarea
									:data-row="index"
									data-col="1"
									rows="1"
									@input="event => event.target.parentNode.dataset.replicatedValue = event.target.value"
									v-model.lazy.trim="spreadsheetData[index][1].value"
									:class="[row[1].error ? 'bg-red-100' : 'bg-white']"
									>
								</textarea>
							</div>
						</td>
						<td class="w-12">
							<input type="text" maxlength="1" :data-row="index" data-col="2"
								v-model.lazy.trim="spreadsheetData[index][2].value"
								class="text-center"
								:class="[row[2].error ? 'bg-red-100' : 'bg-white']"
							/>
						</td>
						<td class="w-12 relative">
							<button type="button" v-show="index < spreadsheetData.length - 1" tabindex="-1" class="clear-button" @click="deleteRow(index)">
								<TrashIcon class="w-4 h-4 text-base-content-light" />
							</button>
							<input type="text" maxlength="1" :data-row="index" data-col="3"
								v-model.lazy.trim="spreadsheetData[index][3].value"
								class="text-center"
								:class="[row[3].error ? 'bg-red-100' : 'bg-white']"
							/>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="mt-12 w-5/6 max-w-4xl flex justify-center">
				<button type="button" class="btn btn-primary" :disabled="!spreadSheetHasData" @click="trySave">{{ $t('Save') }}</button>
			</div>
		</div>


		</div>

	</TheStdLayout>
</template>


<style scoped>
#import-instructions code {
	@apply bg-white px-1 py-1 text-xs;
}
table#spreadsheet {
	@apply table w-5/6 max-w-4xl ml-10;
}
table#spreadsheet tbody tr {
	@apply border-b border-base-200;
}
table#spreadsheet tbody tr .row-number {
	@apply absolute flex justify-center items-center -left-10 top-0 bg-base-100 h-full w-10;
}
table#spreadsheet tbody tr .clear-button {
	@apply absolute flex justify-center items-center top-0 -right-10 w-10 h-full;
}
table#spreadsheet tbody td {
	@apply border-base-200 p-0 h-10;
}
table#spreadsheet tbody td:first-child {
	@apply relative border-l border-r;
}
table#spreadsheet tbody td:not(:first-child) {
	@apply border-r;
}
table#spreadsheet tbody td input {
	@apply w-full h-full block border border-white px-2 outline-none focus:border-blue-300;
}
table#spreadsheet tbody td textarea, .textarea-grow-wrap::after {
	@apply w-full min-h-full block whitespace-pre-wrap border border-white px-2 outline-none focus:border-blue-300 resize-none py-2;
}

/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.textarea-grow-wrap {
	@apply grid;
}
.textarea-grow-wrap::after, .textarea-grow-wrap > textarea {
	grid-area: 1 / 1 / 2 / 2;
	@apply h-full;
}
.textarea-grow-wrap::after {
	content: attr(data-replicated-value) " ";
  	visibility: hidden;
}
</style>


<script lang="ts">
import { nextTick } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { min } from 'date-fns';

export default {
	components: {
		TrashIcon,
	},

	data() {
		return {
			numCols: 4,
			spreadsheetData: [],
			lastChangeWasPastedTableData: false,
		};
	},

	computed: {
		spreadSheetHasData() {
			// TODO check if rows except the last one are empty
			// ...
			return this.spreadsheetData.length > 1;
		}
	},

	watch: {
		spreadsheetData: {
			// add a new row as soon as the last row has some content
			handler: function (val, oldVal) {
				const lastRow = this.spreadsheetData[this.spreadsheetData.length - 1];

				if(lastRow && !this.isEmptyRow(lastRow)) {
					this.spreadsheetData.push(this.newEmptyRow());
				}
			},
			deep: true
		}
	},

	methods: {

		navigateSheet(event, direction) {
			const row = Number(event.target.dataset.row);
			const col = Number(event.target.dataset.col);

			let caretPos = 0;

			switch(direction) {
				case 'up':
					if(row > 0) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'down':
					if(row < this.spreadsheetData.length - 1) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'left':
					caretPos = event.target.selectionStart;
					if(caretPos > 0) {
						return;
					}

					if(col > 0) {
						const prevCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col - 1}"]`);
						prevCell.focus();
						setTimeout(() => {
							let endOfContent = prevCell.value.length;
							prevCell.setSelectionRange(endOfContent, endOfContent);
						}, 50);
					} else if(row > 0) {
						const lastCellPrevRow = this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${this.numCols - 1}"]`);
						lastCellPrevRow.focus();
					}
					break;

				case 'right':
					caretPos = event.target.selectionStart;
					if(caretPos < event.target.value.length) {
						return;
					}

					if(col < this.numCols - 1) {
						const nextCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col + 1}"]`);
						nextCell.focus();
						setTimeout(() => {
							nextCell.setSelectionRange(0, 0);
						}, 50);
					} else if(row < this.spreadsheetData.length - 1) {
						const firstCellNextRow = this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="0"]`);
						firstCellNextRow.focus();
					}
					break;

				default: break;
			}
		},

		newEmptyRow() {
			let row = [];
			for(let i = 0; i < this.numCols; i++) {
				row[i] = {};
				row[i].value = "";
				row[i].error = null;
				row[i].required = (i === 0 || i === 1)? true : false;
			}
			return row;
		},

		isEmptyRow(row) {
			let isEmpty = true;
			for(let i = 0; i < this.numCols; i++) {
				if(row[i].value) {
					isEmpty = false;
					break;
				}
			}
			return isEmpty;
		},

		handleFirstCellChange(event, index) {
			// triggered after 'input' event, so if that input event was pasting table data, don't overwrite the first cell
			// with the raw pasted data
			if(this.lastChangeWasPastedTableData) {
				this.lastChangeWasPastedTableData = false;
			} else {
				this.spreadsheetData[index][0].value = event.target.value;
			}
		},

		handleFirstCellInput(event, index) {

			const input = event.target.value;
			const columns = input.split("\t");
			if(columns.length > 1) {
				this.handleTabbedInput(event, index);
			} else {
				// strip newlines
				if(input.includes("\n")) {
					event.target.value = input.replace("\n", "");
				}
			}
		},

		handleTabbedInput(event, index) {

			const tabbedInput = event.target.value;
			const rows = tabbedInput.split("\n");
			let firstRowArray = rows[0].split("\t");
			// first row determines the number of pasted columns, however we should not exceed the number of columns in the spreadsheet
			firstRowArray = firstRowArray.slice(0, this.numCols);

			let newData = []
			rows.forEach((row, idx) => {
				let rowObject = this.newEmptyRow();
				let values = row.split("\t");
				firstRowArray.forEach((header, idx) => {
					rowObject[idx].value = values[idx];
				});
				newData.push(rowObject);
			});

			this.spreadsheetData.splice(index, 1, ...newData);
			this.lastChangeWasPastedTableData = true;

			// set focus to last cell of the pasted table
			nextTick(() => {
				const cell = this.$refs.spreadsheet.querySelector(`[data-row="${this.spreadsheetData.length - 2}"][data-col="${firstRowArray.length - 1}"]`);
				cell.focus();
			});
		},

		deleteRow(rowIndex) {
			this.spreadsheetData.splice(rowIndex, 1);
		},

		cleanUpSpreadsheetData() {
			// remove empty rows
			this.spreadsheetData = this.spreadsheetData.filter(row => {
				return !this.isEmptyRow(row);
			});
		},

		validateRow(row) {

			for(let i = 0; i<row.length; i++) {
				// check required fields
				if(row[i].required && !row[i].value) {
					row[i].error = this.$t('required');
				}
			}

			// check age range
			const minAge = row[2].value;
			const maxAge = row[3].value;
			let regex = /^[0-9]+$/;

			if(minAge) {
				if(!minAge.match(regex)) {
					row[2].error = this.$t('invalid');
				}
				if(!maxAge) {
					row[3].error = this.$t('missing');
				} else if(parseInt("0" + minAge, 10) > parseInt("0" + maxAge, 10)) {
					row[2].error = this.$t('invalid');
					row[3].error = this.$t('invalid');
				}
			}
			if(maxAge) {
				if(!maxAge.match(regex)) {
					row[3].error = this.$t('invalid');
				}
				if(!minAge) {
					row[2].error = this.$t('missing');
				}
			}

			return row.every(cell => {
				return !cell.error;
			});
		},

		validateSpreadsheetData() {
			let valid = true;

			this.spreadsheetData.forEach((row, index) => {
				if(!this.validateRow(row)) {
					valid = false;
				}
			});
			// check if min age is lower than max age
			// ...

			if(!valid) {
				throw new Error('');
			}
		},

		trySave() {
			// remove empty rows
			this.cleanUpSpreadsheetData();

			// validate data
			try {
				this.validateSpreadsheetData();
			} catch(e) {
				this.spreadsheetData.push(this.newEmptyRow());
				// console.error(e);
				return;
			}
			// if error from backend, add empty row again at the end
			// ...
		}

	},

	created() {
		if(this.spreadsheetData.length === 0) {
			this.spreadsheetData.push(this.newEmptyRow());
		}
	},

	mounted() {
		// set focus to first cell of the spreadsheet
		const firstCell = this.$refs.spreadsheet.querySelector('tbody tr:first-child td:first-child textarea');
		setTimeout(() => {
			firstCell.focus();
		}, 300);
	},

};
</script>