import {DateTime, Interval} from "luxon";

export class CopySelection {

	public interval: Interval;
	public themeNames: string[];

	public serialize(): any {
		return {
			interval: {
				start: this.interval.start.toISO(),
				end: this.interval.end.toISO()
			},
			themeNames: this.themeNames
		};
	}

	public static deserialize(data: any): CopySelection {
		const copySelection = new CopySelection();

		copySelection.interval = Interval.fromDateTimes(
			DateTime.fromISO(data.interval.start),
			DateTime.fromISO(data.interval.end)
		);

		copySelection.themeNames = data.themeNames;
		return copySelection;
	}
}
