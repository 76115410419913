import { defineStore } from 'pinia';

import {orgApi} from "@/utils/Api.util";

import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { Observation } from "@/models/Observation.model";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { Pupil } from "@/models/Pupil.model";
import { PaginationState } from "@/models/PaginationState.model";
import {DateTime, Interval} from "luxon";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";
import { useUsersStore } from "@/stores/Users.store";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import { FilterDef, FilterParamValue } from "@/utils/FilterDefs.util";

interface ObservationStoreState {
	observations: Observation[],
	errorMessage: ErrorMessage | null,
	emptyObservation: Observation | null,
	currentPage: PaginationState | null,
	filter: ObservationFilterDef | null
}

export class ObservationFilterDef extends FilterDef {
	constructor() {
		super([
			'pupilIds',
			'activityIds',
			'start',
			'end',
			'query'
		]);
	}

	setInterval(interval: Interval | null) {

		if (interval) {
			this.setParamValues('start', [ new FilterParamValue(interval.start.toUTC().toISO(), interval.start.toUTC().toISO()) ]);
			this.setParamValues('end', [ new FilterParamValue(interval.end.toUTC().toISO(), interval.end.toUTC().toISO()) ]);
		} else {
			this.clearParam('start');
			this.clearParam('end');
		}
	}

	get pupilId() {
		return this.getParamValues('pupilIds')[0]?.id || null;
	}

	set pupilId(value: string) {
		this.setParamValues('pupilIds', [ new FilterParamValue(value, value) ]);
	}

	get activityId() {
		return this.getParamValues('activityIds')[0]?.id || null;
	}

	set activityId(value: string) {
		this.setParamValues('activityIds', [ new FilterParamValue(value, value) ]);
	}

	get keywords() {
		return this.getParamValues('query')[0]?.id || null;
	}

	set keywords(value: string) {
		this.setParamValues('query', [ new FilterParamValue(value, value) ]);
	}
}

/**
 *
 */
function getDefaultFilter() {
	const filters = new ObservationFilterDef();
	filters.markDefault();

	let interval = useOrganisationsStore().currentOrganisation.getCurrentSchoolYearInterval();
	if (interval) {
		filters.setInterval(interval);
	}

	return filters;
}

export const useObservationsStore = defineStore('observations', {

	state: (): ObservationStoreState => {

		// Check localstorage for default filter
		let filterData = SmaSyLocalStorage.getUserItem(useUsersStore().me.id, 'observationFilters');

		let filters = null;
		if (filterData) {
			filters = new ObservationFilterDef();
			filters.fromJson(filterData);
		} else {
			filters = getDefaultFilter();
		}

		return {
			observations: [],
			errorMessage: null,
			emptyObservation: null,
			currentPage: null,
			filter: filters
		}
	},

	getters: {



	},

	actions: {


		async load() {

			const params = {
				page: this.currentPage ? this.currentPage.currentPage : null,
				mask : this.getMask().join(',')
			};

			params['classroomIds'] = useClassroomsStore().currentClassroom.id;

			if (this.filter) {
				Object.assign(params, this.filter.toApiParams());
			}

			const response = await orgApi.get('observations', {
				params: params
			});

			this.observations = response.data.data.map((observation: any) => {
				return Observation.mapFromServer(observation);
			});

			this.currentPage = PaginationState.mapFromServer(response.data.meta);

		},

		new(pupil: Pupil) {
			let newObservation = new Observation(pupil);
			newObservation.classroomId = useClassroomsStore().currentClassroom.id;

			newObservation.note = "";

			return newObservation;
		},

		getMask() {
			return [
				'*',
				'files.*',
				'files.variations.*',
				'classroom.id',
				'pupil.*',
				'pupil.icon.*',
				'pupil.classrooms.id',
				'pupilActivity.*',
				'pupilActivity.lessonItem.*',
				'pupilActivity.lessonItem.icon.*',
				'pupilActivity.lessonItem.lesson.*',
				'pupilActivity.lessonItem.activity.*',
				'pupilActivity.lessonItem.lessonPlan.*',
			];
		},

		async create() {

			this.errorMessage = null;

			const observation = this.emptyObservation;
			if (observation === null) {
				return;
			}

			try {
				const response = await orgApi.post('observations', observation.getServerData());
				observation.id = response.data.data.id;

				this.emptyObservation = null;

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async save(observation: Observation) {

			this.errorMessage = null;

			if(observation.id) {
				// update existing observation
				try {
					const response = await orgApi.put("observations/" + observation.id, observation.getServerData(), {
						params: {
							mask : [
								'*',
								'files.*',
							].join(',')
						}
					});
					observation.setFromServerData(response.data.data);
					this.updateStoreItem(observation);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				// create new observation
				try {
					const response = await orgApi.post("observations", observation.getServerData(), {
						params: {
							mask : this.getMask().join(',')
						}
					});
					observation.setFromServerData(response.data.data);
					this.addStoreItem(observation);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}
		},

		async delete(observation: Observation) {

			try {
				const response = await orgApi.delete("observations/" + observation.id);
				this.deleteStoreItem(observation);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		updateStoreItem(observation: Observation) {
			var storeIndex = this.observations.findIndex(
				storeItem => storeItem.id == observation.id
			);
			this.observations[storeIndex] = observation.clone();
		},

		addStoreItem(observation: Observation) {
			this.observations.unshift(observation);
		},

		deleteStoreItem(observation: Observation) {
			const index = this.observations.findIndex(v => v.id === observation.id);
			if (index >= 0) {
				this.observations.splice(index, 1);
			}
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		async refreshObservation(observation: Observation) {
			const response = await orgApi.get('observations/' + observation.id, {
				params: {
					mask: this.getMask().join(',')
				}
			});

			observation.setFromServerData(response.data.data);
		},

		applyFilter(filter: ObservationFilterDef) {
			this.filter = filter;
			this.load();

			SmaSyLocalStorage.setUserItem(
				useUsersStore().me.id,
				'observationFilters',
				filter,
				60 * 60 * 8
			);
		},

		loadFirstPage() {
			this.currentPage = null;
			this.load();
		},

		goToPage(page: PaginationState) {
			this.currentPage = page;
			this.load();
		},

		resetFilter() {
			this.applyFilter(getDefaultFilter());
			SmaSyLocalStorage.removeUserItem(useUsersStore().me.id, 'observationFilters');
		}
	}

});
