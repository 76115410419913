import config from "@/config/app.config";

class MaintenanceModeClass {

	private isMaintenanceMode(): boolean {

		if (!config.MAINTENANCE_MODE) {
			return false;
		}

		// Do we have an override hash?
		if (window.location.search === '?overrideMaintenanceMode') {
			localStorage.setItem('overrideMaintenanceMode', '1');
			window.location.href = '/';
			return false;
		}

		// Do we have a maintenance mode local storage key?
		if (localStorage.getItem('overrideMaintenanceMode')) {
			return false;
		}

		return true;

	}

	public checkMaintenanceMode(i18n) {

		if (!this.isMaintenanceMode()) {
			return true;
		}

		const maintenanceMode = document.createElement('div');
		maintenanceMode.innerHTML = '<h1 class="pt-5 text-center text-xl">Smart Symbols</h1>' +
			'<p class="text-center pt-2">' + i18n.global.t('Oops, one of the children accidentally knocked over some of our symbols.') + '<br />' +
			i18n.global.t('We\'re currently tidying up and should be back soon.') + '</p>';
		document.body.appendChild(maintenanceMode);

		return false;

	}

}

export const MaintenanceMode = new MaintenanceModeClass();
