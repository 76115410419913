<template>
	<div class="print:hidden">
		<div class="@container bg-base grid grid-cols-12 gap-3 my-3">

			<div class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-3 flex items-center">
				<MagnifyingGlassIcon class="w-6 h-6 mr-2" />
				<input type="text"
					:value="filter.getParamValues('firstName')[0]"
					class="input input-sm w-full"
					:placeholder="$t('First name')"
					@input="updateFilter('firstName', $event.target.value)"
				/>
			</div>

			<div class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-3">
				<input type="text"
					:value="filter.getParamValues('lastName')[0]"
					class="input input-sm w-full"
					:placeholder="$t('Name')"
					@input="updateFilter('lastName', $event.target.value)"
				/>
			</div>

			<div class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-3">
				<DateSelector
					:model-value="dateOfBirthFilterValue"
					input-css-classes="input-sm"
					:placeholder="$t('Date of birth')"
					@update:model-value="updateFilter('dateOfBirth', $event)"
				/>
			</div>

			<button v-show="!filter.isEmpty()" @click="resetFilter" type="button" class="btn btn-ghost btn-sm">{{ $t('Reset') }}</button>

		</div>
	</div>
</template>


<script lang="ts">
import {mapStores} from "pinia";
import {PupilFilterDef, usePupilsStore} from "@/stores/Pupils.store";
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import DateSelector from "@/components/ui/DateSelector.vue";
import { DateTime } from "luxon";


export default {

	emits: ['change'],

	props: {
		initialFilter: {
			type: PupilFilterDef,
		},
	},

	components: {
		XMarkIcon, MagnifyingGlassIcon,
		DateSelector,
	},

	data() {
		return {
			filter: new PupilFilterDef(),
			searchTimeout: null,
			selectedDateOfBirth: null,
		}
	},

	computed: {
		...mapStores(usePupilsStore),

		dateOfBirthFilterValue: {
			get(): Date {
				if(this.filter.getParamValues('dateOfBirth').length > 0) {
					return new Date(this.filter.getParamValues('dateOfBirth')[0]);
				}
				return null;
			}
		}
	},

	created() {
		// Clone the passed filter so we don't modify it directly, but emit the changed filter instead
		if(this.initialFilter) {
			this.filter = Object.assign(
				Object.create(
					Object.getPrototypeOf(this.initialFilter)
				),
				this.initialFilter
			);
		}
	},

	methods: {

		updateFilter(param, value) {
			if(param === 'dateOfBirth') {
				if(value instanceof Date) {
					this.filter.setParamValues(param, [ DateTime.fromJSDate(value).toSQLDate() ]);
				} else {
					this.filter.clearParam(param);
				}
			} else {
				this.filter.setParamValues(param, [ value ]);
			}

			this.$emit('change', this.filter);
		},

		resetFilter() {
			this.filter.clear();
			this.$emit('change', this.filter);
		},

	}

}
</script>
