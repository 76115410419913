import {defineStore} from 'pinia'
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {PupilActivity} from "@/models/PupilActivity.model";
import {orgApi} from "@/utils/Api.util";
import { format } from 'date-fns'
import {Pupil} from "@/models/Pupil.model";
import {useClassroomsStore} from "@/stores/Classrooms.store";

interface ObservationStoreState {
	errorMessage: ErrorMessage | null,
	loadedDates: {
		[ date: string ] : PupilActivity[]
	}
}

export const usePupilActivitiesStore = defineStore('pupilActivities', {

	state: (): ObservationStoreState => ({
		errorMessage: null,
		loadedDates: {}
	}),

	getters: {
		getActivityForPupilOnDate: (state) => {
			return (date: Date, pupil: Pupil) => {

				if (!state.loadedDates[format(date, 'yyyy-MM-dd')]) {
					return [];
				}

				return state.loadedDates[format(date, 'yyyy-MM-dd')]
					.filter(activity => activity.pupil.id === pupil.id);

			};
		}
	},

	actions: {

		async loadActivityOnDate(date: Date) {
			const response = await orgApi.get("pupil-activities", {
				params: {
					classroomIds: useClassroomsStore().currentClassroom.id,
					mask: 'id,checkin,checkout,pupil_feedback,lessonItem.*,lessonItem.icon.*,pupil.*',
					date: format(date, 'yyyy-MM-dd')
				}
			});

			// don't use line below, the mapping function being used here has a 2nd argument which the map() function fills with the array index
			// this.loadedDates[format(date, 'yyyy-MM-dd')] = response.data.data.map(PupilActivity.mapFromServer);
			this.loadedDates[format(date, 'yyyy-MM-dd')] = response.data.data.map( obj => (new PupilActivity()).setFromServerData(obj) );
		}

	}
});
