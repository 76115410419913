<template>
	<TheStdLayout :title="$t('Preferences')">

		<form @submit.prevent="save" class="m-auto mt-8 w-full sm:max-w-lg grid grid-cols-1 gap-6">

			<div>
				<sms-select  :label="$t('Language')" :options="languages" v-model="me.locale"></sms-select>
			</div>

			<div class="border-t pt-4 flex justify-end">
				<button type="submit" class="btn btn-primary" :disabled="saving">
					<span v-show="saving" class="loading loading-spinner"></span>
					{{ $t('Save') }}
				</button>
			</div>

		</form>

	</TheStdLayout>
</template>

<script lang="ts">
import { useUsersStore } from "@/stores/Users.store";
import { mapState, mapStores } from "pinia";
import { SUPPORTED_LOCALES } from "@/i18n";

export default {

	data() {
		return {
			saving: false
		}
	},

	computed: {
		...mapStores(useUsersStore),
		...mapState(useUsersStore, ['me']),

		languages() {
			const out = [];
			for (let locale in SUPPORTED_LOCALES) {
				out.push({
					label: SUPPORTED_LOCALES[locale],
					value: locale
				})
			}
			return out;
		}
	},

	methods: {
		async save() {
			if (this.saving) {
				return;
			}

			this.saving = true;
			await this.usersStore.savePreferences(this.me);
			this.saving = false;
		}
	}

}
</script>
