<template>
	<div class="flex items-center bg-gray-50 float-left border border-gray-200 p-1 rounded-md">

		<template v-if="svgIcons.length === 0 && icons.length === 0">
			<div class="fade flex flex-nowrap overflow-hidden w-10 sm:w-24 md:w-52">
				<span class="italic text-base-content-light" v-if="editable">{{$t('Add the first icons')}}</span>
				<span class="italic text-base-content-light text-ellipsis whitespace-nowrap" v-if="!editable">{{$t('No icons yet')}}</span>
			</div>
		</template>
		<template v-else-if="svgIcons.length > 0 && icons.length === 0">
			<div class="fade flex flex-nowrap overflow-hidden w-10 sm:w-24 md:w-52">
				<div class="m-1 flex items-center justify-center" v-for="icon in svgIcons">
					<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<use :href="icon"></use>
					</svg>
				</div>
			</div>
		</template>
		<template v-else-if="icons.length > 0">
			<div class="fade flex flex-nowrap overflow-hidden w-10 sm:w-24 md:w-52">
				<img v-for="icon in icons" :key="icon.id" :src="icon.url" class="ml-1 w-8 aspect-square rounded" />
			</div>
		</template>

		<div class="flex gap-2 border-l border-gray-300 px-4 ml-2">

			<router-link v-if="iconLibrary && to" :to="to">
				<button class="btn btn-ghost btn-circle btn-sm bg-base-200">
					<MagnifyingGlassIcon class="w-5 h-5" />
				</button>
			</router-link>

			<button v-if="editable" class="btn btn-primary btn-circle btn-sm" @click="handleAddIconRequest">
				<PlusIcon class="w-5 h-5"></PlusIcon>
			</button>

		</div>

	</div>
</template>

<style scoped>
.fade {
	mask-image: linear-gradient(to right, #000000 50%, transparent 95%);
}
</style>

<script lang="ts">
import {mapStores} from "pinia";
import {useIconsStore} from "@/stores/Icons.store";
import {useIconLibrariesStore} from "@/stores/IconLibraries.store";

import {
	PlusIcon, MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';

export default {

	props: {
		editable: false,
		to: null,
		iconLibrary: null,
		svgIcons: {
			default: []
		}
	},

	emits: [ 'clickAdd' ],

	components: {
		PlusIcon, MagnifyingGlassIcon,
	},

	computed: {
		...mapStores(useIconsStore, useIconLibrariesStore),

		icons() {
			if (!this.iconLibrary) {
				return [];
			}
			return this.iconsStore.allIcons.filter(v => v.library.id === this.iconLibrary.id).slice(0, 8);
		},
	},

	methods: {
		handleAddIconRequest(e) {
			e.preventDefault();
			this.$emit('clickAdd');
		}
	},
}
</script>
