<template>
	<Dropdown v-if="user">
		<template #trigger>

			<button class="btn btn-ghost normal-case">
				{{ user?.name }}
				<ChevronDownIcon class="-mr-1 ml-2 h-3 w-3"/>
			</button>
		</template>
		<template #content>

			<DropdownMenu class="w-72 overflow-hidden">

				<DropdownMenuItem>
					<a href="/preferences">{{ $t('Preferences') }}</a>
				</DropdownMenuItem>

				<DropdownMenuItem v-if="user?.isSuperAdmin()">
					<a @click.prevent="impersonateAnyone" class="">{{ $t('Impersonate') }}</a>
				</DropdownMenuItem>

				<DropdownMenuItem>
					<a @click.prevent="logout">{{ $t('Log out') }}</a>
				</DropdownMenuItem>
			</DropdownMenu>

		</template>
	</Dropdown>
</template>


<script lang="ts">
import Dropdown from '../ui/Dropdown.vue';
import DropdownMenu from '../ui/DropdownMenu.vue';
import DropdownMenuItem from '../ui/DropdownMenuItem.vue';
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { User } from "@/models/User.model";
import { auth } from "@/auth";
import {useUsersStore} from "@/stores/Users.store";
import {mapStores} from "pinia";

export default {
	name: "TheUserMenu",

	components: {
		Dropdown,
		DropdownMenu,
		DropdownMenuItem,
		CheckIcon,
		ChevronDownIcon,
	},

	props: {
		user: {
			type: User,
			required: true
		}
	},

	computed: {
		...mapStores(useUsersStore),
	},

	methods: {
		logout() {
			auth.logout();
		},

		async impersonateAnyone() {

			const email = prompt('Enter the email of the user you want to impersonate');
			if (!email) {
				return;
			}

			const user = await this.usersStore.loadFromEmail(email);
			if (!user) {
				alert('User not found');
				return;
			}

			await this.usersStore.impersonate(user.id);

		}
	}
}
</script>
