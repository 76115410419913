<template>
    <button type="button" @click="toggleSwitch" class="group relative inline-flex h-5 w-10 mx-1 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-accent-focus focus:ring-offset-2" role="switch" aria-checked="false">
        <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-base"></span>
        <span aria-hidden="true" :class="[switchedOn && type == 'toggle'? 'bg-accent-focus' : 'bg-base-200']" class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"></span>
        <span aria-hidden="true"
            :class="[switchedOn? 'translate-x-5' : 'translate-x-0', type == 'toggle'? 'border-base-200' : 'border-accent-focus']"
            class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border bg-base shadow ring-0 transition-transform duration-200 ease-in-out"
        ></span>
    </button>
</template>

<script lang="ts">
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'toggle'
        }
    },
    emits: [
        'update:modelValue'
    ],
    computed: {
        switchedOn() {
            return this.modelValue;
        }
    },
    methods: {
        toggleSwitch() {
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
}
</script>