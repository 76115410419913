<template>

	<Modal @requestUnmount="hide" :fullscreen="false">

		<template #header>
			<div class="font-semibold text-xl mb-4" v-text="inUpdateMode? $t('Edit user') : $t('Add user')"></div>
		</template>

		<template #default="modalActions">

			<form @submit.prevent="save">

				<p v-if="usersStore.errorMessage?.genericError" class="text-red-600 text-xs">
						{{ usersStore.errorMessage?.genericError.toString() }}
				</p>

				<div class="grid grid-cols-1 gap-6 mb-6">

					<!-- <div class="" v-if="!inUpdateMode">
						<sms-input type="name" :placeholder="$t('Name')" class="invalid:border-danger"
											 autofocus v-model="editableModel.name" :label="$t('Name')"
											 :validationError="usersStore.errorMessage?.fields.name"></sms-input>
					</div> -->

					<div class="">
						<template v-if="inUpdateMode">
							<sms-input disabled :modelValue="editableModel.email" :label="$t('Email address')"></sms-input>
						</template>
						<template v-else>
							<sms-input type="email" :placeholder="$t('Email address') + '@example.com'" class="invalid:border-danger"
												 autofocus v-model="editableModel.email" :label="$t('Email address')"
												 :validationError="usersStore.errorMessage?.fields.email"></sms-input>
						</template>
					</div>

					<div class="">
						<sms-select
								:label="$t('Role')"
								:options="getAvailableRoles()"
								v-model="editableModel.role"
								:validationError="usersStore.errorMessage?.fields.role"
								:disabled="canEditRole() === false"
						></sms-select>
					</div>

					<div v-if="inUpdateMode && !editableModel.isMe()" class="pl-1 flex items-center">
						<input type="checkbox" id="status" class="checkbox checkbox-accent" v-model="editableModel.active"/>
						<label for="status" class="label-text pl-2 cursor-pointer">{{ $t('Active') }}</label>
					</div>
					<div v-if="!inUpdateMode" class="pl-1 flex items-center">
						<input type="checkbox" id="send-invite" checked class="checkbox" v-model="sendInvitation" />
						<label for="send-invite" class="label-text pl-2 cursor-pointer">{{ $t('Send an invitation email') }}</label>
					</div>

				</div>


				<div class="modal-footer flex" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
					<div></div>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="modalActions.hide"
										:class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>


<script lang="ts">
import {mapStores} from 'pinia';

import {useUsersStore} from "@/stores/Users.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";

import {User} from "@/models/User.model";

import Modal from "@/components/ui/Modal.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import {TrashIcon} from '@heroicons/vue/24/outline';

export default {
	props: {
		user: {
			type: User,
			required: false,
		},
	},
	components: {
		Modal,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon,
	},
	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
			sendInvitation: true,
		}
	},
	computed: {
		...mapStores(useUsersStore),

		inUpdateMode() {
			return (this.user && this.user.id) ? true : false;
		}
	},

	methods: {

		hide() {
			this.usersStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		async confirmRemove(dialogActions) {

			dialogActions.close();

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.usersStore.delete(this.originalModel);
				this.deleting = false;

				this.hide();

			} catch (e: any) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		async save() {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.usersStore.save(this.editableModel, this.sendInvitation);

				this.saving = false;
				this.hide();

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		getAvailableRoles() {

			const roles = [];

			// We need to know our own role in order to determine which roles we can assign to other users
			const myRole = this.usersStore.me.role;
			switch (myRole) {
				case 'admin':
					roles.push('admin', 'teacher');
					break;

				case 'owner':

					if (this.editableModel.role === 'owner') {
						// owner cannot be demoted, unless there is another owner
						// Is this the last owner?
						if (this.usersStore.owners.length < 2) {
							roles.push('owner');
							break;
						}
					}

					roles.push('owner', 'admin', 'teacher');
					break;
			}

			if (this.editableModel.role === 'owner' && !roles.includes('owner')) {
				roles.unshift('owner');
			}

			return roles.map(role => ({
				label: this.$t(role[0].toUpperCase() + role.slice(1)),
				value: role,
			}));
		},

		canEditRole() {
			const myRole = this.usersStore.me.role;
			switch (myRole) {
				case 'admin':
					return this.editableModel.role !== 'owner';

				case 'owner':
					return true;
			}

			return false;
		}
	},

	beforeMount() {
		if (this.user && this.user.id) {
			this.originalModel = this.user;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.user.clone();
		} else {
			this.editableModel = this.usersStore.new();
		}
	},
}
</script>
