<template>
	<span class="goal-title">
		<span
			v-if="goal.customTitle"
			:title="goal.title"
			v-text="goal.title"
			class="whitespace-nowrap overflow-clip text-ellipsis line-through font-normal inline-block opacity-50 w-16"
		>
		</span>
		<span :class="titleClasses" v-html="formattedTitle"></span>
	</span>
</template>

<style>
/* to support ul/li tags in the title (e.g. Zill goals). UPDATE: No longer required as ul/li tags are now stripped from the content */
.goal-title ul {
	list-style-type: circle;
	list-style-position: inside;
}
</style>

<script lang="ts">
import { COMPOSED_TITLE_PATH_SEPARATOR } from '@/goals/zill/settings';

export default {
	props: {
		goal: {
			type: Object,
			required: true
		},
		titleClasses: {
			type: String,
		}
	},

	computed: {
		formattedTitle() {
			let title = this.goal.customTitle || this.goal.title;
			if(this.goal.isSubgoal()) {
				title = title.replaceAll(COMPOSED_TITLE_PATH_SEPARATOR, '<br><span class="text-base-content-light text-sm">&#8618;</span> ');
			}

			return title;
		}
	}
}
</script>