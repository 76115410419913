import {Icon} from "@/models/Icon.model";

export class Symbol {

  public url = '';

  static mapFromServer(data: any) {
    return (new Symbol()).setFromServerData(data);
  }

  public setFromServerData(data: any): Symbol {
    this.url = data; // server returns strings, not objects.

    return this;
  }

  getServerData() {
    return this.url;
  }

  equals(icon: Icon | Symbol) {
    if (icon instanceof Icon) {
      return false;
    }

    return this.url === icon.url;
  }
}
