import {defineStore} from "pinia";
import {Lesson} from "@/models/Lesson.model";
import {orgApi} from "@/utils/Api.util";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {DateTime, Interval} from "luxon";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";
import {useUsersStore} from "@/stores/Users.store";
import { AgendaStore, AgendaStoreState } from "@/stores/AbstractAgenda.store";
import { useThemesStore } from "@/stores/Themes.store";

interface LessonsStoreState extends AgendaStoreState<Lesson> {

}

interface LessonFilters {
	type ?: string,
	hasLessonItems ?: boolean,
}

const BaseStore = AgendaStore<Lesson, LessonsStoreState>();

export const useLessonsStore = defineStore('lessons', {

	state: (): LessonsStoreState => ({

		errorMessage: null,
		events: [],
		loadingRanges: 0,
		loadedRanges: [],
		lastLoadedRange: null

	}),

	getters: {

		...BaseStore.getters,

		apiPath(state: LessonsStoreState) {
			return 'lessons';
		},

		defaultMask(state: LessonsStoreState) {
			return [
				'id',
				'type',
				'title',
				'description',
				'start',
				'end',
				'color',
				'classroom',
				'seats',
				'lessonItems.*',
				'lessonItems.activity.*',
				'lessonItems.icon.*',
				'lessonItems.icon.library.id',
				'lessonItems.attachments.*',
				'lessonItems.lessonPlan.*',
				'lessonItems.lessonPlan.tags.*',
				'lessonItems.lessonPlan.supplies.*',
				'lessonItems.lessonPlan.attachments.*',
				'lessonItems.lessonPlan.owner.id',
				'lessonItems.lessonPlan.owner.name',
				'lessonItems.lessonPlan.owner.firstName',
				'lessonItems.lessonPlan.owner.email',
				'lessonItems.lessonPlan.goals.*',
			];
		},

	},

	actions: {

		...BaseStore.actions,

		mapEventFromServer(data: any): Lesson {
			return Lesson.mapFromServer(data);
		},

		new(properties: any = null) {

			const lesson = new Lesson();
			lesson.boardType = '';
			lesson.classroomId = useClassroomsStore().currentClassroom.id.toString();

			if (properties !== null) {
				this._setLessonProperties(lesson, properties);
			}
			return lesson;
		},

		clone(lessonId: string) {

			const lesson = this.getFromId(lessonId);

			let clone = lesson.clone();
			clone.classroomId = useClassroomsStore().currentClassroom.id.toString();
			clone.originalId = lesson.id;
			clone.id = undefined;

			return clone;
		},

		async store(lesson: Lesson) {

			this.clearErrorMessage();

			try {
				const serverData: any = lesson.asApiObject();
				serverData.classroom = {id: lesson.classroomId};

				const response = await orgApi.post(this.apiPath, serverData, {
					params: {
						mask: this.defaultMask.join(',')
					}
				});
				lesson.setFromServerData(response.data.data);
				this.events.push(lesson);

				this.rememberLastLessonInfo(lesson);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async update(lesson: Lesson, force = false) {

			this.clearErrorMessage();
			try {

				const body: any = lesson.asApiObject();
				if (force) {
					body.force = force;
				}

				const response = await orgApi.put(this.apiPath + "/" + lesson.id, body, {
					params: {
						mask: this.defaultMask.join(',')
					}
				});
				lesson.setFromServerData(response.data.data);

				this.rememberLastLessonInfo(lesson);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async delete(lesson: Lesson) {

			this.clearErrorMessage();

			try {
				// Remove from the API
				await orgApi.delete("lessons/" + lesson.id);
				this.removeFromId(lesson.id);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async updateLesson(lessonId, properties, force = false) {
			let lesson = this.getFromId(lessonId);
			this._setLessonProperties(lesson, properties);

			await this.update(lesson, force);
		},

		async clearDay(isoDateString: string) {

			const start = new Date(isoDateString);
			start.setHours(0, 0, 0, 0);

			const end = new Date(start)
			end.setDate(end.getDate() + 1);

			await this.clearDateRange(start, end);
		},

		/**
		 * @param source
		 * @param destinations
		 * @param confirmOverride
		 */
		async copyTo(source: Interval, destinations: Interval[], confirmOverride: boolean = false) {
			const classroomId = useClassroomsStore().currentClassroom.id;

			const targets = await Promise.all(
				destinations.map(
					async (destination: Interval): Promise<any> => {

						return {
							start: destination.start.toJSDate().toISOString(),
							end: destination.end.toJSDate().toISOString(),
							classroom: {
								id: classroomId
							}
						};
					}
				)
			);

			try {
				const post: any = {
					classroomIds: classroomId,
					start: source.start.toJSDate().toISOString(),
					end: source.end.toJSDate().toISOString(),
					destinations: targets
				}

				if (confirmOverride) {
					post.force = true;
				}

				// Remove from the API
				const response = await orgApi.post(
				'lessons/duplicate',
					post,
					{
						params: {
							mask: this.defaultMask.join(',')
						}
					}
				);

				this.mergeEvents(response.data);

				let minStart = DateTime.max(...destinations.map(d => d.start));
				let maxEnd = DateTime.min(...destinations.map(d => d.end));

				// Reload the themes as these might have changed
				useThemesStore().loadBetween(minStart.toJSDate(), maxEnd.toJSDate(), {}, true);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}
		},

		transformToApiFilter(filters: LessonFilters): any {
			const out: any = {};

			if (typeof(filters.type) !== 'undefined') {
				out.type = filters.type;
			}

			if (typeof(filters.hasLessonItems) !== 'undefined') {
				out.hasLessonItems = filters.hasLessonItems ? 1 : 0;
			}

			return out;
		},

		/** Private actions **/

		/**
		 * @param lesson
		 * @param properties
		 */
		_setLessonProperties(lesson: Lesson, properties: any) {

			if(properties.title) {
				lesson.title = properties.title;
			}
			if(properties.color) {
				lesson.color = properties.color;
			}
			if(properties.start) {
				lesson.start = properties.start;
			}
			if(properties.end) {
				lesson.end = properties.end;
			}

		},

		async rememberLastLessonInfo(lesson: Lesson) {

			const user = useUsersStore();
			const me = await user.loadMe();

			if(lesson.boardType === Lesson.BOARD_TYPE_ACTIVITIES || lesson.boardType === Lesson.BOARD_TYPE_REFLECTION) {
				let lastLessonInfo = SmaSyLocalStorage.getUserItem(me.id, 'last_lesson_info');
				if(!lastLessonInfo) {
					lastLessonInfo = {};
				}
				lastLessonInfo[lesson.boardType] = {
					title: lesson.title,
					color: lesson.color,
				}
				SmaSyLocalStorage.setUserItem(me.id, 'last_lesson_info', lastLessonInfo);
			}

		},

		async tryRetrieveLastLessonInfoFor(boardType: string) {

			if(!boardType) {
				return null;
			}

			const user = useUsersStore();
			const me = await user.loadMe();

			let lastLessonInfo = SmaSyLocalStorage.getUserItem(me.id, 'last_lesson_info');
			if(lastLessonInfo && lastLessonInfo[boardType]) {
				return lastLessonInfo[boardType];
			} else {
				return null;
			}
		}
	}

});
