import {useClassroomsStore} from "@/stores/Classrooms.store";
import {useOrganisationsStore} from "@/stores/Organisations.store";

export enum Feature {
	TAG_WEEK_SCHEDULES = 'TAG_WEEK_SCHEDULES',
	CUSTOM_ICONS = 'CUSTOM_ICONS',
}

export class Features {
	static hasFeature(feature: Feature): boolean {

		if (useOrganisationsStore().currentOrganisation) {
			// @ts-ignore
			return useOrganisationsStore().currentOrganisation.hasFeature(feature);
		} else {
			return false;
		}
	}
}
