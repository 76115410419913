<template>

    <Modal :fullscreen="showActivities" :beforeClose="canClose" @requestUnmount="cancelEdit">

		<template #header>
			<div class="font-semibold text-xl mb-4">
                {{ $t('Time block') }}
			</div>
		</template>

		<template #default="modalActions">

			<div class="pb-16 h-full" id="lessonFormContainer">
				<div id="fc-lesson-form" class="flex flex-col lg:flex-row lg:space-x-6 lg:divide-x divide-base-200 h-full">

					<div :class="[ showActivities? 'lg:w-72 xl:w-96 shrink-0' : 'w-full' ]">

						<div class="flex flex-col gap-y-6">

							<div v-if="lessonsStore.errorMessage?.genericError" class="alert alert-error mb-4 w-full lg:max-w-2xl m-auto">
								<div>
									<span>{{ $t(lessonsStore.errorMessage?.genericError.toString()) }}</span>
								</div>
							</div>

							<!-- TIME -->
							<div class="flex justify-center lg:justify-start">
								<div class="flex gap-x-3 py-2 flex-wrap">
									<div class="flex gap-2 items-center xl:basis-auto lg:mb-2 xl:mb-0" :class="showActivities ? 'lg:basis-full' : ''">
										<ClockIcon class="w-5 h-5 text-base-content opacity-50"/>
										<div v-text="localDate(lesson.start)"></div>
									</div>
									<div class="flex justify-center">
										<TimePicker v-model="startTime" :interval="5" :validationError="lessonsStore.errorMessage?.fields.start"></TimePicker>
									</div>
									<div class="flex items-center justify-center"> -</div>
									<div class="flex justify-center">
										<TimePicker v-model="endTime" :interval="5" :validationError="lessonsStore.errorMessage?.fields.end"></TimePicker>
									</div>
								</div>
							</div>

							<!-- COLOR -->
							<div class="flex justify-center lg:justify-start print:hidden">
								<div class="grid grid-cols-8 xl:grid-cols-8 gap-y-4 gap-x-6 py-6 px-4 justify-center w-full max-w-md" :class="showActivities? 'lg:grid-cols-5' : ''">
									<div v-for="color in availableColors" class="w-full relative" style="padding-top: 100%;">
										<label
											class="block absolute inset-0 m-auto rounded-full border border-black/10 text-sm hover:opacity-80 hover:cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden transition-all origin-center"
											:class="[color === lesson.hexColor ? 'opacity-100 scale-150' : 'opacity-40 hover:scale-100']"
											:style="{ backgroundColor: color }"
										>
											<input type="radio" name="color" class="absolute inset-0 opacity-0" :value="color" v-model="lesson.color" />
											<CheckIcon v-show="color === lesson.color" class="absolute inset-0 w-3/5 m-auto opacity-60" />
										</label>
									</div>
								</div>
							</div>

							<!-- BOARD TYPE -->
							<div class="print:hidden">
								<ActionConfirm position="bottom-start" class="w-full md:w-[30rem] text-danger font-bold" v-slot="confirmationDialog">
									<div class="flex gap-x-4 items-start">
										<div class="w-5/12 lg:w-4/12 text-right mt-1">{{ $t('Without choice board') }}</div>
										<div class="w-2/12 lg:w-4/12 text-center">
											<input type="checkbox" class="toggle toggle-lg" id="lf-cb-toggle" :checked="isBoardTypeActivities" @click="handleIntentToChangeBoard($event, confirmationDialog)" />
										</div>
										<div class="w-5/12 lg:w-4/12 mt-1 pr-8 relative">
											{{ $t('With choice board') }}
											<QuestionMarkCircleIcon class="absolute top-0 right-0 w-6 h-6 text-base-content opacity-50 hover:cursor-pointer" @click="showChoiceBoardExplanation = !showChoiceBoardExplanation"/>
										</div>
									</div>
								</ActionConfirm>
								<div class="flex gap-4 items-start bg-base-100 rounded text-base-content-light text-sm transition-all origin-center" :class="[showChoiceBoardExplanation? 'p-4' : 'h-0 p-0 overflow-hidden']">
									<img src="/images/icon-choiceboard.svg" class="w-16" />
									{{ $t('#instruction: when to select choice board') }}
								</div>
							</div>

							<!-- <div class="my-4">
								<ActionConfirm position="bottom-start" class="w-full md:w-[30rem] text-danger font-bold" v-slot="confirmationDialog">
									<div class="mt-3 grid grid-cols-10 justify-items-center items-end">
										<div class="col-span-6 grid grid-cols-2 justify-items-center gap-y-2">
											<label class="col-span-2 flex justify-between text-sm">
												{{ $t('Using a Smart Symbols') }}...
											</label>


											<button type="button" class="flex flex-col items-start gap-x-2 group cursor-pointer" @click="changeBoardType('activities', confirmationDialog)">
												<div class="flex flex-col items-center gap-1 text-sm font-bold">
													{{ $t('Activity board') }}
													<div class="w-20 h-20 bg-base-100 relative rounded-lg ring-inset group-hover:ring-2 p-2" :class="[ isBoardTypeActivities ? 'ring-2' : 'ring-0' ]">
														<CheckCircleIcon v-show="isBoardTypeActivities" class="w-9 h-9 absolute left-1/2 -top-2 -ml-5 text-blue-500" />
														<img src="/images/icon-choiceboard.svg" class="w-full h-full" />
													</div>
												</div>
											</button>
											<button type="button" class="flex flex-col items-start gap-x-2 group cursor-pointer" @click="changeBoardType('reflection', confirmationDialog)">
												<div class="flex flex-col items-center gap-1 text-sm font-bold">
													{{ $t('Reflection board') }}
													<div class="w-20 h-20 bg-base-100 relative rounded-lg ring-inset group-hover:ring-2 p-2" :class="[ isBoardTypeReflection ? 'ring-2' : 'ring-0' ]">
														<CheckCircleIcon v-show="isBoardTypeReflection" class="w-9 h-9 absolute left-1/2 -top-2 -ml-5 text-blue-500" />
														<img src="/images/icon-reflectionboard.svg" class="w-full h-full" />
													</div>
												</div>
											</button>
										</div>
										<div class="divider divider-horizontal">{{ $t('or') }}</div>
										<button type="button" class="col-span-3 flex flex-col items-start gap-x-2 group cursor-pointer" @click="changeBoardType('', confirmationDialog)">
											<div class="flex flex-col items-center gap-1 text-sm font-bold">
												{{ $t('without board') }}
												<div class="w-20 h-20 bg-base-100 relative rounded-lg ring-inset group-hover:ring-2 p-2" :class="[ isBoardTypeNone ? 'ring-2' : 'ring-0' ]">
													<CheckCircleIcon v-show="isBoardTypeNone" class="w-9 h-9 absolute left-1/2 -top-2 -ml-5 text-blue-500" />
													<img src="/images/icon-noboard.svg" class="w-full h-full" />
												</div>
											</div>
										</button>
									</div>
								</ActionConfirm>
							</div> -->

							<hr>

							<div>

								<div>
									<label for="title" class="block text-sm font-medium text-base-content">{{ $t('Short description') }} <span class="lowercase">({{ $t('Optional') }})</span></label>
									<div class="mt-1">
										<input v-model="lesson.title" type="text" name="title" id="title"
											@change="$emit('change', lesson)"
											:placeholder="$t('f.i. Lunch break, Mathematics, Cleaning up,  ...')"
											class="input input-bordered w-full placeholder:italic placeholder:text-base-content-light" />
										<span v-if="lessonsStore.errorMessage?.fields.title" class="text-red-600 text-xs">
											{{ $t(lessonsStore.errorMessage?.fields.title.toString()) }}
										</span>
									</div>
								</div>

								<div class="text-center" v-if="!showActivities">
									<div class="text-xs mt-2 uppercase">{{ $t('and') }} / {{ $t('or') }}</div>
									<button class="btn btn-neutral my-4" @click="setShowActivities(true)">
										<PlusIcon class="w-5 h-5 mr-1"/>
										{{ $t('Add more content') }} ...
									</button>
								</div>
							</div>

						</div>

					</div>

					<div v-if="showActivities" class="py-6 lg:px-6 w-full flex-grow overflow-auto">
						<LessonActivities :lesson="lesson"></LessonActivities>
					</div>

				</div>

				<div class="modal-footer absolute bottom-4 inset-x-4 bg-white/50 backdrop-blur-sm z-10 flex justify-between print:hidden">

					<ActionConfirm position="top-start" class="w-80" v-slot="confirmationDialog">
						<button v-if="showDeleteButton" class="btn" :class="[deleting? 'btn-disabled':'btn-outline btn-danger']" @click="deleteLesson(confirmationDialog)">
							<span v-show="deleting" class="loading loading-spinner"></span>
							<TrashIcon class="w-5 h-5"/>
						</button>
					</ActionConfirm>

					<div class="flex justify-end gap-1">
						<button class="btn btn-ghost" @click="modalActions.hide" :class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button class="btn" @click="saveLesson" :class="[deleting ? 'btn-disabled' : 'btn-primary']" type="submit">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{$t('Save')}}
						</button>
					</div>

				</div>
			</div>
		</template>

	</Modal>

</template>


<script lang="ts">
import { DateTime } from "luxon";
import Modal from './ui/Modal.vue';
import TimePicker from './ui/TimePicker.vue';
import { ClockIcon, CheckIcon, NoSymbolIcon, ArrowUpIcon, TrashIcon, PlusIcon, InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import LessonActivities from './LessonActivities.vue';
import BasicColorPicker from "./ui/BasicColorPicker.vue";
import ActionConfirm from './ui/ActionConfirm.vue';

import {mapStores} from "pinia";
import {useLessonsStore} from "@/stores/Lessons.store";
import {Lesson} from "@/models/Lesson.model";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { UnsavedChanges } from "@/utils/UnsavedChanges.util";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";

export default {
	name: 'LessonFormModal',

	emits: [
		'requestClose',
		'cancel',
		'update:showActivities',
		'change'
	],

	inheritAttrs: false,    // to prevent propagation of lessons

	props: {
		lesson: {
			type: Lesson,
			required: true
		},
		showActivities: {
			type: Boolean,
			default: false
		}
	},

	components: {
		Modal, TimePicker, LessonActivities, BasicColorPicker,
		ActionConfirm,
		ClockIcon, CheckIcon, NoSymbolIcon, ArrowUpIcon, TrashIcon, PlusIcon, InformationCircleIcon,
		CheckCircleIcon,
		QuestionMarkCircleIcon
	},

	data() {
		return {
			saving: false,
			deleting: false,
			originalLesson: null,
			showChoiceBoardExplanation: false,
		}
	},

	mounted() {
		this.lessonsStore.clearErrorMessage();
		this.saving = false;
		this.originalLesson = this.lesson.clone();

		UnsavedChanges.observeModel(this.originalLesson, this.lesson);

		if (this.lesson.lessonItems.length > 0) {
			this.setShowActivities(true);
		}
	},

	unmounted() {
		UnsavedChanges.unobserveModel();
	},

	computed: {

		...mapStores(useLessonsStore),

		availableColors() {
			return Lesson.AVAILABLE_COLORS;
		},

		startTime: {
			get() {
				if (!this.lesson.start) {
					return null;
				}

				return this.lesson.start.toTimeString().substring(0, 5);
			},
			set(value) {
				this.lesson.start.setHours(...value.split(':').map(v => parseInt(v)));
				this.$emit('change', this.lesson);
			}
		},

		endTime: {
			get() {
				if (!this.lesson.end) {
					return null;
				}

				return this.lesson.end.toTimeString().substring(0, 5);
			},
			set(value) {
				this.lesson.end.setHours(...value.split(':').map(v => parseInt(v)));
				this.$emit('change', this.lesson);
			}
		},

		// date() {
		// 	return this.lesson.start.toLocaleString(undefined, { dateStyle: 'short' });
		// },
		localDate() {
			return (date: Date) => {
				return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
			}
		},

		showDeleteButton() {
			return this.lesson.hasId();
		},

		isBoardTypeActivities() {
			return this.lesson.boardType === Lesson.BOARD_TYPE_ACTIVITIES;
		},

		isBoardTypeReflection() {
			return this.lesson.boardType === Lesson.BOARD_TYPE_REFLECTION;
		},

		isBoardTypeNone() {
			return !this.isBoardTypeActivities && !this.isBoardTypeReflection;
		},
	},

	methods: {

		setShowActivities(state: Boolean) {
			// Also change the lesson type to 'individual' if this is the first lesson
			// if (state && this.lesson.lessonItems.length === 0) {
			// 	this.lesson.boardType = Lesson.BOARD_TYPE_ACTIVITIES;
			// }

			this.$emit('update:showActivities', state);
		},

		closeModal() {
			if (!UnsavedChanges.nonexistentOrIgnorable()) {
				return;
			}

			this.setShowActivities(false);
			this.$emit('requestClose');
		},

		canClose() {
			return UnsavedChanges.nonexistentOrIgnorable();
		},

		cancelEdit() {
			// Revert all changes
			this.revertChanges();
			UnsavedChanges.unobserveModel();

			this.setShowActivities(false);
			this.$emit('cancel');
		},

		async saveLesson(force = false) {

			if (this.saving) {
				return;
			}

			this.saving = true;

			try {
				if (this.lesson.id) {
					await this.lessonsStore.update(this.lesson, force);
				} else {
					await this.lessonsStore.store(this.lesson, force);
				}

				UnsavedChanges.unobserveModel();
				this.closeModal();

				this.$notify({
					text: this.$t('The lesson has been saved'),
					type: "success",
				});

				this.saving = false;
			} catch (e) {

				this.saving = false;

				// handled by store's error handler
				if (e.response?.status === 409) {

					const { reveal } = createConfirmDialog(ConfirmModal);

					reveal({
						title: this.$t('Activity board will reset'),
						message: this.$t('#notification: activity board will reset')
					}).then((answer) => {
						if (answer.isCanceled) {
							this.saving = false;
							return;
						}

						this.saveLesson(true);
					});
					return;

				}

			}
		},

		async deleteLesson(confirmationDialog) {

			if (this.deleting) {
				return;
			}

			const confirmed = await confirmationDialog.show();
			if(confirmed) {
				this.deleting = true;
				try {
					await this.lessonsStore.delete(this.lesson);
					this.deleting = false;

					UnsavedChanges.unobserveModel();
					this.closeModal();

					this.$notify({
						text: this.$t('The lesson has been removed'),
						type: "success",
					});

				} catch (e: any) {
					this.deleting = false;
					if (!(e instanceof ApiErrors)) {
						throw e;
					}
				}
			}
		},

		revertChanges() {
			this.lesson.setFromModel(this.originalLesson);
			this.$emit('change', this.lesson);
		},

		async handleIntentToChangeBoard(event, confirmationDialog = null) {

			if(this.lesson.lessonItems.length === 0) {

				this.lesson.boardType = event.target.checked ? Lesson.BOARD_TYPE_ACTIVITIES : '';

			} else {

				event.target.disabled = true;

				// intent to DEselect choice board
				if(event.target.checked === false) {
					confirmationDialog.setMessage( this.$t('The activities that have already been selected for the activity board will be removed. Are you sure?') );

				// intent to select choice board
				} else {
					confirmationDialog.setMessage( this.$t('When choosing the activity board, the current lesson content will be cleared. Are you sure?') );
				}

				const confirmed = await confirmationDialog.show();
				if(confirmed) {
					this.lesson.boardType = event.target.checked ? Lesson.BOARD_TYPE_ACTIVITIES : '';
					this.lesson.lessonItems = [];
				} else {
					event.target.checked = !event.target.checked;
				}
				event.target.disabled = false;
			}
			return;
		},


		// CURRENTLY UNUSED, BUT KEPT FOR FUTURE USE IN CASE NEW BOARDS ARE INTRODUCED
		async changeBoardType(newBoardType: string, confirmationDialog = null) {

			if(this.lesson.boardType === newBoardType) {
				return;
			}

			const initialBoardType = this.lesson.boardType;

			// when lesson items have already been added, ask for confirmation in case of switching to/from activity board
			if(this.lesson.lessonItems.length && (newBoardType === Lesson.BOARD_TYPE_ACTIVITIES || this.lesson.boardType === Lesson.BOARD_TYPE_ACTIVITIES)) {
				if(newBoardType === Lesson.BOARD_TYPE_ACTIVITIES) {
					// switch to activity board
					confirmationDialog.setMessage( this.$t('When choosing the activity board, the current lesson content will be cleared. Are you sure?') );
				} else {
					// switch from activity board
					confirmationDialog.setMessage( this.$t('The activities that have already been selected for the activity board will be removed. Are you sure?') );
				}
				const confirmed = await confirmationDialog.show();
				if(confirmed) {
					this.lesson.boardType = newBoardType;
					this.lesson.lessonItems = [];
				}
			} else {
				this.lesson.boardType = newBoardType;
			}

			// if board type was changed, try to set the title from the last lesson with the same board type
			if(this.lesson.boardType !== initialBoardType) {
				const infoOfLastLessonWithPrevBoard = await this.lessonsStore.tryRetrieveLastLessonInfoFor(initialBoardType);
				let lessonTitleWasSetFromCache = false;
				if(infoOfLastLessonWithPrevBoard && this.lesson.title === infoOfLastLessonWithPrevBoard.title) {
					lessonTitleWasSetFromCache = true;
				}
				if(!this.lesson.title || lessonTitleWasSetFromCache) {
					if(newBoardType.length === 0) {
						this.lesson.title = '';
						this.lesson.color = Lesson.DEFAULT_COLOR;
					} else {
						const infoOfLastLessonWithNewBoard = await this.lessonsStore.tryRetrieveLastLessonInfoFor(newBoardType);
						if(infoOfLastLessonWithNewBoard) {
							this.lesson.title = infoOfLastLessonWithNewBoard.title ?? '';
							this.lesson.color = infoOfLastLessonWithNewBoard.color ?? this.lesson.color;
						} else {
							this.lesson.title = '';
							this.lesson.color = Lesson.DEFAULT_COLOR;
						}
					}
				}
			}
		},
	},
}
</script>
