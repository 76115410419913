<template>
	<button v-if="available" type="button" @click="openFilePicker()" class="" :disabled="!loaded">
		<img src="/images/icons/google-drive.svg" class="w-8 h-8" alt="Google Drive icon" />
		<span class="hidden sm:inline-block">{{ label }}</span>

		<span v-if="!loaded">
			<span class="loading loading-spinner"></span>
		</span>
	</button>
</template>

<script lang="ts">
import { FileExternal } from "@/models/FileExternal.model";
import {GoogleDriveAccessToken} from "@/utils/GoogleDriveAccessToken.util";
import config from "@/config/app.config";
import { jwtDecode } from "jwt-decode";
import {mapStores} from "pinia";
import {useUsersStore} from "@/stores/Users.store";
import Modal from "@/components/ui/Modal.vue";

let googleDriveLibrariesLoadedPromise = null;
export default {

	computed: {
		...mapStores(useUsersStore),

		available() {
			if (!config.GOOGLE?.client_id) {
				return false;
			}

			return true;
		},
	},

	components: {
		Modal
	},

	props: {
		label: {
			type: String,
			required: false,
		}
	},

	data() {
		return {
			developerKey: config.GOOGLE.developer_key,
			clientId: config.GOOGLE.client_id,
			scope: "https://www.googleapis.com/auth/drive.file", // https://developers.google.com/drive/api/guides/api-specific-auth
			oauthToken: null,
			error: null,
			loaded: false,
		};
	},

	emits: [
		'filesSelected'
	],

	async created() {
		await this.loadGoogleDriveApi();
	},

	mounted() {
		googleDriveLibrariesLoadedPromise.then(() => {
			this.loaded = true;
		});
	},

	methods: {

		async loadGoogleDriveApi() {

			if (!googleDriveLibrariesLoadedPromise) {

				googleDriveLibrariesLoadedPromise = new Promise<void>(
					(resolve, reject) => {

						this.loadScripts([
							"https://apis.google.com/js/api.js",
							"https://accounts.google.com/gsi/client"
						]).then(
							() => {
								// Load required modules
								gapi.load('picker,client', () => {
									resolve();
								});
							}
						);
					}
				);
			}

			return googleDriveLibrariesLoadedPromise;
		},

		loadScripts(scripts) {
			const promises = [];

			scripts.forEach((script: string) => {

				promises.push(new Promise<void>((resolve, reject) => {

					let gDrive = document.createElement("script");
					gDrive.setAttribute("type", "text/javascript");
					gDrive.setAttribute("src", script);

					gDrive.onload = () => {
						resolve();
					}

					document.head.appendChild(gDrive);

				}));

			});

			return Promise.all(promises);
		},

		openFilePicker() {
			if (!googleDriveLibrariesLoadedPromise) {
				console.error('Google libraries not loaded (yet?)');
				// Can't make a promise as otherwise popup blocking kicks in.
			}

			if (!GoogleDriveAccessToken.getAccessToken()) {
				this.requestAuthentication();
			} else {
				this.createPicker();
			}
		},

		requestAuthentication() {

			const tokenClient = google.accounts.oauth2.initTokenClient({
				client_id: this.clientId,
				scope: this.scope,
				callback: async (response) => {

					if (response.error !== undefined) {
						throw (response);
					}

					try {

						await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest');
						gapi.client.setToken(response);

						gapi.client.oauth2.userinfo.get().execute((userDetails) => {

							if (userDetails.id !== (this.usersStore.me.google_id)) {
								alert(this.$t('Access failure. You are trying to access Google Drive for an account that is different from the one you are using for Smart Symbols.'));
								this.error = this.$t('You are trying to access Google Drive with a different account. Please log out and log in with the correct account.');
								return;
							}

							GoogleDriveAccessToken.store(response);
							this.createPicker();

						})
					} catch (e) {
						console.error(e);
					}

				}
			});

			tokenClient.requestAccessToken({prompt: ''});

		},

		createPicker() {
			const sharedDrivesView = new google.picker.DocsView(google.picker.ViewId.DOCS)
				.setEnableDrives(true)
				.setIncludeFolders(true);
			const sharedWithMeView = new google.picker.DocsView(google.picker.ViewId.DOCS)
				.setOwnedByMe(false)
				.setMode(google.picker.DocsViewMode.LIST);
			const myFilesView = new google.picker.DocsView(google.picker.ViewId.DOCS)
				.setOwnedByMe(true)
				.setMode(google.picker.DocsViewMode.LIST)
				.setIncludeFolders(true);

			if (GoogleDriveAccessToken.getAccessToken()) {
				var picker = new google.picker.PickerBuilder()
					.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
					.addView(sharedDrivesView)
					.addView(sharedWithMeView)
					.addView(myFilesView)
					.setOAuthToken(GoogleDriveAccessToken.getAccessToken())
					.setDeveloperKey(this.developerKey)
					.setCallback(this.pickerCallback)
					.build();
				picker.setVisible(true);
			}
		},

		async pickerCallback(data: any) {
			if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
				// Array of Picked Files
				let docs = data.docs;

				this.$emit('filesSelected', docs.map(
					(doc) => {

						let externalFile = new FileExternal();
						externalFile.service = "gdrive";
						externalFile.name = doc.name;
						externalFile.url = doc.url;
						externalFile.mimeType = doc.mimeType;

						return externalFile;
					}
				));
			}
		},
	},

}
</script>
