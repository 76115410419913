<template>
	<transition appear name="fade">
	<div id="backdrop" class="fixed inset-0 bg-black z-20 bg-opacity-50">

		<div class="absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center text-gray-100">
			<WifiIcon class="w-1/4 h-1/4" />
			<div class="my-4 text-2xl">{{ $t('Interrupted network connection')}}</div>
			<button type="button" class="btn btn-circle btn-outline" @click="pageRefresh">
				<ArrowPathIcon class="w-4/5 h-4/5" />
			</button>
		</div>

	</div>
	</transition>
</template>

<script lang="ts">
import { WifiIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';

export default {
	components: {
		WifiIcon,
		ArrowPathIcon,
	},
	methods: {
		pageRefresh() {
			location.reload();
		}
	}
}
</script>