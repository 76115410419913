<template>
	<div class="relative border rounded-md mb-1">
		<div :style="{ width: Math.round(upload.progress * 100) + '%' }" class="absolute h-1 bg-accent"></div>

		<div class="px-2 py-1 text-sm">
			{{ $t('Uploading') }} {{upload.file.name}} - {{ Math.round(upload.progress * 100) }}%
		</div>
		<div v-if="upload.error" class="px-2 text-red-500 flex justify-between items-center text-sm">
			{{ $t('Error') }}: {{upload.errorMessage}}
			<button type="button" @click="$emit('cancel')">
				<XMarkIcon class="w-5 h-5" />
			</button>
		</div>

	</div>
</template>

<script lang="ts">

import { UploadingFile } from "@/models/UploadingFile.model";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default {

	emits: [
		'cancel'
	],

	components: {
		XMarkIcon,
	},

	props: {
		upload: {
			type: UploadingFile,
			required: true,
		}
	}

}
</script>
