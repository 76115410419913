import { defineStore } from 'pinia';
import {orgApi} from "@/utils/Api.util";

import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { LessonPlan } from '@/models/LessonPlan.model';
import { FilterDef } from "@/utils/FilterDefs.util";
import { Lesson } from "@/models/Lesson.model";
import { LessonItem } from "@/models/LessonItem.model";
import { Tag as TagModel } from "@/models/Tag.model";

import { useUsersStore } from "@/stores/Users.store";
import { useTagsStore } from "@/stores/Tags.store";
import { useThemesStore } from "@/stores/Themes.store";
import { PaginationState } from "@/models/PaginationState.model";
import { OrderParameter } from "@/models/OrderParameter.model";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";

export class LessonPlanFilterDef extends FilterDef {
	constructor() {
		super(['activityLabels', 'themeLabels', 'goals', 'keywords']);
	}

	toApiParams(): any {
		let params: any = {};

		/*
		const tags = [];
		tags.push(... this.filterMap.get('activityLabels').values.map((v) => v.id));
		tags.push(... this.filterMap.get('themeLabels').values.map((v) => v.id));

		if (tags.length > 0) {
			params.tags = tags.join(',');
		}*/

		params.activityTags = this.filterMap.get('activityLabels').values.map((v) => v.id).join(',');
		params.themeTags = this.filterMap.get('themeLabels').values.map((v) => v.id).join(',');

		params.keywords = this.filterMap.get('keywords').values.map((v) => v.label).join(',');

		return params;
	}
}

interface LessonPlansStoreState {
	latestNewPlan: LessonPlan | null,
	items: LessonPlan[],
	errorMessage: ErrorMessage | null,
	currentPage: PaginationState | null,
	order: OrderParameter | null,
	loadingMore: boolean,
	filter: LessonPlanFilterDef | null,
}

export const useLessonPlansStore = defineStore('lessonPlans', {

	state: (): LessonPlansStoreState => {

		const filter = new LessonPlanFilterDef();

		// Look for localstorage
		const locallyStoredFilter = SmaSyLocalStorage.getUserItem(useUsersStore().me.id, 'lessonplan-filter');
		if (locallyStoredFilter) {
			filter.fromJson(JSON.parse(locallyStoredFilter));
		} else {
			filter.clear();
		}

		return {
			latestNewPlan: null,
			items: [],
			errorMessage: null,
			currentPage: null,
			order: useDefaultOrderStore().lessonPlansOrder,
			filter: filter,
			loadingMore: false,
		}
	},

	getters: {},

	actions: {

		async new(
			lessonItem: LessonItem = null,
			lesson: Lesson = null
		) {

			const plan = new LessonPlan();
			this.latestNewPlan = plan;

			// Set activity label to the name of the activity
			if (lessonItem?.activity) {

				const activityTag = new TagModel();
				activityTag.name = lessonItem.activity.name;

				plan.activityTags = [ activityTag ];

			}

			// Set theme label if available.
			// Don't wait for it, but rather set if when the theme is loaded
			if (lesson?.theme?.id) {
				let theme = await useThemesStore().findById(lesson.theme.id);

				if (!theme || !theme.name) {
					return plan;
				}

				const themeTag = new TagModel();
				themeTag.name = theme.name;

				plan.themeTags = [ themeTag ];
			}

			return plan;

		},

		findById(id: number) {
			return this.items.find(p => p.id === id);
		},

		async executeGetRequest(
			page: PaginationState | null = null,
		): Promise<LessonPlan[]> {
			let params: any = {
				mask: this.getMask().join(','),
				page: page ? page.currentPage : null,
				order: this.order ? this.order.getApiProperty() : null,
				recordsPerPage: 25,
			};

			Object.assign(params, this.filter.toApiParams());

			const user = useUsersStore().me;
			const response = await orgApi.get('users/' + user.id + '/lesson-plans', {
				params: params
			});

			this.currentPage = PaginationState.mapFromServer(response.data.meta);
			return response.data.data.map(LessonPlan.mapFromServer);
		},

		async load(
			page: PaginationState | null = null,
			forceReload: boolean = false,
			awaitNewDataBeforeResettingList: boolean = false
		) {

			if (!awaitNewDataBeforeResettingList) {
				this.items = [];
			}

			try {
				this.items = await this.executeGetRequest(page);
			} catch (e: any) {
				throw ApiErrors.fromAxiosException(e);
			}
		},

		// Given the current filters and current page, try to load more items
		async loadMore() {

			if (this.loadingMore) {
				return;
			}

			if (!this.currentPage) {
				return;
			}

			if (!this.currentPage.hasNext()) {
				return;
			}

			this.loadingMore = true;

			let page = this.currentPage;

			try {
				const items = await this.executeGetRequest(page.getNext());

				// Adapt page to show 'from' as 1 (as the other items are also still loaded)
				this.currentPage.from = page.from;

				this.items.push(...items);
			} catch (e: any) {
				throw ApiErrors.fromAxiosException(e);
			}

			this.loadingMore = false;
		},

		async save(item: LessonPlan) {

			if (!item.isSavedInLib) {
				// must make a new one
				item.originalId = item.id;
				item.id = null;
			}

			this.errorMessage = null;

			const user = useUsersStore().me;

			if(item.id) {

				try {
					const response = await orgApi.put('users/' + user.id + '/lesson-plans/' + item.id,
						item.getServerData(),
						{
							params: {
								mask: this.getMask().join(',')
							}
						}
					);

					item.setFromServerData(response.data.data);
					this.updateStoreItem(item);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {

				try {
					const response = await orgApi.post('users/' + user.id + '/lesson-plans',
						item.getServerData(),
						{
							params: {
								mask: this.getMask().join(',')
							}
						}
					);

					item.setFromServerData(response.data.data);
					this.addStoreItem(item);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

			// Reload the tags as new ones may have been created
			useTagsStore().load(true);
		},

		async delete(item: LessonPlan) {

			const user = useUsersStore().me;
			try {
				const response = await orgApi.delete('users/' + user.id + '/lesson-plans/' + item.id);
				this.deleteStoreItem(item);

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		updateStoreItem(item: LessonPlan) {
			var storeIndex = this.items.findIndex(
				storeItem => storeItem.id == item.id
			);
			this.items[storeIndex] = item.clone();
		},

		addStoreItem(item: LessonPlan) {
			this.items.push(item);
		},

		deleteStoreItem(item: LessonPlan) {
			const index = this.items.findIndex(v => v.id === item.id);
			if (index >= 0) {
				this.items.splice(index, 1);
			}
		},

		async changeOrder(order: OrderParameter) {
			this.order = order;
			await this.load(null, this.filter, order);

			useDefaultOrderStore().setDefaultLessonPlansOrder(order);
		},

		applyFilter(filter: LessonPlanFilterDef) {

			const me = useUsersStore().me;
			if(filter.isEmpty()) {
				SmaSyLocalStorage.removeUserItem(me.id, 'lessonplan-filter');
			} else {
				SmaSyLocalStorage.setUserItem(me.id, 'lessonplan-filter', filter.toJson(), 60 * 60 * 8);
			}

			this.load(null, filter);
		},

		resetFilter() {
			this.filter.clear();
			this.applyFilter(this.filter);
		},

		getMask() {
			return [
				'*',
				'tags.*',
				'attachments.*',
				'supplies.*',
				'owner.id',
				'goals.*',
				'goals.tags.*'
			];
		}

	},
});
