import { DateTime } from "luxon";

export abstract class AbstractAgendaItemModel {
	public id: string;
	public start: Date;
	public end: Date;

	public isToday() {
		return DateTime.fromJSDate(this.start).hasSame(DateTime.now(), 'day');
	}

	public asFullCalendarEvent(): any {
		return {
			id: this.id,
			start: this.start,
			end: this.end,
		};
	}
}
