<template>

	<div class="mx-auto">

		<div class="overflow-x-auto">

			<div class="alert alert-error text-sm" v-if="errorMessage">
				{{ errorMessage }}
			</div>

			<table
				id="spreadsheet"
				ref="spreadsheet"
				@keydown.up="navigateSheet($event, 'up')"
				@keydown.down="navigateSheet($event, 'down')"
				@keydown.left="navigateSheet($event, 'left')"
				@keydown.right="navigateSheet($event, 'right')"
				class="w-full"
			>
				<thead>

					<tr>
						<td v-for="column in columns" :key="column.key" class="uppercase font-semibold">
							{{ column.title }}
						</td>
					</tr>

				</thead>
				<tbody>

				<tr v-for="(row, rowIndex) in spreadsheetData" :data-row="rowIndex" :class="[ isEmptyRow(this.spreadsheetData[rowIndex]) ? 'opacity-30' : '' ]">

					<td v-for="(colum, columnIndex) in columns" class="relative" >

						<span class="row-number" v-if="columnIndex === 0" :class="[ !validateRow(rowIndex) ? 'text-red-600 font-bold' : '' ]">{{ rowIndex + 1 }}</span>


						<template v-if="!columns[columnIndex].type || columns[columnIndex].type === 'textarea'">

							<div class="textarea-grow-wrap" :data-replicated-value="spreadsheetData[rowIndex][columnIndex].value">
								<textarea
									rows="1"
									:data-row="rowIndex"
									:data-col="columnIndex"
									:type="columns[columnIndex].type || 'text'"
									v-model="spreadsheetData[rowIndex][columnIndex].value"
									:class="[validateColumn(rowIndex, columnIndex, spreadsheetData[rowIndex][columnIndex].value) ? 'bg-white' : 'bg-red-100' ]"
									@paste="handlePaste($event, rowIndex)"
									@change="(event) => { changeValue(rowIndex, columnIndex, event) }"
									@focus="$forceUpdate()"
								></textarea>
							</div>

						</template>

						<template v-else>
							<div class="input-grow-wrap">
								<input
									rows="1"
									:data-row="rowIndex"
									:data-col="columnIndex"
									:type="columns[columnIndex].type || 'text'"
									v-model="spreadsheetData[rowIndex][columnIndex].value"
									:class="[validateColumn(rowIndex, columnIndex, spreadsheetData[rowIndex][columnIndex].value) ? 'bg-white' : 'bg-red-100' ]"
									@paste="handlePaste($event, rowIndex)"
									@change="(event) => { changeValue(rowIndex, columnIndex, event) }"
									@focus="$forceUpdate()"
								></input>
							</div>
						</template>

					</td>

				</tr>
				</tbody>
			</table>


		</div>


	</div>

</template>

<style scoped>
#import-instructions code {
	@apply bg-white px-1 py-1 text-xs;
}
table#spreadsheet {
	@apply table w-5/6 max-w-4xl ml-10;
}
table#spreadsheet tbody tr {
	@apply border-b border-base-200;
}
table#spreadsheet tbody tr .row-number {
	@apply absolute flex justify-center items-center -left-10 top-0 bg-base-100 h-full w-10;
}
table#spreadsheet tbody tr .clear-button {
	@apply absolute flex justify-center items-center top-0 -right-10 w-10 h-full;
}
table#spreadsheet tbody td {
	@apply border-base-200 p-0 h-10;
}
table#spreadsheet tbody td:first-child {
	@apply relative border-l border-r;
}
table#spreadsheet tbody td:not(:first-child) {
	@apply border-r;
}
table#spreadsheet tbody td input {
	@apply w-full h-full block border border-white px-2 outline-none focus:border-blue-300;
}

table#spreadsheet tbody td textarea, .textarea-grow-wrap::after,
table#spreadsheet tbody td input, .input-grow-wrap::after {
	@apply w-full min-h-full block whitespace-pre-wrap border border-white px-2 outline-none focus:border-blue-300 resize-none py-2;
}


/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.textarea-grow-wrap,
.input-grow-wrap {
	@apply grid h-full;
}
.textarea-grow-wrap::after, .textarea-grow-wrap > textarea,
.input-grow-wrap::after, .input-grow-wrap > input {
	grid-area: 1 / 1 / 2 / 2;
	@apply h-full;
}
.textarea-grow-wrap::after,
.input-grow-wrap::after {
	content: attr(data-replicated-value) " ";
	visibility: hidden;
}
</style>


<script lang="ts">
import { TrashIcon } from '@heroicons/vue/24/outline';

export default {
	components: {
		TrashIcon,
	},

	props: {

		modelValue: {
			type: Array,
			required: true
		},

		columns: {
			type: Array,
			required: true
		},


	},

	data() {
		return {
			lastChangeWasPastedTableData: false,
			spreadsheetData: [],
			errorMessage: null,
		};
	},

	computed: {

		validateColumn() {
			return (rowIndex, columnIndex, value) => {

				if (!value) {

					// If any other field in this row is filled
					// and this field is required
					// return false.
					if (!this.columns[columnIndex]?.required) {
						return true;
					}

					if (this.isEmptyRow(this.spreadsheetData[rowIndex])) {
						return true;
					}

					// Is the focus element on the same row as the empty field?
					if (
						document.activeElement &&
						parseInt(document.activeElement?.getAttribute('data-row')) === rowIndex
					) {
						return true;
					}

					return false;
				}

				if (this.columns[columnIndex]?.validator) {
					return this.columns[columnIndex].validator(value);
				}
				return true;
			}
		},

		validateRow() {
			return (rowIndex: number) => {
				if (typeof(this.spreadsheetData[rowIndex]) === 'undefined') {
					return true;
				}

				let valid = true;
				this.spreadsheetData[rowIndex].forEach((cell, index) => {
					if (!this.validateColumn(rowIndex, index, cell.value)) {
						valid = false;
					}
				});
				return valid;
			}
		},

	},

	watch: {
		spreadsheetData: {
			// add a new row as soon as the last row has some content
			handler: function (val, oldVal) {
				const lastRow = this.spreadsheetData[this.spreadsheetData.length - 1];

				if(lastRow && !this.isEmptyRow(lastRow)) {
					this.spreadsheetData.push(this.newEmptyRow());
				}
			},
			deep: true
		}
	},

	methods: {

		navigateSheet(event, direction) {

			const row = Number(event.target.dataset.row);
			const col = Number(event.target.dataset.col);

			let caretPos = 0;

			switch(direction) {
				case 'up':

					// Has step up method? Then ignore
					if (!this.supportsVerticalNavigation(event.target)) {
						return;
					}

					if(row > 0) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'down':

					// Has step down method? Then ignore
					if (!this.supportsVerticalNavigation(event.target)) {
						return;
					}

					if(row < this.spreadsheetData.length - 1) {
						this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="${col}"]`).focus();
					}
					break;

				case 'left':
					caretPos = event.target.selectionStart;

					if(
						caretPos !== null &&
						caretPos > 0
					) {
						return;
					}

					if(col > 0) {
						const prevCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col - 1}"]`);

						prevCell.focus();

						if (!this.supportsSetSelectionRange(prevCell)) {
							return;
						}

						let endOfContent = prevCell.value.length;
						prevCell.setSelectionRange(endOfContent, endOfContent);
						event.preventDefault();

					} else if(row > 0) {
						const lastCellPrevRow = this.$refs.spreadsheet.querySelector(`[data-row="${row - 1}"][data-col="${this.columns.length - 1}"]`);
						lastCellPrevRow.focus();
					}
					break;

				case 'right':
					caretPos = event.target.selectionStart;

					if(
						caretPos !== null &&
						caretPos < event.target.value.length
					) {
						return;
					}

					if(col < this.columns.length - 1) {

						const nextCell = this.$refs.spreadsheet.querySelector(`[data-row="${row}"][data-col="${col + 1}"]`);
						nextCell.focus();

						if (!this.supportsSetSelectionRange(nextCell)) {
							return;
						}

						nextCell.setSelectionRange(0, 0);
						event.preventDefault();

					} else if(row < this.spreadsheetData.length - 1) {
						const firstCellNextRow = this.$refs.spreadsheet.querySelector(`[data-row="${row + 1}"][data-col="0"]`);
						firstCellNextRow.focus();
					}
					break;

				default: break;
			}
		},

		/**
		 * @param input
		 */
		supportsSetSelectionRange(input: HTMLInputElement | HTMLTextAreaElement) {
			if (typeof(input.setSelectionRange) === 'undefined') {
				return false;
			}

			switch (input.type) {
				case 'text':
				case 'search':
				case 'url':
				case 'tel':
				case 'password':
				case 'textarea':
					return true;
				default:
					return false;
			}
		},

		/**
		 * Should user be able to navigate vertically away from this input element, or should
		 * the input element adjust its value instead?
		 * @param input
		 */
		supportsVerticalNavigation(input: HTMLInputElement | HTMLTextAreaElement) {
			if (input instanceof HTMLTextAreaElement) {
				return true;
			}

			if (typeof(input.stepUp) !== 'undefined') {
				return false;
			}

			return true;
		},

		newEmptyRow() {
			let row = [];
			for(let i = 0; i < this.columns.length; i++) {
				row[i] = {};
				row[i].value = "";
				row[i].error = null;
				row[i].required = (i === 0 || i === 1)? true : false;
			}
			return row;
		},

		isEmptyRow(row) {
			let isEmpty = true;
			for(let i = 0; i < this.columns.length; i++) {
				if(row[i].value) {
					isEmpty = false;
					break;
				}
			}
			return isEmpty;
		},

		handlePaste(event, index) {
			const pastedData = event.clipboardData.getData('text');
			const rows = pastedData.split("\n");

			if(rows.length > 1) {

				event.preventDefault();

				for (let i = 0; i < rows.length; i++) {
					rows[i] = rows[i].replace(/\r/g, '');

					if (rows[i].trim() === '') {
						break;
					}

					this.handleTabbedInputRow(rows[i], index + i);
				}
			}

			this.emitChange();
		},

		handleTabbedInputRow(value: string, index: number) {

			let rowObject = null;
			if (this.spreadsheetData.length > index) {
				rowObject = this.spreadsheetData[index];
			} else {
				rowObject = this.newEmptyRow();
				this.spreadsheetData.push(rowObject);
			}

			const values = value.split("\t");
			values.forEach((val, idx) => {
				rowObject[idx].value = this.handleColumnInputPaste(idx, val);
			});

		},

		handleColumnInputPaste(columnIndex: number, value: string) {
			if (this.columns[columnIndex]?.pasteTransformer) {
				return this.columns[columnIndex]?.pasteTransformer(value);
			} else if (this.columns[columnIndex]?.transformer) {
				return this.columns[columnIndex]?.transformer(value);
			}

			return value;
		},

		handleColumnInputChange(columnIndex: number, value: string) {
			if (this.columns[columnIndex]?.transformer) {
				return this.columns[columnIndex]?.transformer(value);
			}

			return value;
		},

		deleteRow(rowIndex) {
			this.spreadsheetData.splice(rowIndex, 1);
		},

		cleanUpSpreadsheetData() {
			// remove empty rows
			this.spreadsheetData = this.spreadsheetData.filter(row => {
				return !this.isEmptyRow(row);
			});
		},

		validate() {
			return this.validateSpreadsheetData();
		},

		validateSpreadsheetData() {
			let valid = true;

			if (this.spreadsheetData.length === 1) {
				this.errorMessage = this.$t('Please fill in all required fields');
				return false;
			}

			this.spreadsheetData.forEach((row, index) => {
				if(!this.validateRow(index)) {
					valid = false;
				}
			});

			this.errorMessage = valid ? null : this.$t('Please fill in all required fields');

			return valid;
		},

		changeValue(row, column, event) {
			event.target.value = this.handleColumnInputChange(column, event.target.value)
			this.emitChange();
		},

		emitChange() {

			// Cleanup empty rows
			const out = [];
			this.spreadsheetData.forEach(row => {
				if (!this.isEmptyRow(row)) {
					out.push(row);
				}
			});

			this.$emit('update:modelValue', out);
		},

		trySave() {
			// remove empty rows
			this.cleanUpSpreadsheetData();

			// validate data
			try {
				this.validateSpreadsheetData();
			} catch(e) {
				this.spreadsheetData.push(this.newEmptyRow());
				// console.error(e);
				return;
			}
			// if error from backend, add empty row again at the end
			// ...
		}

	},

	created() {
		if(this.spreadsheetData.length === 0) {
			this.spreadsheetData.push(this.newEmptyRow());
		}
	},

	mounted() {

		if (typeof(this.modelValue) === 'undefined') {
			this.spreadsheetData = this.modelValue;
		}

		// set focus to first cell of the spreadsheet
		// const firstCell = this.$refs.spreadsheet.querySelector('tbody tr:first-child td:first-child input');
		const firstCell = this.$refs.spreadsheet.querySelector('tbody tr:first-child td:first-child textarea');
		setTimeout(() => {
			firstCell.focus();
		}, 300);
	},

};
</script>
