<template>
	<TheStdLayout :title="$t('Network error')">

		<div class="py-6 px-4">
			<p>{{ $t('Please check your network connection and try again.') }}</p>
			<a href="/" class="btn btn-primary">{{$t('Reload page')}}</a>
		</div>

	</TheStdLayout>
</template>

<script lang="ts">
import TheStdLayout from "@/components/layout/TheStdLayout.vue";

export default {
	components: {
		TheStdLayout,
	}
}
</script>
