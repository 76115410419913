<template>
	<div class="flex justify-center">
		<div v-if="showFilterOptions" class="absolute z-10 bg-base px-4 py-4 shadow-lg rounded-lg flex flex-col items-center" style="animation: button-pop 0.25s ease-out;">
			<button type="button" @click="selectColors(availableColors)" class="btn-effect flex -space-x-7 mb-2">
				<div v-for="color in reversedArray(availableColors)" class="w-8 h-8 mask mask-squircle" :class="'bg-' + color + '-300'">
				</div>
			</button>
			<div class="flex flex-col gap-3">
				<hr>
				<button type="button"
					v-for="color in availableColors"
					@click="selectColors([color])"
					:key="color"
					class="btn-effect w-10 h-10 mask mask-squircle hover:bg-opacity-90"
					:class="'bg-' + color + '-300'"
				>
				</button>
			</div>
		</div>

		<button type="button" class="btn-effect flex items-center  mb-2 bg-base-100 hover:bg-base-200 p-2 rounded-lg" @click="showFilterOptions = true">
			<div class="flex -space-x-7">
				<div v-for="color in allSelectedColors" class="w-8 h-8 mask mask-squircle" :class="'bg-' + color + '-300'"></div>
			</div>
			<ChevronDownIcon class="w-3 h-3 ml-1" />
		</button>

	</div>
</template>

<script lang="ts">
import { Pupil } from "@/models/Pupil.model";
import { mapStores } from "pinia";
import { useActivityBoardStore } from '@/stores/ActivityBoard.store';
import { ChevronDownIcon } from "@heroicons/vue/24/outline";


export default {
	data() {
		return {
			showFilterOptions: false,
			availableColors: Pupil.COLORS,
		}
	},

	components: {
		ChevronDownIcon,
	},

	computed: {
		...mapStores(useActivityBoardStore),

		allSelectedColors() {
			return this.reversedArray(this.activityBoardStore.avatarSelectedColors);
		}
	},

	methods: {
		selectColors(colors: string[]) {
			this.activityBoardStore.setAvatarSelectedColors(colors)
			this.showFilterOptions = false;

			if(this.$gtag) {
				this.$gtag.event('select-colors', {
					'event_category': 'activity-board',
					'event_label': 'configuration',
					'value': colors.join(','),
				});
			}
		},
		// returs a reversed COPY of the array (toReversed() is only supported as of Safari 15)
		reversedArray(array: any[]) {
			return array.slice().reverse();
		}
	}
}
</script>
