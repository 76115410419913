<template>
	<Drawer ref="ovsg-drawer" :title="$t('Select goals')" initialWidth="3xl" :closeOnClickOutside="true" @requestUnmount="onDrawerRequestsClose">

		<!-- <template #default="{ hide }"> -->
		<template v-if="showSuggestions" #default="{ resizeTo }">
			<div class="p-4">
			<p class="font-bold mb-4">{{ $t('Suggestions') }}</p>
			<ul class="mb-4">
				<li v-for="goal in suggestedGoals" :key="goal.id" :class="goal.isSubgoal() ? 'pl-4' : 'pl-0'">
					<GoalListItem
						:listItem="goal"
						action="select"
						:checked="selectedSuggestions.has(goal.sourceId)"
						@checked="selectSuggestedGoal"
						@unchecked="deselectSuggestedGoal"
					/>
				</li>
			</ul>
			<div class="flex justify-between gap-4 px-2 pt-2">
				<button type="button" class="btn btn-neutral btn-outline" @click="() => { prepareOvsgSelector(); resizeTo('5xl'); }">Open OVSG Selector ...</button>
				<button type="button" class="btn btn-primary" @click="closeSuggestionsPanel()">Klaar</button>
			</div>
			</div>
		</template>
		<template v-else #default>
			<div>
				<div v-if="showClientError" class="alert alert-error text-sm">
					Door een storing kon de doel-info momenteel niet worden opgehaald van het OVSG-platform. Probeer nogmaals opnieuw, of meld ons het probleem
					(via de knop helemaal onderaan het hoofdmenu) wanneer het zich blijft voordoen.
				</div>
				<div v-else class="alert alert-info">
					<!-- Selecteer de doelen, kijk je selectie na, en kies dan 'Voeg toe aan {{ buttonLabelSuffix }}'. -->
					Selecteer de doelen, kijk je selectie na, en kies dan 'Bewaar in Smart Symbols'.
				</div>
			</div>

			<div ref="selector-container" class="w-full h-full relative">
				<div v-if="retrievingGoalData" class="absolute w-full h-full bg-white/50 flex items-center justify-center">
					<span class="loading loading-spinner loading-lg"></span>
				</div>
				<iframe ref="ovsg-selector" src="" style="width: 100%; height: 100%; border: none;"></iframe>
			</div>
		</template>
		<!-- </template> -->

	</Drawer>
</template>



<script lang="ts">
import { mapStores, mapState } from "pinia";
import { useOrganisationsStore } from "@/stores/Organisations.store";

import Drawer from '@/components/ui/Drawer.v2.vue';
import GoalListItem from '@/components/GoalListItem.vue';
import {
	OVSG_HAS_CREDENTIALS,
	OVSG_SELECTOR_DOMAIN,
	OVSG_SELECTOR_DOMAIN_PURE,
	OVSG_SELECTOR_PATH
} from "@/goals/ovsg/settings";
import {OvsgLoader} from "@/goals/ovsg/utils/OvsgLoader.util";
import {Goal} from "@/models/Goal.model";

// This is a global variable to keep track of the authentication status of the OVSG Selector
let OVSG_AUTHENTICATED = false;

export default {

	emits: [
		'requestUnmount',
		'goalSelected',
		'goalUnselected'
	],

	props: {
		selectedGoals: {
			type: Array,
			default: () => [],
		},

		suggestedGoals: {
			type: Array,
			default: () => [],
		},

		context: {
			type: String,
		},
	},

	components: {
		Drawer,
		GoalListItem,
	},

	data() {
		return {
			showSuggestions: false,
			selectedSuggestions: new Map(),
			retrievingGoalData: false,
			showClientError: false,
			initialized: false,
		}
	},

	computed: {
		...mapStores(useOrganisationsStore),

		buttonLabelSuffix() {
			if(this.context === 'theme') {
				return 'thema';
			} else {
				return 'lesplan';
			}
		},
	},

	created() {
		this.showSuggestions = this.selectedGoals.length === 0 && this.suggestedGoals.length > 0;
	},

	async mounted() {

		if(!this.showSuggestions) {
			this.prepareOvsgSelector();
		}

		//(new OvsgLoader()).load('384b3499-6136-4abf-b040-dd023419b785');

		//this.handleSelectionUpdate('384b3499-6136-4abf-b040-dd023419b785');
	},

	beforeUnmount() {
		window.removeEventListener("message", this.handlePostMessage);
		window.removeEventListener("resize", this.adjustIframeHeight);

		if (this.initializeTimeout) {
			clearTimeout(this.initializeTimeout);
		}
	},

	methods: {
		addGoal(goal) {
			// check if goal was already selected (just in case, normally not possible if OVSG Selector got initialized correctly)
			if(this.selectedGoals.find(g => g.sourceId === goal.sourceId)) {
				return;
			}
			this.$emit('goalSelected', goal);
		},

		removeGoal(goal) {
			this.$emit('goalUnselected', goal);
		},

		selectSuggestedGoal(goal) {
			this.selectedSuggestions.set(goal.sourceId, goal);

			// also add the parent goal if any
			if(goal.sourceParentPath) {
				const parentGoal = this.suggestedGoals.find(g => g.fullPath === goal.sourceParentPath);
				if(parentGoal) {
					this.selectedSuggestions.set(parentGoal.sourceId, parentGoal);
				}
			}
		},

		deselectSuggestedGoal(goal) {
			this.selectedSuggestions.delete(goal.sourceId);

			// also remove selected subgoals if any
			const subgoals = this.suggestedGoals.filter(g => g.sourceParentPath === goal.fullPath);
			for(let subgoal of subgoals) {
				this.selectedSuggestions.delete(subgoal.sourceId);
			}
		},

		prepareOvsgSelector() {

			this.commitSelectedSuggestedGoals();
			this.showSuggestions = false;

			window.addEventListener('message', this.handlePostMessage);
			window.addEventListener("resize", this.adjustIframeHeight);

			this.$nextTick().then(() => {

				/*
				this.ovsgSelectorWindow = this.$refs['ovsg-selector'].contentWindow;
				this.adjustIframeHeight();
				this.loadOvsgSelector();

				 */

				this.initialized = false;
				this.loadOvsgSelector();

				// Wait 10 seconds and if not initialized by then, throw an error
				if (this.initializeTimeout) {
					clearTimeout(this.initializeTimeout);
				}

				this.initializeTimeout = setTimeout(() => {
					if(!this.initialized) {
						throw new Error('OVSG Selector did not initialize correctly within 10 seconds');
					}
				}, 10 * 1000);
			});
		},

		handlePostMessage(event) {

			// Remove the basic http auth from the url, if any is set
			const expectedOrigin = OVSG_SELECTOR_DOMAIN_PURE;
			if (event.origin.substring(0, expectedOrigin.length) !== expectedOrigin) {
				throw new Error('Received postMessage from unexpected origin');
			}

			// Clear the authentication timeout and execute the redirect immediately
			OVSG_AUTHENTICATED = true;
			if (this.ovsgAuthTimeout) {
				clearTimeout(this.ovsgAuthTimeout);
				this.ovsgAuthTimeout = null;
				this.postAuthPopupRedirect();
				return;
			}

			const { actie, inhoud } = event.data;

			// Leer Lokaal is klaar en vraagt een lege of bestaande selectie
			if (actie === 'vraag_selectie') {

				// Set initialized flag to true
				this.initialized = true;

				const selectedGoals = {
					basisdoel_uuids: [],
					attitude_uuids: [],
				};

				this.selectedGoals.forEach((goal: Goal) => {

					const sourceIdSplit = goal.sourceId.split('/');
					switch (sourceIdSplit.shift()) {
						case 'A':
							selectedGoals.attitude_uuids.push(sourceIdSplit.join('/'));
							break;

						case 'B':
							selectedGoals.basisdoel_uuids.push(sourceIdSplit.join('/'));
							break;
					}

				});

				const msgBody = {
					actie: 'huidige_selectie',
					inhoud: selectedGoals
				};

				this.ovsgSelectorWindow.postMessage(msgBody, expectedOrigin);
			}

			// ontvangst van een nieuwe selectie van Leer Lokaal
			if (actie === 'nieuwe_selectie') {
				const { selectie_uuid, basisdoel_uuids, attitude_uuids } = inhoud;
				this.handleSelectionUpdate(selectie_uuid);
			}

		},

		async loadOvsgSelector() {

			// Do we have secret http username & password for OVSG API?
			this.ovsgDomainWithoutCredentials = OVSG_SELECTOR_DOMAIN.replace(/^(https?:\/\/)([^@]+@)/, '$1');

			//this.ovsgSelectorWindow.location = OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH;

			//let win = window.open(OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH);
			let popupWidth = 0;
			const screenWidth = window.screen.width;
			if(screenWidth > 1200) {
				popupWidth = 1200;
			} else {
				popupWidth = 800;
			}

			// Should do authenticate juggle?
			let url = OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH;
			if (OVSG_AUTHENTICATED) {
				url = OVSG_SELECTOR_DOMAIN_PURE + OVSG_SELECTOR_PATH;
			}

			this.ovsgSelectorWindow = this.popupCenter({
				url: url,
				title: 'ovsg-selector',
				width: popupWidth,
				height: 600
			});

			this.ovsgAuthTimeout = null;
			if (OVSG_HAS_CREDENTIALS && !OVSG_AUTHENTICATED) {
				// Wait for the popup to load and then redirect to the OVSG Selector
				this.ovsgAuthTimeout = setTimeout(() => {
					this.postAuthPopupRedirect();
				}, 5000);
			}

			// Listen for popup close.
			var timer = setInterval(() => {
				if(!this.ovsgSelectorWindow || this.ovsgSelectorWindow.closed) {
					clearInterval(timer);
					this.closeDrawer();
				}
			}, 500);

			return this.ovsgSelectorWindow;

			//this.ovsgSelectorWindow.location = OVSG_SELECTOR_DOMAIN + OVSG_SELECTOR_PATH;
			//this.ovsgSelectorWindow.opener = window;
		},

		postAuthPopupRedirect() {
			this.ovsgSelectorWindow.location = OVSG_SELECTOR_DOMAIN_PURE + OVSG_SELECTOR_PATH;
		},

		popupCenter({url, title, width, height}) {
			// Taken from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
			// http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html

			// Fixes dual-screen position                             Most browsers      Firefox
			const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
			const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

			const screenWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
			const screenHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

			//const systemZoom = screenWidth / window.screen.availWidth;
			const left = (screenWidth - width) / 2 + dualScreenLeft
			const top = (screenHeight - height) / 2 + dualScreenTop
			return window.open(url, title,
				`
				  scrollbars=yes,
				  width=${width},
				  height=${height},
				  top=${top},
				  left=${left}
				  `
			)

		},

		adjustIframeHeight() {
			let container = this.$refs['selector-container'];
			let iframe = this.$refs['ovsg-selector'];
			iframe.style.height = container.clientHeight + "px";
		},

		async handleSelectionUpdate(selectionUid: string) {
			this.retrievingGoalData = true;

			const goalsToRemove = new Map(this.selectedGoals.map(goal => [goal.sourceId, goal]));

			let loader = new OvsgLoader();
			try {
				const goals = await loader.load(selectionUid);
				goals.forEach((goal) => {
					this.addGoal(goal);
					goalsToRemove.delete(goal.sourceId);
				});
			} catch(e) {
				console.error('Error loading goals from OVSG API', e);
				this.retrievingGoalData = false;
				this.showClientError = true;
				return;
			}

			// remove goals that are no longer selected
			for(let goal of goalsToRemove.values()) {
				this.removeGoal(goal);
			}

			this.closeDrawer();
		},

		closeDrawer() {
			if (this.$refs['ovsg-drawer']) {
				this.$refs['ovsg-drawer'].prepareToClose();
			}
		},

		onDrawerRequestsClose() {
			if (this.ovsgSelectorWindow) {
				this.ovsgSelectorWindow.close();
				this.ovsgSelectorWindow = null;
			}

			this.$emit('requestUnmount');
		},

		commitSelectedSuggestedGoals() {

			const selectedSuggestions = this.selectedSuggestions.values();
			for(let goal of selectedSuggestions) {
				this.addGoal(goal);
			}

		},

		closeSuggestionsPanel() {
			this.showSuggestions = false;
			this.commitSelectedSuggestedGoals();

			this.$refs['ovsg-drawer'].prepareToClose();
		},
	}

}
</script>
