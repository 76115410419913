<template>

	<Teleport to="body">

		<Transition
			enter-from-class="opacity-0"
			enter-active-class="ease-out duration-300"
			enter-to-class="opacity-100"
			leave-from-class="opacity-100"
			leave-active-class="ease-in duration-200"
			leave-to-class="opacity-0"
		>
			<div v-if="visible" id="modal-backdrop" class="fixed inset-0 bg-black bg-opacity-30 z-40"></div>
		</Transition>

		<Transition
			enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			enter-active-class="ease-out duration-300"
			enter-to-class="opacity-100 translate-y-0 sm:scale-100"
			leave-from-class="opacity-100 translate-y-0 sm:scale-100"
			leave-active-class="ease-in duration-200"
			leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		>
			<div v-if="visible" id="SmSmodalContainer"
				 class="fixed bottom-0 left-0 right-0 sm:inset-0 grid place-items-center z-40"
				 @keyup.esc="prepareToClose" @click.self="handleClickOutside">

				<!-- Actual dialog element -->
				<div class="flex flex-col w-full p-6 bg-base overflow-hidden isolate"
					 :class="[ fullscreen ? 'fixed inset-0' : 'relative max-h-90vh rounded-t-xl sm:rounded-xl shadow-lg ' + modalWidth]"
					 v-bind="$attrs"
				>

					<header>
						<div class="pr-8 relative">
							<button class="absolute right-0 btn btn-circle btn-ghost btn-sm print:hidden" @click="prepareToClose">
								<XMarkIcon class="w-6 h-6"></XMarkIcon>
							</button>
							<slot name="header">
								<div class="font-semibold text-xl mb-4">
									{{ modalTitle }}
								</div>
							</slot>
						</div>
					</header>

					<div class="flex-grow overflow-y-auto overflow-x-hidden flex flex-col h-full">
						<slot :hide="prepareToClose">
							Dialog content
						</slot>
					</div>

				</div>

			</div>
		</Transition>

	</Teleport>

</template>


<style>
.max-h-90vh {
	max-height: 90vh;
}
</style>


<script lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';

export default {
	name: 'Modal',
	emits: ['requestUnmount', 'beforeRequestUnmount'],
	inheritAttrs: false,
	props: {
		modalTitle: String,     // (simpler) alternative to using the header slot
		fullscreen: Boolean,
		closeOnClickOutside: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: 'lg',
			validator(value) {
				return ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'].includes(value);
			},
		},
		beforeClose: {
			type: Function,
			default: null,
		},
	},
	components: {
		XMarkIcon,
	},
	data() {
		return {
			visible: false,
		}
	},
	mounted() {
		// only set visible once mounted in order to allow animation to work
		this.visible = true;
		// set focus to first input with 'autofocus' attribute, but wait for animation to complete
		setTimeout(() => {
			let firstInput = document.querySelector('#SmSmodalContainer input[autofocus]');
			if (firstInput) {
				firstInput.focus();
			}
		}, 300);
	},
	computed: {
		modalWidth() {
			switch (this.width) {
				case 'xs':
					return 'sm:max-w-xs';
				case 'sm':
					return 'sm:max-w-sm';
				case 'md':
					return 'sm:max-w-md';
				case 'lg':
					return 'sm:max-w-lg';
				case 'xl':
					return 'sm:max-w-xl';
				case '2xl':
					return 'sm:max-w-2xl';
				case '3xl':
					return 'sm:max-w-3xl';
				case '4xl':
					return 'sm:max-w-4xl';
			}
		}
	},
	methods: {
		async prepareToClose() {

			if (this.beforeClose) {
				let canClose = await this.beforeClose();
				if (!canClose) {
					return;
				}
			}

			this.visible = false;
			setTimeout(() => {  // allow animation to complete before emitting event
				this.$emit('requestUnmount');
			}, 200);    // should correspond with the 'duration' property of the Transition component
		},
		handleClickOutside() {
			if (this.closeOnClickOutside) {
				this.prepareToClose();
			}
		},
	},
};
</script>
