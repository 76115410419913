<template>
	<Modal @requestUnmount="hide">

		<template #header>
			<div class="font-semibold text-xl mb-4" v-text="isNew? $t('New library') : $t('Edit library')"></div>
		</template>

		<template #default="modalActions">

			<form @submit.prevent="save">
				<div v-if="panel === 'general'" class="grid grid-cols-1 gap-6 mb-6">

					<div v-if="iconLibrariesStore.errorMessage?.genericError" class="alert alert-error mb-4 w-full sm:max-w-2xl m-auto">
						<div>
							<span>{{ $t(iconLibrariesStore.errorMessage?.genericError.toString()) }}</span>
						</div>
					</div>

					<div>
						<sms-input type="text" autofocus v-model="editableModel.name" :label="$t('Name')" :validationError="iconLibrariesStore.errorMessage?.fields.name" />
					</div>

					<div v-if="isNew">
						<div class="text-sm mb-4">
							{{ $t('The icons in this library will be used for ...') }}
						</div>
						<ul class="flex flex-col gap-4 ml-4">
							<li class="flex items-center gap-2">
								<input type="radio" value="activity" id="cat-activities" class="radio" v-model="editableModel.group" />
								<label for="cat-activities" class="hover:cursor-pointer">{{ $tc('Activity', 2) }}</label>
							</li>
							<li class="flex items-center gap-2">
								<input type="radio" value="pupil" id="cat-pupils" class="radio disabled:cursor-not-allowed" v-model="editableModel.group" />
								<label for="cat-pupils" class="hover:cursor-pointer">{{ $tc('Pupil', 2) }}</label>
							</li>
						</ul>
						<span class="alert alert-warning text-sm py-1 mt-6 items-start">
								<ExclamationTriangleIcon class="w-5 h-5" />
								{{ $t('The category cannot be changed afterwards.') }}
						</span>
					</div>

				</div>

				<div class="modal-footer flex" :class="[!isNew? 'justify-between' : 'justify-end']">
					<Dropdown v-if="!isNew" position="top-start">
						<template #trigger>
							<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5" />
							</button>
						</template>
						<template #content="dialogActions">
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @cancel="dialogActions.close" @confirm="confirmRemove(dialogActions)">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="modalActions.hide" :class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>


<script lang="ts">

import {useIconLibrariesStore} from "@/stores/IconLibraries.store";
import {mapStores} from 'pinia'
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {IconLibrary} from "@/models/IconLibrary.model";
import Modal from "@/components/ui/Modal.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";

import {
	TrashIcon, ExclamationTriangleIcon,
} from '@heroicons/vue/24/outline';

export default {

	props: {
		library: {
			type: IconLibrary,
			required: false
		},
		scope: {
			type: String,
		}
	},

	emits: ['requestClose'],

	components: {
		Modal,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon, ExclamationTriangleIcon,
	},

	data() {
		return {
			panel: 'general',
			isNew: false,
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null
		}
	},

	beforeMount() {
		if(this.library && this.library.id) {
			this.originalModel = this.library;
			// clone the store model to allow for manipulation without instantly affecting affected the store
			this.editableModel = this.library.clone();

		}else{
			this.isNew = true;
			if (this.scope === 'classroom') {
				this.editableModel = this.iconLibrariesStore.new(true);
			}else{
				this.editableModel = this.iconLibrariesStore.new(false);
			}
		}
		this.iconLibrariesStore.clearErrorMessage();
	},

	computed: {
		...mapStores(useIconLibrariesStore)
	},

	methods: {

		/**
		 * Hide modal.
		 */
		hide() {
			this.iconLibrariesStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		async confirmRemove(dialogActions) {
			dialogActions.close();

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.iconLibrariesStore.delete(this.originalModel);
				this.deleting = false;
				this.hide();

				this.$notify({
					text: this.$t('The icon library has been removed'),
					type: "success",
				});
			} catch (e) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
				this.deleting = false;
			}
		},

		async save() {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {

				await this.iconLibrariesStore.save(this.editableModel);

				this.saving = false;
				this.hide();

				this.$notify({
					text: this.$t('The icon library has been saved'),
					type: "success",
				});

			} catch (e) {
				this.saving = false;

				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		}
	}

}

</script>
