import {defineStore} from 'pinia'

interface PreferenceStoreState {
	lastUsedPupilIconLibrary: string | null,
	lastUsedActivityIconLibrary: string | null
}

export const usePreferencesStore = defineStore('preferences', {

	state: (): PreferenceStoreState => ({
		lastUsedPupilIconLibrary: null,
		lastUsedActivityIconLibrary: null
	}),

	actions: {
		loadPreferences() {
			try {
				if (localStorage.getItem('last_used_pupil_icon_library')) {
					this.lastUsedPupilIconLibrary = localStorage.getItem('last_used_pupil_icon_library');
				}

				if (localStorage.getItem('last_used_activity_icon_library')) {
					this.lastUsedActivityIconLibrary = localStorage.getItem('last_used_activity_icon_library');
				}
			} catch (e) {
				console.log(e);
			}
			return this;
		},

		setLastUsedPupilIconLibraryId(libraryId: string)
		{
			this.lastUsedPupilIconLibrary = libraryId;
			try {
				localStorage.setItem('last_used_pupil_icon_library', libraryId);
			} catch (e) {
				console.log(e);
			}
		},

		setLastUsedActivityIconLibraryId(libraryId: string)
		{
			this.lastUsedActivityIconLibrary = libraryId;
			try {
				localStorage.setItem('last_used_activity_icon_library', libraryId);
			} catch (e) {
				console.log(e);
			}
		}
	}

});
