import { Goal } from "@/models/Goal.model";
import { OVSG_CURRICULUM_SOURCE_NAME, OVSG_PROXY_API_PATH } from "@/goals/ovsg/settings";
import { api } from "@/utils/Api.util";


export class OvsgLoader {

	/**
	 * @private
	 */
	private goalPromiseMap: Map<string, Promise<Goal>> = new Map<string, Promise<Goal>>();

	public constructor() {



	}


	async load(selectionUid: string) {

		const response = await api.get(OVSG_PROXY_API_PATH + '/selecties/' + selectionUid);

		const data = response.data;

		const goals: Goal[] = [];

		// Map to goals
		for (let type in data.data) {
			if (!data.data.hasOwnProperty(type)) {
				continue;
			}

			if (!Array.isArray(data.data[type])) {
				continue;
			}

			const typeData = data.data[type];
			typeData.forEach((goalData: any) => {

				let goal = this.transformToGoal(type, goalData.data);
				goals.push(goal);

			});
		}

		return goals;

	}

	transformToGoal(dataType: string, data: any): Goal {

		const goal = new Goal();
		goal.source = OVSG_CURRICULUM_SOURCE_NAME;

		//const codeParts = data.code.split(' ');
		//console.log(data.code, codeParts);

		//goal.sourceParentPath = codeParts.shift().split('.').shift();
		//goal.sourcePathId = codeParts.join('.');

		const codeParts = data.code.split(' ');
		goal.sourcePathId = codeParts.pop();
		goal.sourceParentPath = codeParts.join('.');

		switch (dataType) {

			case 'attitudes':

				goal.sourceId = 'A/' + data.attitude_uuid;

				goal.title = this.sanitizeContent(data.omschrijving);
				goal.tags = [
					'Attitudes',
				];

				return goal;

			case 'basisdoelen':

				goal.sourceId = 'B/' + data.basisdoel_uuid;
				goal.title = this.sanitizeContent(data.omschrijving);
				goal.tags = [
					'Basisdoelen',
				];

				return goal;

		}

		throw new Error('Unknown data type: ' + dataType);
	}


	sanitizeContent(content: string): string {
		return content;
	}

}
