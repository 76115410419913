<template>
    <div class="flex">
		<input type="text" maxlength="5" :value="time" @blur="handleKeyboardInput" class="input border-none hover:bg-base-100 w-20 text-center" />
		<div class="flex flex-col">
			<button type="button" class="flex w-8 justify-center py-1 hover:bg-base-100" @click="timeUp">
				<ChevronUpIcon class="w-4 h-4"></ChevronUpIcon>
			</button>
			<button type="button" class="flex w-8 justify-center py-1 hover:bg-base-100" @click="timeDown">
				<ChevronDownIcon class="w-4 h-4"></ChevronDownIcon>
			</button>
		</div>
    </div>
</template>

<script lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'TimePicker',
    props: {
        modelValue: {
            type: String,   // hh:mm
            validator(value) {
                const regex = new RegExp(/^([01]?[0-9]|2[0-3]):?([0-5]\d)$/);
                return regex.test(value);
            }
        },
        interval: {
            type: Number,
            default: 5,
            validator(value) {
                return [1, 5, 10, 15, 20, 30, 60].includes(value);
            }
        },
    },
    components: {
        ChevronDownIcon,
        ChevronUpIcon,
    },
    data() {
        return {
            time: this.modelValue,
        };
    },
    methods: {
        timeUp() {
            const timeParts = this.time.split(':');
            let hours = parseInt(timeParts[0]);  // only keep the digits
            let minutes = null;
            if(timeParts.length > 1) {
                minutes = parseInt(timeParts[1]);
            }else{
                minutes = 0;
            }
            minutes = (minutes + this.interval) - ((minutes + this.interval) % this.interval);
            if(minutes >= 60) {
                minutes = minutes - 60;
                hours++;
            }
            if(hours >= 24) {
                hours = 0;
            }
            this.time = hours.toString().padStart(2,'0') + ':' + minutes.toString().padStart(2,'0');
            this.syncModelValue();
        },
        timeDown() {
            const timeParts = this.time.split(':');
            let hours = parseInt(timeParts[0]);
            let minutes = null;
            if(timeParts.length > 1) {
                minutes = parseInt(timeParts[1]);
            }else{
                minutes = 0;
            }
            minutes = (minutes - this.interval) + ((minutes - this.interval) % this.interval);
            if(minutes < 0) {
                minutes = minutes + 60;
                hours--;
            }
            if(hours < 0) {
                hours = 23;
            }
            this.time = hours.toString().padStart(2,'0') + ':' + minutes.toString().padStart(2,'0');
            this.syncModelValue();
        },
        handleKeyboardInput(event) {
            this.time = this.getCleanedFieldInput(event.target);
            this.syncModelValue();
        },
        // Removes any invalid characters from the input field
        getCleanedFieldInput(fieldEl) {
            const timeParts = fieldEl.value.split(':');
            let hours = timeParts[0].replace(/\D/g,'');
            if(!hours || parseInt(hours) > 23) {
                hours = '00';
            }else if(parseInt(hours) > 23) {
                hours = '23';
            }
            let minutes = null;
            if(timeParts.length > 1) {
                minutes = timeParts[1].replace(/\D/g,'');
                if(!minutes) {
                    minutes = '00';
                }else if(parseInt(minutes) > 59) {
                    minutes = '59';
                }
            }else{
                minutes = '00';
            }
            return hours + ':' + minutes;
        },
        syncModelValue() {
            this.$emit('update:modelValue', this.time);
        },
    }
};
</script>
