<template>

	<Drawer v-if="editableModel" :title="inUpdateMode? $t('Edit lesson plan') : $t('Add lesson plan')" initialWidth="3xl" :beforeClose="canClose"  @requestUnmount="hide">

		<template #default="{ hide }">

			<form @submit.prevent="save">

				<div class="pb-16 px-6">
					<!-- <p v-if="lessonPlansStore.errorMessage?.genericError" class="text-red-600 text-xs">
							{{ lessonPlansStore.errorMessage?.genericError.toString() }}
					</p> -->

					<LessonPlanForm :lessonPlan="editableModel" :showOptionGuided="false" :classroomContext="false" :errors="lessonPlansStore.errorMessage" class="mb-10" />
				</div>

				<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">

					<ActionConfirm position="top-start" class="w-80" v-slot="confirmationDialog">
						<button type="button" v-if="lessonPlan && lessonPlan.id" class="btn" :class="[deleting? 'btn-disabled':'btn-outline btn-danger']" @click="confirmRemove(confirmationDialog)">
							<span v-show="deleting" class="loading loading-spinner"></span>
							<TrashIcon class="w-5 h-5"/>
						</button>
					</ActionConfirm>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="hide"
										:class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Drawer>
</template>


<script lang="ts">
import { mapStores } from 'pinia';

import { useLessonPlansStore } from "@/stores/LessonPlans.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { LessonPlan } from "@/models/LessonPlan.model";

import Drawer from '@/components/ui/Drawer.v2.vue';
import LessonPlanForm from '@/components/LessonPlanForm.vue';
import ActionConfirm from "@/components/ui/ActionConfirm.vue";
import { TrashIcon } from '@heroicons/vue/24/outline';
import { UnsavedChanges } from "@/utils/UnsavedChanges.util";
import Modal from "@/components/ui/Modal.vue";

export default {

	emits: ['requestClose'],

	props: {
		lessonPlan: {
			type: LessonPlan,
			required: false,
		},
	},

	components: {
		Modal,
		Drawer,
		LessonPlanForm,
		ActionConfirm,
		TrashIcon,
	},

	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
		}
	},
	computed: {
		...mapStores(useLessonPlansStore),

		inUpdateMode() {
			return (this.lessonPlan && this.lessonPlan.id) ? true : false;
		},
	},

	methods: {

		hide() {
			this.lessonPlansStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		canClose() {
			return UnsavedChanges.nonexistentOrIgnorable();
		},

		async confirmRemove(confirmationDialog) {

			const confirmed = await confirmationDialog.show();
			if(!confirmed) {
				return;
			}

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.lessonPlansStore.delete(this.originalModel);
				this.deleting = false;

				this.hide();

			} catch (e: any) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}

		},

		async save() {

			// Was the lesson plan changed?
			if (this.originalModel && this.originalModel.equals(this.editableModel)) {
				this.hide();
				return;
			}

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.lessonPlansStore.save(this.editableModel);

				this.saving = false;
				this.hide();

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				if (e instanceof ApiErrors) {
					this.$notify({
						title: "Error!",
						text: e.toString(),
						type: "error",
					});
				} else {
					throw e;
				}
			}
		},

	},

	async beforeMount() {
		if (this.lessonPlan && this.lessonPlan.id) {
			this.originalModel = this.lessonPlan;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.lessonPlan.clone();

			UnsavedChanges.observeModel(this.originalModel, this.editableModel);
		} else {
			this.editableModel = await this.lessonPlansStore.new();
			this.editableModel.isGuided = true;

			UnsavedChanges.observeModel(this.editableModel.clone(), this.editableModel);
		}
	},

	async beforeUnmount() {
		UnsavedChanges.unobserveModel();
	}
}
</script>
