<template>
    <div class="flex justify-between items-center p-4 rounded-md bg-base shadow-lg ring-1 ring-neutral ring-opacity-5 focus:outline-none">
        <div>
            <slot></slot>
        </div>

        <div class="flex gap-2">
            <button type="button" class="btn" :class="buttonClasses" @click="handleConfirmation">{{ confirmLabel }}</button>
            <button type="button" class="btn btn-ghost" @click="handleCancellation">{{ cancelLabel }}</button>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        confirmLabel: {
            type: String,
            default: 'OK',
        },
        cancelLabel: {
            type: String,
            default: 'Cancel',
        },
        confirmStyle: {
            type: String,
            default: '',
        }
    },
    emits: [
        'confirm',
        'cancel',
    ],
    computed: {
        buttonClasses() {
            switch(this.confirmStyle) {
                case 'danger':
                    return 'btn-danger';
                default:
                    return '';
            }
        }
    },
    methods: {
        handleConfirmation() {
            this.$emit('confirm');
        },
        handleCancellation() {
            this.$emit('cancel');
        }
    }
}
</script>
