<template>
	<!-- SIDEBAR SMALL SCREEN -->
	<div class="lg:hidden relative sidebar-navigation print:hidden">
		<div class="absolute z-20 bg-base">
			<button @click="toggleSideBarDrawer"
					class="lg:hidden px-4 h-16 border-r border-gray-200 text-base-content-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
				<span class="sr-only">Open sidebar</span>
				<Bars3BottomLeftIcon class="h-6 w-6"/>
			</button>
		</div>

		<div v-show="showSideBarDrawer" class="fixed flex z-40 inset-0  sidebar-navigation">
			<div class="fixed inset-0" aria-hidden="true">
				<div class="absolute inset-0 bg-gray-600 opacity-75"></div>
			</div>

			<div class="relative flex-1 flex flex-col max-w-xs w-full pt-4 pb-4 bg-base-100">
				<div class="absolute top-0 right-0 -mr-12 pt-2">
					<button @click="toggleSideBarDrawer"
							class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
						<span class="sr-only">Close sidebar</span>
						<XMarkIcon class="h-6 w-6 text-white"/>
					</button>
				</div>

				<div class="flex-shrink-0 flex items-center px-4">
					<a href="/">
						<img src="/smartsymbols-logo.png" class="block h-10 w-auto" alt="Smart Symbols home"/>
					</a>
				</div>

				<div class="mt-5 flex-1 h-0 overflow-y-auto">
					<nav class="px-2 space-y-1">
						<!-- Don't forget the desktop instance -->
						<SideBarMenu :classrooms="currentOrganisation?.classrooms"/>
					</nav>
				</div>

				<div class="px-2 border-t border-base-300">
					<SideBarMenuLink :to="'/support'">
						<ChatBubbleOvalLeftEllipsisIcon class="w6 h-6 mr-3"/>
						{{ $t('Report a problem') }}
					</SideBarMenuLink>
				</div>

				<div class="px-4 text-gray-400 text-xs mt-5" :title="'Built on ' + buildDate">
					v{{ version }}
				</div>
			</div>

			<div class="flex-shrink-0 w-14" aria-hidden="true">
				<!-- Dummy element to force sidebar to shrink to fit close icon -->
			</div>
		</div>
	</div>

	<!-- SIDEBAR DESKTOP -->
	<div class="hidden lg:flex lg:flex-shrink-0 h-screen overflow-y-auto">
		<div class="flex flex-col print:hidden transition-all" :class="[desktopSidebarCollapsed ? 'w-12' : 'w-64']">

			<div class="flex flex-col flex-grow border-r border-base-200 pt-3 pb-1 bg-base-100">

				<div class="flex gap-1 flex-shrink-0" :class="[desktopSidebarCollapsed ? 'flex-col items-end px-1' : 'flex-row justify-between']">
					<a href="/">
						<img v-show="!desktopSidebarCollapsed" src="/smartsymbols-logo.png" class="ml-4 h-10 w-auto" alt="Smart Symbols home"/>
						<img v-show="desktopSidebarCollapsed" src="/app-icons/default/apple-touch-icon_120.png" class="w-8 h-8 rounded" alt="Smart Symbols home"/>
					</a>
					<button type="button" class="btn btn-sm btn-ghost text-base-content-light px-2" @click="toggleDesktopSidebar">
						<ChevronDoubleLeftIcon class="w-4 h-4" :class="[desktopSidebarCollapsed ? 'rotate-180' : '']" />
					</button>
				</div>

				<div class="flex-grow flex flex-col" :class="[desktopSidebarCollapsed? '' : 'mt-5']">
					<nav class="flex-1 space-y-1" :class="desktopSidebarCollapsed? 'px-1' : 'px-1'">
						<!-- Don't forget the drawer instance -->
						<SideBarMenu :classrooms="currentOrganisation?.classrooms" :iconsOnly="desktopSidebarCollapsed" />
					</nav>
				</div>

				<div class="px-1 mt-4 flex flex-col" :class="[desktopSidebarCollapsed ? 'items-end' : 'items-stretch']">
					<SideBarMenuLink :to="'/support'">
						<ChatBubbleOvalLeftEllipsisIcon class="w6 h-6"/>
						<span :class="[desktopSidebarCollapsed? 'hidden' : 'ml-3']">{{ $t('Report a problem') }}</span>
					</SideBarMenuLink>
				</div>

				<div class="px-2 text-gray-400 text-xs mt-1" :title="'Built on ' + buildDate">
					v{{ version }}
				</div>

			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Bars3BottomLeftIcon, XMarkIcon, ChatBubbleOvalLeftEllipsisIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/24/outline'
import SideBarMenu from './SideBarMenu.vue'
import { useUsersStore } from "@/stores/Users.store";
import { mapState } from "pinia";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import AppConfig from "@/config/app.config";
import SideBarMenuLink from "@/components/layout/SideBarMenuLink.vue";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";

export default {
	name: "TheSidebar",
	components: {
		Bars3BottomLeftIcon,
		XMarkIcon, ChatBubbleOvalLeftEllipsisIcon, ChevronDoubleLeftIcon,
		SideBarMenu, SideBarMenuLink,
	},
	computed: {
		...mapState(useUsersStore, ['me']),
		...mapState(useOrganisationsStore, ['currentOrganisation']),
	},

	data() {
		const date = AppConfig.BUILD_TIMESTAMP;
		return {
			showSideBarDrawer: false,
			version: AppConfig.VERSION,
			buildDate: date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes(),
			desktopSidebarCollapsed: false,
		}
	},

	beforeMount() {
		this.desktopSidebarCollapsed = SmaSyLocalStorage.getUserItem(this.me.id, 'desktop_sidebar_collapsed') ?? false;
	},

	methods: {
		toggleSideBarDrawer() {
			this.showSideBarDrawer = !this.showSideBarDrawer;
		},

		toggleDesktopSidebar() {
			this.desktopSidebarCollapsed = !this.desktopSidebarCollapsed;
			SmaSyLocalStorage.setUserItem(this.me.id, 'desktop_sidebar_collapsed', this.desktopSidebarCollapsed);

			// if current route is 'agenda', then reload the page to force the agenda to re-render as it doesn't fully
			// behave responsive to the sidebar width change. View component reload would be more subtle, but would also
			// require communication between unrelated components, so we just reload the page for now)
			if (this.$route.name === 'classroom.agenda') {
				console.log('redirecting to ...');
				setTimeout(() => {
					window.location.reload();
				}, 200);
			}
		},
	}
}
</script>
