<template>
    <template v-if="initialized">

		<router-view></router-view>

    </template>

	<template v-if="errorMessage">
		<p class="alert alert-error">{{ errorMessage }}</p>
	</template>

    <LoadingScreen v-show="loading" />
    <!-- vuejs-confirm-dialog wrapper element -->
	<DialogsWrapper />

</template>


<script lang="ts">

import {useUsersStore} from "@/stores/Users.store";
import {mapActions, mapState, mapStores} from "pinia";
import {usePreferencesStore} from "@/stores/Preferences.store";
import LoadingScreen from "./components/ui/LoadingScreen.vue";
import { useOrganisationsStore } from "@/stores/Organisations.store";

export default {
	components: {
		LoadingScreen
	},
	data() {
		return {
			initialized: false,
			loading: true,
			errorMessage: null
		};
	},

	computed: {
		...mapStores(usePreferencesStore),
		...mapState(useUsersStore, [ 'me' ]),
		...mapState(useOrganisationsStore, [ 'currentOrganisation' ]),

		classrooms() {
			return this.currentOrganisation.classrooms;
		}
	},

	methods: {
		...mapActions(useUsersStore, [ 'loadMe' ]),
	},

	async mounted() {
		// Load user
		this.initialized = false;
		this.loading = true;

		try {
			/*
			if (this.me.classrooms.length === 0) {
				this.loading = false;
				this.errorMessage = this.$t('Your account is not associated to any classrooms.')
			} else {
				this.initialized = true;
				this.loading = false;
			}*/

			this.initialized = true;
			this.loading = false;

		} catch (e) {
			if (e.response && e.response.status === 401) {
				this.loading = false;
				this.errorMessage = this.$t('You are not logged in. Please login to continue.');
			} else if (e.response && e.response.status === 403) {
				this.loading = false;
				this.errorMessage = this.$t('You are not logged in: ' + e.response.data.message);
			} else {
				this.loading = false;
				this.errorMessage = this.$t('An error occurred: ') + e.message;
			}
		}
	}
}
</script>
