import { Comparable } from "@/interfaces/Comparable.interface";
import { i18n } from '@/i18n';

class UnsavedChangesUtil
{
	private observedModelOriginal: Comparable;
	private observedModelEdited: Comparable;

	private manualOverride: boolean = false;

	public observeModel(originalModel: any, model: any) {
		this.observedModelOriginal = originalModel;
		this.observedModelEdited = model;
	}

	public unobserveModel() {
		this.observedModelOriginal = null;
		this.observedModelEdited = null;
	}

	public markUnsavedChanges() {
		this.manualOverride = true;
	}

	public markSavedChanges() {
		this.manualOverride = false;
	}

	public exists() {
		if (this.manualOverride) {
			return true;
		}

		if (this.observedModelEdited && this.observedModelOriginal) {
			return !this.observedModelOriginal.equals(this.observedModelEdited);
		}

		return false;
	}

	public nonexistentOrIgnorable() {
		if (!this.exists()) {
			return true;
		}

		const text = i18n.global.t('You have unsaved changes. Leave anyway?');
		const answer = window.confirm(text)
		if (!answer) {
			return false
		} else {
			return true;
		}
	}

}

const UnsavedChanges = new UnsavedChangesUtil();

export {
	UnsavedChanges
};

// Also register this in the window
window.onbeforeunload = function(e) {
	if (UnsavedChanges.exists()) {
		// Cancel the event
		e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
		// Chrome requires returnValue to be set
		e.returnValue = '';
	}
}
