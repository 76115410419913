import {Icon} from "./Icon.model";
import {Symbol} from "./Symbol.model";
import {AbstractHasSymbol} from "@/models/AbstractHasSymbol.model";

export class Activity extends AbstractHasSymbol
{
	public id ?: string;
	public name ?: string;
	public color ?: string;

	public seats ?: number;
	public classroomId: string = '';

	static mapFromServer(data: any): Activity {
		return (new Activity()).setFromServerData(data);
	}

	setFromServerData(data: any) {

		this.id = data.id;
		this.name = data.name;

		if (data.icon) {
			this.icon = Icon.mapFromServer(data.icon);
		}

		if (data.symbol) {
			this.symbol = Symbol.mapFromServer(data.symbol);
		}

		this.seats = data.seats;

		return this;
	}

	getIcon(): Icon | Symbol | null {
		if (this.icon) {
			return this.icon;
		}

		return this.symbol;
	}

	getServerData(): any {
		return {
			id: this.id,
			name: this.name,
			seats: this.seats,
			icon: this.icon?.getServerData(),
			symbol: this.symbol?.getServerData(),
			classroom: {
				id: this.classroomId
			}
		}
	}

	clone(): Activity {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}
}
