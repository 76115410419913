<template>
	<div class="relative">
		<slot :show="open" :setMessage="setMessage"></slot>

		<Dropdown v-bind="$attrs" v-if="show" :showOnMount="true" @close="handleCloseDueToLostFocus">
			<div class="flex justify-between gap-2 items-center p-4 rounded-md bg-base shadow-lg ring-1 ring-neutral ring-opacity-5 focus:outline-none">
				<div class="normal-case">
					{{ messageWithFallback }}
				</div>

				<div class="flex gap-2">
					<button type="button" class="btn" :class="buttonClasses" @click="_confirm">{{ confirmLabelWithFallback }}</button>
					<button type="button" class="btn btn-ghost text-neutral" @click="_cancel">{{ cancelLabelWithFallback }}</button>
				</div>
			</div>
		</Dropdown>
	</div>
</template>

<script lang="ts">
import Dropdown from './Dropdown.v2.vue';

export default {
	inheritAttrs: false,

    props: {
        confirmLabel: {
            type: String,
            default: null,
        },
        cancelLabel: {
            type: String,
            default: null,
        },
        confirmStyle: {
            type: String,
            default: 'danger',
        },
		message: {
			type: String,
			default: null,
		}
    },

	components: {
		Dropdown,
	},

	data() {
		return {
			show: false,
			resolvePromise: null,
			rejectPromise: null,
			resolved: false,
			altMessage: null,
		};
	},

    computed: {
        buttonClasses() {
            switch(this.confirmStyle) {
                case 'danger':
                    return 'btn-danger';
                default:
                    return '';
            }
        },
		confirmLabelWithFallback() {
			return this.confirmLabel ?? this.$t('Yes');
		},
		cancelLabelWithFallback() {
			return this.cancelLabel ?? this.$t('No');
		},
		messageWithFallback() {
			return this.altMessage ?? this.message ?? this.$t('Are you sure?');
		},
    },

    methods: {

		async open() {
			this.show = true;
			this.resolved = false;

			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},

        _confirm() {
			this.show = false;
			this.resolvePromise(true);
			this.resolved = true;
        },

        _cancel() {
			this.show = false;
			this.resolvePromise(false);
			this.resolved = true;
        },

		handleCloseDueToLostFocus() {
			// Clicking any of the buttons closes the dropdown, triggering the 'click outside' event of the dropdown
			// The order of triggered events isn't really a problem, but it's cleaner to only resolve the promise once
			if(!this.resolved) {
				this._cancel();
			}
		},

		setMessage(message) {
			this.altMessage = message;
		}
    }
}
</script>
