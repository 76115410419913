<template>
    <NotificationGroup>
        <div aria-live="assertive" class="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-4">
            <div class="flex w-full flex-col items-center space-y-4 sm:items-end">

                <Notification
                    v-slot="{ notifications }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-8"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                <div v-for="n in notifications" :key="n.id" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded bg-base-100 shadow">
                    <div v-if="n.type === 'success'" class="notif notif-success">
                        <div class="flex items-start">
                            <div class="flex-shrink-0 notif-icon">
                                <CheckCircleIcon class="w-6 h-6" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="notif-title nb-1">{{ n.title }}</p>
                                <p class="text-sm">{{ n.text }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="n.type === 'error'" class="notif notif-error">
                        <div class="flex items-start">
                            <div class="flex-shrink-0 notif-icon">
                                <ExclamationCircleIcon class="w-6 h-6" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="notif-title nb-1">{{ n.title }}</p>
                                <p class="text-sm">{{ n.text }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="n.type === 'warning'" class="notif notif-warning">
                        <div class="flex items-start">
                            <div class="flex-shrink-0 notif-icon">
                                <CheckCircleIcon class="w-6 h-6" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="notif-title nb-1">{{ n.title }}</p>
                                <p class="text-sm">{{ n.text }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="notif">
                        <div class="flex items-start">
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="notif-title nb-1">{{ n.title }}</p>
                                <p class="text-sm">{{ n.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </Notification>

            </div>
        </div>
    </NotificationGroup>
</template>

<style scoped>
.notif {
    padding: 1rem;
    border-left-width: 4px;
    z-index: 1000;
}
.notif-success {
    background-color: #f0fdfa;  /* teal-50 */
    border-color: #14b8a6;      /* teal-500 */
    color: #0f766e;             /* teal-700 */
}
.notif-success .notif-icon {
    color: #14b8a6;             /* teal-500 */
}
.notif-success .notif-title {
    color: #134e4a;             /* teal-900 */
    font-weight: bold;
}
.notif-error {
    background-color: #fef2f2;  /* red-50 */
    border-color: #ef4444;      /* red-500 */
    color: #b91c1c;             /* red-700 */
}
.notif-error .notif-icon {
    color: #ef4444;             /* red-500 */
}
.notif-error .notif-title {
    color: #7f1d1d;             /* red-900 */
    font-weight: bold;
}
.notif-warning {
    background-color: #fefce8;  /* yellow-50 */
    border-color: #eab308;      /* yellow-500 */
    color: #a16207;             /* yellow-700 */
}
.notif-warning .notif-icon {
    color: #eab308;             /* yellow-500 */
}
.notif-warning .notif-title {
    color: #713f12;             /* yellow-900 */
    font-weight: bold;
}
</style>

<script type="ts">
import { NotificationGroup } from 'notiwind';
import { CheckCircleIcon, ExclamationCircleIcon, NoSymbolIcon } from '@heroicons/vue/24/outline';

export default {
    components: { CheckCircleIcon, ExclamationCircleIcon, NoSymbolIcon },
};
</script>