export class GenericApiError {

	public message: string;

	constructor(
		message: string
	) {
		if (Array.isArray(message)) {
			message = message.join("\n");
		}

		this.message = message;
	}

	toString() {
		return this.message;
	}

}
