// API
import axios, { InternalAxiosRequestConfig } from "axios";
import config from "@/config/app.config";
import {auth} from "@/auth";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { notify } from "notiwind"

const baseURL = config.API_URL;
let _accessToken = null;

// Look for organisation id
// @ts-ignore
if (typeof(window.ORGANISATION_ID) !== 'undefined') {
	// @ts-ignore
	config.ORG_ID = window.ORGANISATION_ID;
} else if (localStorage.getItem('organisation_id')) {
	config.ORG_ID = localStorage.getItem('organisation_id');
} else {
	config.ORG_ID = null;
}

const api = axios.create({
	baseURL: config.API_URL,
	withCredentials: true,
});

api.interceptors.request.use(addRequestHeaders);
api.interceptors.response.use((response) => response, handleAxiosError);

const orgApi = axios.create({
	baseURL: config.API_URL + 'org/' + config.ORG_ID + '/',
	withCredentials: true,
});

orgApi.interceptors.request.use(addRequestHeaders);
orgApi.interceptors.response.use((response) => response, handleAxiosError);

let lastNetworkNotification = null;
function handleAxiosError(error: any) {

	// Authentication error
	if (error.response && error.response.status === 401) {
		handleAuthenticationError();
		window.location.href = '/';

		throw new Error(error);
	}

	if (error.response && error.response.status === 402) {
		// Current organisation is not active
		localStorage.removeItem('organisation_id');
		window.location.href = '/';

		throw new Error(error);
	}

	// Validation errors? Show a notification, but not for each error
	const apiError = ApiErrors.fromAxiosException(error);
	if (error.code === 'ERR_NETWORK') {
		if (
			!lastNetworkNotification ||
			(new Date()).getTime() - lastNetworkNotification.getTime() > 500
		) {
			lastNetworkNotification = new Date();
			notify({
				text: apiError.toString(),
				type: 'error'
			})
		}
	}

	throw apiError;

}

function setOrganisationId(organisationId: string) {
	orgApi.defaults.baseURL = config.API_URL + 'org/' + organisationId + '/';
}

function handleAuthenticationError() {
	auth.clearAccessToken();
}

function setAccessToken(accessToken: string) {

	_accessToken = accessToken;
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

	if (api) {
		api.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
	}

	if (orgApi) {
		orgApi.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
	}


}

function hasAccessToken() {
	return _accessToken !== null;
}

function addRequestHeaders(reqConfig: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
	reqConfig.headers['X-App-Version'] = config.VERSION;
	reqConfig.headers['X-App-Page'] = window.location.pathname;
	reqConfig.headers['X-Client-Date'] = (new Date()).toISOString();

	try {
		reqConfig.headers['X-Client-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch (e) {
		return;
	}

	return reqConfig;
}

// Look for csfr token
let token: any = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	//axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else if (localStorage.getItem('access_token')) {
	// We must fetch an access token
	setAccessToken(localStorage.getItem('access_token'));
	//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
}

// Listen for (brand new) access token
auth.onAccessToken((accessToken: string) => {
	setAccessToken(accessToken);
});

// Try to get the platform details
try {
	// @ts-ignore
	navigator.userAgentData.getHighEntropyValues([
		"architecture",
		"platform",
		"platformVersion",
		"model",
		"bitness",
		"uaFullVersion",
		"formFactor"
	])
		.then((details) => {

			[api, orgApi].forEach((api) => {

				//console.log(details);
				/*
				api.defaults.headers.common['X-Device-Architecture'] = details.architecture;
				api.defaults.headers.common['X-Device-Platform'] = details.platform;
				api.defaults.headers.common['X-Device-Platform-Version'] = details.platformVersion;
				api.defaults.headers.common['X-Device-Model'] = details.model;
				api.defaults.headers.common['X-Device-Bitness'] = details.bitness;
				api.defaults.headers.common['X-Device-UA-Full-Version'] = details.uaFullVersion;
				 */

				api.defaults.headers.common['X-Device-Family'] = details.platform;

			});
		});
} catch(e) {
	// Do nothing.
}

export { api, baseURL, orgApi, setOrganisationId, setAccessToken, hasAccessToken };
