<template>
	<Modal @requestUnmount="hide">

		<template #header>&nbsp;</template>

		<template #default="modalActions">

			<div class="alert alert-error my-2" v-if="iconsStore.errorMessage?.genericError">
				{{ $t(iconsStore.errorMessage?.genericError.message) }}
			</div>

			<form @submit.prevent="save">
				<input type="file" accept="image/*" name="image" id="file" @change="loadFile" style="display: none;"
					   ref="uploaded-file"/>

				<div class="flex justify-center py-2 max-h-[60vh] max-w-full overflow-hidden mb-6">

					<!-- Overview -->
					<template v-if="panel === 'view'">
						<img :src="imgSrc" class="rounded"/>
					</template>

					<!-- Upload form -->
					<div v-if="panel === 'upload'" class="flex flex-col gap-2 items-center">
						<p>{{ $t('Upload a new image') }}</p>

						<p class="q-ma-lg">
							<label for="file" class="btn btn-primary" style="cursor: pointer;">
								{{ $t('Select an image') }}
							</label>
						</p>
					</div>

					<!-- Crop the (new) image -->
					<template v-if="panel === 'crop'">
						<p v-show="!this.uploadedImage">
							{{ $t('Please wait, uploading') }}
						</p>

						<cropper ref="cropper" class="cropper"
							:src="uploadedImage"
							:canvas="{
								minHeight: 0,
								minWidth: 0,
								maxHeight: 240,
								maxWidth: 240,
							}"
							:stencil-component="$options.components.RectangleStencil"
							:stencil-props="{
								aspectRatio: 10/10
							}"
							backgroundClass="cropper_background"
							boundariesClass="cropper_boundaries"
						/>
					</template>

				</div>

				<div class="modal-footer flex"
					 :class="[panel === 'view' && editable? 'justify-between' : 'justify-end']"
					 v-if="canEdit"
				>
					<Dropdown v-if="panel === 'view' && editable" position="top-start">

						<template #trigger>
							<button type="button" class="btn"
									:class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5"/>
							</button>
						</template>
						<template #content="dialogActions">
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')"
												   confirm-style="danger" class="w-80" @cancel="dialogActions.close"
												   @confirm="confirmRemove(dialogActions)">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown>

					<div class="flex justify-end gap-1">
						<button type="button" v-if="panel === 'view' && editable" class="btn btn-ghost"
								:class="[deleting? 'hidden' : '']" @click="handleReplaceRequest">
							{{ $t('Replace icon') }}
						</button>
						<button type="button" v-if="(panel === 'upload' || panel === 'crop') && editable"
								class="btn btn-ghost" :class="[saving || deleting? 'hidden' : '']"
								@click="modalActions.hide">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" v-if="panel === 'crop' && editable" class="btn btn-primary">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>

<style scoped>
>>> .cropper_background {
	background: white;
}

>>> .cropper_boundaries {
	border-style: dashed;
	border-color: rgb(252, 212, 79);
}

>>> .vue-advanced-cropper__foreground {
	background: white;
}

>>> .vue-simple-line {
	border-color: rgb(252, 212, 79);
}

>>> .vue-simple-handler {
	background: rgb(252, 212, 79);
}

>>> .cropper {
	border: 1px solid gray;
}

</style>

<script lang="ts">

import 'vue-advanced-cropper/dist/style.css';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import { useIconsStore } from "@/stores/Icons.store";
import { mapStores } from "pinia"
import { ApiErrors } from "@/stores/errors/ApiErrors";
import Modal from "@/components/ui/Modal.vue";

import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import { TrashIcon } from '@heroicons/vue/24/outline';
import { Icon } from '@/models/Icon.model';
import { useUsersStore } from "@/stores/Users.store";

export default {
	components: {
		Cropper,
		CircleStencil,
		Modal,
		Dropdown, DropdownDialogConfirm,
		TrashIcon
	},

	props: {
		icon: {
			type: Icon,
			default: null
		},
		library: null,
		group: null,
		editable: false
	},

	data() {
		return {
			panel: 'view',
			uploadedImage: null,
			file: '',
			name: '',
			imgSrc: null,
			saving: false,
			deleting: false,
		}
	},

	computed: {
		...mapStores(useIconsStore),
		...mapStores(useUsersStore),

		canEdit() {
			if (!this.icon) {
				return true;
			}

			if (this.icon.library.classroomId) {
				return this.usersStore.me.hasTeacherPermissions();
			}

			// global icon library requires admin permissions
			return this.usersStore.me.hasAdminPermissions();
		}
	},

	emits: ['requestClose'],

	mounted() {
		// If an icon is set, show that icon. Otherwise, show the upload form.
		if (this.icon) {
			this.panel = 'view';
			this.imgSrc = this.icon.url;
		} else {
			this.panel = 'upload';
		}
		this.iconsStore.clearErrorMessage();
	},

	methods: {

		handleReplaceRequest() {
			this.panel = 'upload';
			this.iconsStore.clearErrorMessage();
		},

		/**
		 * @param event
		 */
		loadFile(event) {

			this.uploadedImage = null;
			this.panel = 'crop';

			// this takes a few milliseconds, so delay this to allow the 'loading' message to show.
			setTimeout(() => {
				this.uploadedImage = URL.createObjectURL(event.target.files[0]);
				this.name = event.target.files[0].name
			}, 1);

		},

		/**
		 * Hide modal.
		 */
		hide() {
			this.$emit('requestClose');
		},

		async save() {
			this.saving = true;

			const {
				coordinates,
				canvas
			} = this.$refs.cropper.getResult();

			this.coordinates = coordinates;
			this.imgSrc = canvas.toDataURL();

			try {
				if (this.icon) {
					await this.iconsStore.replaceIcon(this.icon, this.library, this.group, this.imgSrc, this.name);
				} else {
					await this.iconsStore.uploadIcon(this.library, this.group, this.imgSrc, this.name);
				}
				this.$notify({
					// title: "",
					text: this.$t('The icon has been saved'),
					type: "success",   // optional styling: info, success, error
				});
				this.hide()
			} catch (e) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
				this.saving = false;
				this.panel = 'crop';
			}
		},

		cancel() {
			// we just need to hide the dialog
			this.hide()
		},

		cancelUpload() {
			if (this.icon) {
				this.panel = 'view';
			} else {
				this.hide();
			}
		},

		selectFile() {
			this.$refs["uploaded-file"].click();
		},

		async confirmRemove(dialogActions) {
			this.deleting = true;

			dialogActions.close();

			try {
				await this.iconsStore.removeIcon(this.icon);
				this.$notify({
					// title: "",
					text: this.$t('The icon has been removed'),
					type: "success",
				});
				this.hide();
			} catch (e) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
				this.deleting = false;
			}
		},
	}
}
</script>
