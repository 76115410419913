import { Goal } from "@/models/Goal.model";
import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import { ZILL_API_URL, ZILL_CURRICULUM_SOURCE_NAME, COMPOSED_TITLE_PATH_SEPARATOR } from '@/goals/zill/settings';


export class ZillLoader {

	/**
	 * @private
	 */
	private apiClient: AxiosInstance;

	/**
	 * @private
	 */
	private goalPromiseMap: Map<string, Promise<Goal>> = new Map<string, Promise<Goal>>();

	public constructor() {

		this.apiClient = axios.create({
			baseURL: ZILL_API_URL,
		});
		axiosRetry(this.apiClient, {
			retries: 1,
			retryDelay: axiosRetry.exponentialDelay,
			onRetry: (count, err) => { console.log('retry attempt' + count, err) }
		});

	}


	async load(urls: string[]) {

		return new Promise<Goal[][]>((resolve, reject) => {

			const promises = urls.map((url) => {
				const uuid = this.findIdInUrl(url);
				return this.fetchRelevantGoals(uuid);
			});

			Promise.all(promises).then((goals) => {
				resolve(goals);
			})
			.catch((error) => {
				reject(error);
			});

		});

	}

	getGoalAPIData(uuid: string) {

		if (this.goalPromiseMap.has(uuid)) {
			return this.goalPromiseMap.get(uuid);
		}

		const promise = this.fetchFromAPI(uuid);
		this.goalPromiseMap.set(uuid, promise);

		return promise;

	}

	findIdInUrl(url: string): string {

		const parts = url.split('/');
		return parts[parts.length - 1];

	}

	async fetchRelevantGoals(uuid: string): Promise<Goal[]> {

		const requestedGoalData = await this.fetchFromAPI(uuid);

		if(!requestedGoalData.data.title && requestedGoalData.data.description) {
			requestedGoalData.data.title = requestedGoalData.data.description;
		}
		requestedGoalData.data.title = requestedGoalData.data.title;

		// In case of a so-called 'sub goal' (a smaller goal that contributes towards a generic goal), we also want
		// to include that generic parent goal in the list of relevant goals
		const relevantGoals: Goal[] = [];

		let parentPathSegments: string[] = [];
		let titleSegments = [];
		let color: string = null;

		// Travel through all parents
		let parent = requestedGoalData.parent;
		let parentGoalData = null;

		while (parent !== null) {

			// http://zill-developers.katholiekonderwijs.vlaanderen.s3-website-eu-west-1.amazonaws.com/content/api/curriculum_zill.html
			switch (parent.type) {
				case 'CURRICULUM_ZILL_DEVELOPMENT_FIELD':
					parentPathSegments.unshift(parent.data.identifiers[0]);
					if (!color && parent.data.color) {
						color = parent.data.color;
					}
					break;

				case 'CURRICULUM_ZILL_DEVELOPMENT_THEME':
					parentPathSegments.unshift(parent.data.identifiers[0]);
					break;

				case 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT':
					if(parent.data.title) {
						titleSegments.unshift(parent.data.title);
					}
					break;

				case 'CURRICULUM_ZILL_GENERIC_GOAL':
					parentGoalData = parent;
					break;
			}

			parent = parent.parent;
		}

		if (parentGoalData !== null) {	// dealing with a subgoal + parent goal
			const pathId = parentGoalData.data.identifiers[0];
			relevantGoals.push(this.transformToGoal(parentGoalData.data, parentPathSegments, pathId, color));

			if (requestedGoalData.data.title) {
				// for a subgoal of multiple levels deep, we compose a title from all parent titles
				if (titleSegments.length > 0) {
					requestedGoalData.data.title = titleSegments.join(COMPOSED_TITLE_PATH_SEPARATOR) + COMPOSED_TITLE_PATH_SEPARATOR + requestedGoalData.data.title;
				}
				parentPathSegments.push(parentGoalData.data.identifiers[0]);
				relevantGoals.push(this.transformToGoal(requestedGoalData.data, parentPathSegments, null, color));
			}
		} else {
			const pathId = requestedGoalData.data.identifiers[0];

			if (requestedGoalData.data.title) {
				relevantGoals.push(this.transformToGoal(requestedGoalData.data, parentPathSegments, pathId, color));
			}
		}

		return relevantGoals;
	}

	transformToGoal(data: any, parentPathSegments: string[], pathId: string = null, color: string = null): Goal {

		const goal = new Goal();
		goal.source = ZILL_CURRICULUM_SOURCE_NAME;
		goal.sourceId = data.key;
		goal.sourceParentPath = parentPathSegments.join('.');
		goal.sourcePathId = pathId;
		goal.title = this.sanitizeContent(data.title);

		goal.color = color;

		if (data.description) {
			goal.info = this.sanitizeContent(data.description);
		}

		return goal;
	}

	sanitizeContent(content: string): string {
		return content
					.replace(/(\s*)<br>/gi, '\n')
					// for uniformity, converting bullet points to markdown notation
					.replace(/•(\s*)/g, '- ')
					// replace list item tags with markdown notation
					.replace(/<li>(.*?)<\/li>/gi, (match, p1) => `\n- ${p1}`)
					// strip a number of other tags that can be expected
					.replace(/(\s*)<\/?(a|script|ul|ol)[^>]*>(\s*)/gi, " ");
	}

	async fetchFromAPI(uuid: string): Promise<any> {

		const response = await this.apiClient.get(`/content/${uuid}`);

		const type = response.data.type;

		// Look for the closest parent goal
		const relationsFrom = response.data.$$relationsFrom;

		// We assume each node can only have 1 parent.
		const goal = {
			type: type,
			data: response.data,
			parent: null
		};

		let promise = null;
		for (let i = 0; i < relationsFrom.length; i++) {
			const relation = relationsFrom[i];

			const type = relation.$$expanded.relationtype;
			if (type === 'IS_PART_OF') {
				const toId = this.findIdInUrl(relation.$$expanded.to.href);
				promise =
					this.getGoalAPIData(toId)
						.then((parentGoal) => {
							goal.parent = parentGoal;
						})
				break;
			}
		}

		await promise;

		return goal;
	}

}
