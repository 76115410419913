<template>
	<Teleport to="head">
		<link rel="apple-touch-icon" sizes="180x180" href="/app-icons/activity-board/apple-touch-icon.png" />
		<link rel="icon" href="/app-icons/activity-board/favicon.ico" sizes="48x48">
		<link rel="icon" href="/app-icons/activity-board/favicon.svg" sizes="any" type="image/svg+xml">
		<meta name="theme-color" content="#ffffff">
		<link rel="manifest" href="activity-board-manifest.json" />
	</Teleport>

	<!-- <div class="h-screen flex overflow-hidden"> -->
		<slot />
	<!-- </div> -->

	<TheNotifier />
</template>

<script lang="ts">
import TheNotifier from "@/components/TheNotifier.vue";

export default {
	name: "TheBoardLayout",

	components: {
		TheNotifier,
	}
}
</script>
