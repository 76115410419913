<template>
	<div class="flex flex-col">
		<label v-if="label" class="block input-label">{{ label }}</label>
		<select v-bind="$attrs" class="select select-bordered w-full" :disabled="disabled" @input="onChange">
			<option v-for="option in options"
				:value="option.value"
				:disabled="option.disabled"
				:selected="modelValue === option.value || (modelValue === null && option.value === null)"
			>{{ option.label }}</option>
		</select>

		<span v-if="validationError" class="text-red-600 text-xs">
			{{ validationError.toString() }}
		</span>
	</div>
</template>


<script lang="ts">
import {FieldValidationError} from "@/stores/errors/FieldValidationError";

export default {

	inheritAttrs: false,
	emits: ['update:modelValue'],

	props: {
		label: {
			default: 'Label'
		},
		disabled: {
			default: false
		},
		modelValue: {

		},
		options: {
			default: []
		},
		readonly: {
			default: false
		},
		validationError: { type: FieldValidationError }
	},

	methods: {
		onChange(event: Event) {
			const target = event.target as HTMLSelectElement;
			this.$emit('update:modelValue', this.options[target.selectedIndex].value);
		}
	}
}
</script>
