<template>

	<Modal @requestUnmount="hide" :fullscreen="false" width="2xl">

		<template #header>
			<div class="font-semibold text-xl mb-4" v-text="inUpdateMode? $t('Edit classroom') : $t('Add classroom')"></div>
		</template>

		<template #default="modalActions">

			<form @submit.prevent="save" class="flex-grow flex flex-col overflow-y-auto overflow-x-hidden">

				<p v-if="classroomsStore.errorMessage?.genericError" class="text-red-600 text-xs">
					{{ classroomsStore.errorMessage?.genericError.toString() }}
				</p>

				<div class="flex-grow overflow-auto grid grid-cols-6 gap-10 row mb-6">

					<div class="col-span-6 sm:col-span-3">
						<sms-input type="text" autofocus v-model="editableModel.name" :label="$t('Name')"
											 :validationError="classroomsStore.errorMessage?.fields.name"></sms-input>
					</div>

					<div class="col-span-6 sm:col-span-3">
						<!-- {{ $t('Owner') }} for automated locale string extraction only -->
						<label class="label border-b pb-0 mb-2">
							<span class="label-text capitalize">{{ $tc('Teacher', 2) }}</span>
						</label>
						<div class="pl-2 max-h-64 h-64 overflow-auto">
							<div v-for="user in allTeachers" :key="user.id"
									 class="flex items-center gap-2 text-sm py-2 rounded hover:bg-base-100">
								<input type="checkbox" :id="'user' + user.id" :value="user.id" v-model="editableModel.teachers"
											 class="checkbox checkbox-sm"/>
								<label :for="'user' + user.id" class="hover:cursor-pointer">
									{{ user.getNameIdentifier() }}
									<span v-if="!user.active" class="badge badge-error badge-sm ml-2">{{ $tc('Inactive') }}</span>
								</label>
							</div>
							<div v-for="user in allAdmins" :key="user.id" class="flex items-center gap-2 text-sm py-2">
								<input type="checkbox" checked disabled class="checkbox checkbox-sm"/>
								<label class="hover:cursor-not-allowed">
									{{ user.getNameIdentifier() }}
									<span class="badge badge-success badge-sm ml-2">{{ $tc('Admin', 1) }}</span>
								</label>
							</div>
						</div>
					</div>

				</div>

				<div class="modal-footer flex" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
					<Dropdown v-if="inUpdateMode" position="top-start">
						<template #trigger>
							<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5"/>
							</button>
						</template>
						<template #content="dialogActions">
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger"
																		 class="w-80" @cancel="dialogActions.close" @confirm="confirmRemove(dialogActions)">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown>

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="modalActions.hide"
										:class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>
			</form>

		</template>

	</Modal>
</template>


<script lang="ts">
import {mapStores} from 'pinia';

import {useClassroomsStore} from "@/stores/Classrooms.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";

import {Classroom} from "@/models/Classroom.model";

import Modal from "@/components/ui/Modal.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import {TrashIcon} from '@heroicons/vue/24/outline';
import {useUsersStore} from "@/stores/Users.store";

export default {
	props: {
		classroom: {
			type: Classroom,
			required: false,
		},
	},
	components: {
		Modal,
		Dropdown,
		DropdownDialogConfirm,
		TrashIcon,
	},
	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
		}
	},
	computed: {
		...mapStores(useClassroomsStore, useUsersStore),

		inUpdateMode() {
			return (this.classroom && this.classroom.id) ? true : false;
		},

		allAdmins() {
			return [
				...this.usersStore.admins
			].sort((a, b) => a.getNameIdentifier().localeCompare(b.getNameIdentifier()));
		},

		allTeachers() {
			return [
				...this.usersStore.teachers
			].sort((a, b) => a.getNameIdentifier().localeCompare(b.getNameIdentifier()));
		}
	},

	methods: {

		hide() {
			this.classroomsStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		async confirmRemove(dialogActions) {

			dialogActions.close();

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.classroomsStore.delete(this.originalModel);
				this.deleting = false;

				this.hide();

				this.$notify({
					text: this.$t('The classroom has been removed'),
					type: "success",
				});

			} catch (e: any) {
				this.deleting = false;
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		async save(event) {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.classroomsStore.save(this.editableModel);

				this.saving = false;
				this.hide();

				this.$notify({
					text: this.$t('The classroom has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				throw e;
			}
		}
	},

	beforeMount() {
		if (this.classroom && this.classroom.id) {
			this.originalModel = this.classroom;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.classroom.clone();

		} else {
			this.editableModel = this.classroomsStore.new();
		}
	},
}
</script>
