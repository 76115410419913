<template>
    <div class="divide-y divide-base-200 text-sm rounded-md bg-base shadow-lg ring-1 ring-neutral ring-opacity-5">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>

<script lang="ts">
export default {}
</script>