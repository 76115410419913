<template>

<Drawer :title="panelTitle" initialWidth="xl" :showDefaultCloseButton="false" @requestUnmount="close">

	<template #default="{ hide }">

		<form class="grid grid-cols-1 gap-6 pt-8">

			<div class="pb-20 px-6">

				<div v-if="selectedItem.activity" class="grid grid-cols-2 gap-6 mb-8">
					<div class="flex flex-col">
						<label class="block text-xs font-bold">{{ $tc('Icon on activity board', 2) }}</label>
						<div class="flex items-center gap-2 hover:cursor-pointer" @click="showIconSelector = true">
							<activity-symbol :activity="selectedItem" class="w-16" />
							<button type="button" class="btn btn-sm btn-circle">
								<PencilIcon class="h-4 w-4"></PencilIcon>
							</button>
						</div>
					</div>

					<div class="flex flex-col">
						<label class="text-xs font-bold">{{ $tc('Seat', 2) }}</label>
						<div class="mt-1">
							<NumberInput v-model="selectedItem.seats" :stepValue="1" class="w-14"/>
						</div>
					</div>
				</div>


				{{ $t('Select the pupils who can participate in this activity') }}

				<div class="mt-2">

					<div class="border-b mb-2 pb-2 flex gap-3 items-center">
						<input type="checkbox"
									id="select-all"
									class="checkbox checkbox-sm"
									@change.prevent="toggleSelectAll"
									ref="checkAll"
						/>
						<label for="select-all">{{ $t('Select all') }}</label>
					</div>

					<ul>
						<li v-for="pupil in pupilsStore.pupils" :key="pupil.id" class="mb-2 flex gap-3 items-center">
							<input
									type="checkbox"
									:id="'pupil-' + pupil.id"
									class="checkbox checkbox-sm checkbox-primary"
									:checked="selectedItem.isPupilSelected(pupil)"
									@change.prevent="event => this.togglePupil(pupil, event.target.checked)"
							/>
							<label :for="'pupil-' + pupil.id">{{ pupil.getFullName() }}</label>
						</li>
					</ul>
				</div>

			</div>

			<div class="absolute bottom-0 w-full bg-white/50 backdrop-blur-sm z-10 p-4 flex mt-6 border-t border-base-200 justify-end">
				<button type="button" class="btn btn-primary" @click="applyChanges(hide)">{{ $t('Done') }}</button>
			</div>
		</form>

	</template>
</Drawer>

<IconSelector
	v-if="showIconSelector"
	:isSelectable="() => true"
	:group="'activity'"
	:model="selectedItem"
	:classroom="classroomsStore.currentClassroom"
	@icon:selected="setIcon($event)"
	@request-close="showIconSelector = false"
/>

</template>

<script lang="ts">
import {mapStores} from "pinia";
import {usePupilsStore} from "@/stores/Pupils.store";
import {LessonItem} from "@/models/LessonItem.model";
import {Pupil} from "@/models/Pupil.model";
import {useClassroomsStore} from "@/stores/Classrooms.store";

import IconSelector from "@/components/IconSelector.vue";
import ActivitySymbol from "@/components/ui/symbols/ActivitySymbol.component.vue";
import NumberInput from '@/components/ui/NumberInput.vue';
import { PencilIcon } from "@heroicons/vue/24/outline";
import Drawer from '@/components/ui/Drawer.v2.vue';
import { Icon } from "@/models/Icon.model";
import { Symbol } from "@/models/Symbol.model";

export default {
	emits: [
		'requestClose',
		'update:modelValue',
	],

	props: {
		lessonItem: {
			type: LessonItem,	// LessonItem
			required: true,
		},
	},

	components: {
		IconSelector,
		ActivitySymbol,
		NumberInput,
		PencilIcon,
		Drawer,
	},

	data() {
		return {
			allSelected: false,
			someSelected: false,
			showIconSelector: false,
			selectedItem: null
		}
	},

	computed: {
		...mapStores(usePupilsStore, useClassroomsStore),

		panelTitle() {
			let title = this.$t('Settings');
			if( this.lessonItem.activity) {
				title += ' - ' + this.lessonItem.activity.name;
			}
			return title;
		},
	},

	async mounted() {
		await this.pupilsStore.load();
		this.updateAllSelected();
	},

	created	() {
		this.selectedItem = this.lessonItem.clone();
	},

	methods: {
		close() {
			this.$emit('requestClose');
		},

		applyChanges(closeDrawerCallback) {
			// ... update pupils attribute value
			this.$emit('update:modelValue', this.selectedItem);
			closeDrawerCallback();
		},

		toggleSelectAll(event) {
			if (this.selectedItem.allowedPupilIds.length > 0) {
				this.selectedItem.allowedPupilIds = [];
			} else {
				this.selectedItem.allowedPupilIds = this.pupilsStore.pupils.map(pupil => pupil.id);
			}

			this.updateAllSelected();
		},

		togglePupil(pupil: Pupil, selected: boolean) {
			this.selectedItem.setPupilSelected(pupil, selected);
			this.updateAllSelected();
		},

		updateAllSelected() {
			this.allSelected = this.selectedItem.allowedPupilIds.length >= this.pupilsStore.pupils.length;
			this.someSelected = this.selectedItem.allowedPupilIds.length > 0 &&
					this.selectedItem.allowedPupilIds.length < this.pupilsStore.pupils.length;

			this.$nextTick(() => {
				this.$refs.checkAll.indeterminate = this.someSelected;
				this.$refs.checkAll.checked = this.selectedItem.allowedPupilIds.length > 0;
			});
		},

		setIcon(eventData: Icon | Symbol) {
			const icon = eventData;

			if (icon instanceof Symbol) {
				this.selectedItem.icon = null;
				this.selectedItem.symbol = icon;
			} else if (icon instanceof Icon) {
				this.selectedItem.symbol = null;
				this.selectedItem.icon = icon;
			}
		},
	}
}
</script>
