<template>
	<div class="flex">
		<a :href="file.url" target="_blank" class="grow bg-base-100 hover:bg-base-200 flex items-center gap-3 px-1 py-1 rounded relative">

			<template v-if="!fileTypeIcon.url">
				<DocumentIcon class="w-5 h-5 shrink-0" />{{ file.name }}
			</template>
			<template v-else>
				<img :src="fileTypeIcon.url" class="w-5 h-5 shrink-0 rounded" />{{ file.name }}
			</template>

			<template v-if="fileTypeIcon && fileTypeIcon.storageIconUrl">
				<img :src="fileTypeIcon.storageIconUrl" class="absolute top-0 left-4 w-4 h-4 bg-white rounded" />
			</template>

		</a>

		<button v-if="!readOnly" type="button" class="btn btn-sm btn-ghost shrink-0" @click.prevent="$emit('remove')">
			<TrashIcon class="w-4 h-4" />
		</button>
	</div>
</template>


<script lang="ts">

import { File } from "@/models/File.model";
import { DocumentIcon, TrashIcon } from "@heroicons/vue/24/outline";

export default {

	emits: [
		'remove'
	],

	components: {
		DocumentIcon, TrashIcon,
	},

	computed: {
		fileTypeIcon() {
			return this.file.getFileTypeIcon() ?? null;
		},
	},

	props: {
		file: {
			type: File,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	}

}
</script>
