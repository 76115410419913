<template>

	<!-- Perhaps a little dirty to teleport things around, but in this case it feels like an acceptable trade-off. -->
	<Teleport to="head">
		<link rel="icon" href="/app-icons/default/apple-touch-icon_120.png" />
		<link rel="apple-touch-icon" sizes="120x120" href="/app-icons/default/apple-touch-icon_120.png" />
		<link rel="apple-touch-icon" sizes="152x152" href="/app-icons/default/apple-touch-icon_152.png" />
		<link rel="apple-touch-icon" sizes="167x167" href="/app-icons/default/apple-touch-icon_167.png" />
		<link rel="apple-touch-icon" sizes="180x180" href="/app-icons/default/apple-touch-icon_180.png" />
    	<meta name="msapplication-TileImage" content="/app-icons/default/apple-touch-icon_152.png" />
	</Teleport>

	<div class="h-screen flex overflow-hidden bg-base">

		<TheSidebar v-if="currentOrganisation" />

		<div class="flex flex-col flex-1 w-0 overflow-hidden">
			<div class="relative flex-shrink-0 flex h-16 mb-1 bg-base shadow header-navigation print:hidden">
				<div class="flex-1 px-5 flex justify-between items-center ml-14 lg:ml-0">
					<div class="flex-1 flex">
						<h1 class="text-2xl pl-2 font-semibold text-base-content capitalize">

							<slot name="pageheader">{{ title }}</slot>

						</h1>
					</div>

					<div v-if="me?.impersonating" class="flex items-center py-2 px-3 bg-red-500 text-sm impersonating-warning print:hidden rounded-md">
						<span class="flex p-1 rounded-md bg-red-800">
							<svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
								 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z">
									</path>
							</svg>
						</span>

						<p class="ml-3 font-medium text-white truncate">
							<span class="text-white">
								{{ $t('Representation mode') }}
							</span>
							<a href="javascript:void(0)" @click="stopImpersonating" class="inline-block ml-2 font-extrabold underline hover:no-underline">{{$t('Leave')}}</a>
						</p>
					</div>

					<div class="ml-4 flex items-center md:ml-6">
						<TheUserMenu v-if="me" :user="me"/>
					</div>
				</div>
			</div>

			<main class="flex-1 relative overflow-y-auto focus:outline-none px-4 pb-4">

				<slot/>

			</main>
		</div>
	</div>

	<TheNotifier />
</template>

<style>
@media print {
	@page { margin: 0; }
	body { margin: 1cm; }

	* {
		overflow: visible !important;
	}
}
</style>

<script lang="ts">
import { useUsersStore } from "@/stores/Users.store";
import {mapActions, mapState} from "pinia";

import TheSidebar from './TheSideBar.vue';
import TheUserMenu from './TheUserMenu.vue';
import TheNotifier from "@/components/TheNotifier.vue";
import { useOrganisationsStore } from "@/stores/Organisations.store";

export default {
	name: "TheStdLayout",

	props: {
		title: String,
	},

	computed: {
		...mapState(useUsersStore, ['me']),
		...mapState(useOrganisationsStore, ['currentOrganisation']),
	},

	methods: {
		...mapActions(useUsersStore, ['stopImpersonating'])
	},

	components: {
		TheSidebar,
		TheUserMenu,
		TheNotifier,
	}
}
</script>
