<template>
	<TheStdLayout>
		<div class="alert alert-error my-4">
			<p>{{ errorMessage }}</p>
		</div>
	</TheStdLayout>
</template>

<script lang="ts">
import TheStdLayout from "@/components/layout/TheStdLayout.vue";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {mapState} from "pinia";
import {useUsersStore} from "@/stores/Users.store";
import { useOrganisationsStore } from "@/stores/Organisations.store";

export default {
	components: {
		TheStdLayout,
	},

	data() {
		return {
			errorMessage: null,
		};
	},

	computed: {
		...mapState(useClassroomsStore, ['currentClassroom']),
		...mapState(useOrganisationsStore, ['currentOrganisation']),
		...mapState(useUsersStore, ['me']),
	},

	mounted() {
		this.selectedClassroom = this.currentClassroom;
		if (this.me.isSuperAdmin()) {
			this.$router.push({name: 'schools.list'});
		} else if (this.me.hasAdminPermissions()) {
			this.$router.push({name: 'user.list'});
		} else if (this.currentClassroom) {
			this.$router.push({name: 'classroom.agenda', params: {classroomId: this.currentClassroom.id}});
		} else if (!this.currentOrganisation && this.me.roles.length === 0) {
			this.errorMessage = this.$t('You do not seem to be a member of a school account for the moment');
		} else if (this.currentOrganisation.classrooms.length > 0) {
			this.$router.push({name: 'classroom.list'});
		} else {
			this.$router.push({name: 'lesson-plan-library.list'});
			// this.errorMessage = this.$t('#notification: no classroom assigned yet');
		}
	}
};
</script>
