export class FieldValidationError {

	public field: string;

	public messages: string[];

	constructor(
		field: string,
		messages: string[]
	) {
		this.field = field;
		this.messages = messages;
	}

	toString() {
		return this.messages.join(', ');
	}

}
