<template>
	<p>{{ selectedPupilSummary }}</p>
</template>

<script lang="ts">
import { LessonItem } from "@/models/LessonItem.model";
import { mapStores } from "pinia";
import { usePupilsStore } from "@/stores/Pupils.store";

export default {
	props: {
		lessonItem: {
			type: LessonItem,	// LessonItem
			required: true,
		},
	},

	computed: {
		...mapStores(usePupilsStore),

		selectedPupilSummary() {
			if (
				this.pupilsStore.pupils.length === 0 ||
				this.lessonItem.allowedPupilIds === null
			) {
				return '';
			}

			if (this.pupilsStore.pupils.length <= this.lessonItem.allowedPupilIds.length) {
				return this.$t('Everyone');
			} else if (this.lessonItem.allowedPupilIds.length === 0) {
				return this.$t('No pupils selected');
			} else if (this.pupilsStore.pupils.length - this.lessonItem.allowedPupilIds.length <= 3) {
				return this.getEveryoneExceptText(this.notSelectedPupils);
			} else if (this.lessonItem.allowedPupilIds.length > 0 && this.lessonItem.allowedPupilIds.length <= 3) {
				return this.getOnlyText(this.selectedPupils);
			} else {
				return this.$t('{pupils} pupils selected', {pupils: this.lessonItem.allowedPupilIds.length});
			}
		},

		notSelectedPupils() {
			return this.pupilsStore.pupils.filter(v => !this.lessonItem.isPupilSelected(v));
		},

		selectedPupils() {
			return this.pupilsStore.pupils.filter(v => this.lessonItem.isPupilSelected(v));
		}
	},

	methods: {
		getEveryoneExceptText(pupils) {
			return this.$t('Everyone, except {pupils}', {pupils: this.concatPupilNames(pupils)});
		},

		getOnlyText(pupils) {
			return this.$t('Only {pupils}', {pupils: this.concatPupilNames(pupils)});
		},

		/**
		 * Return a string with all pupil names concatenated and the word 'and' before the last name.
		 * @param pupils
		 */
		concatPupilNames(pupils) {
			if (pupils.length === 1) {
				return pupils[0].firstName;
			}

			let names = pupils.map(v => v.firstName);
			let last = names.pop();
			return names.join(', ') + ' ' + this.$t('and') + ' ' + last;
		}
	},

	mounted() {
		this.pupilsStore.load();
	}
}
</script>
