<template>
	<template>

		<Drawer :title="$t('Set theme period')" initialWidth="2xl" @requestUnmount="hide">

			<template #default="{ hide, resizeTo }">

				<form @submit.prevent="save" class="pt-4">

					<div class="drawer-content grid grid-cols-6 gap-4">

						<div class="col-span-6">
							<label v-text="$t('Period')" class="text-sm"></label>
							<div class="">
								<!--
								<DateRangeSelector ref="datepicker" v-model="vModelEditableCopy.dateRange" class="input input-bordered bg-base-100"   />
								-->

								{{ vModelEditableCopy.dateRange.start.toFormat('dd/MM/yyyy') }} - {{ vModelEditableCopy.dateRange.end.minus({ hours: 2 }).toFormat('dd/MM/yyyy') }}
							</div>
						</div>

						<div class="col-span-6">
							<label v-text="$t('Name')" class="text-sm"></label>
							<input type="text" ref="themeNameFld" autofocus autocomplete="off" spellcheck="false" v-model="vModelEditableCopy.name" class="input w-full" :class="[invalidName? 'border-red-500' : '']" />

							<span v-if="themesStore.errorMessage?.fields.name" class="text-red-600 text-xs">
								{{ themesStore.errorMessage?.fields.name }}
							</span>
						</div>

						<div v-if="organisationsStore.currentOrganisation.curriculum" class="col-span-6 mt-4 border-t">
							<div class="mt-4 py-2 flex flex items-center gap-2">
								<label v-text="$tc('Goal', 2)" class="text-sm"></label>
								<button type="button" class="btn btn-primary btn-sm btn-circle" @click="showGoalSelector = true">
									<PlusIcon class="w-5 h-5"></PlusIcon>
								</button>
							</div>

							<ul class="flex flex-col gap-4 py-6">
								<li v-for="goal in vModelEditableCopy.goals" :key="goal.id" class="flex gap-2" :class="[goal.isSubgoal()? 'pl-6 text-sm' : '']">
									<MapPinIcon class="shrink-0" :class="[goal.isSubgoal()? 'mt-1 w-3 h-3' : 'mt-0.5 w-4 h-4']" :style="{ color: goal.color ?? '#86efac' }"></MapPinIcon>
									<span class="text-base-content-light mr-2 shrink-0 w-24 overflow-hidden whitespace-nowrap text-ellipsis" :title="goal.fullPath">{{ goal.fullPath }}</span>
									<GoalTitle :goal="goal" titleClasses="whitespace-pre-wrap" />
									<div class="grow text-right pl-4">
										<button type="button" class="btn btn-sm btn-ghost btn-circle bg-base-100" @click="removeGoal(goal)">
											<TrashIcon class="w-4 h-4"></TrashIcon>
										</button>
									</div>
								</li>
							</ul>

						</div>
					</div>

					<div class="drawer-action-bar gap-2" :class="[themePeriod? 'justify-between' : 'justify-end']">
						<ActionConfirm position="top-start" class="w-80 text-danger font-bold" v-slot="confirmationDialog">
							<button v-if="themePeriod.id" type="button" class="btn" :class="[saving? 'btn-disabled':'btn-outline btn-danger']" @click="handleDeleteIntent(confirmationDialog)">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5"/>
							</button>
						</ActionConfirm>

						<div class="flex gap-2">
							<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
								{{ $t('Cancel') }}
							</button>
							<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
								<span v-show="saving" class="loading loading-spinner"></span>
								{{ $t('Save') }}
							</button>
						</div>
					</div>

				</form>

			</template>

		</Drawer>

		<GoalSelector
			v-if="showGoalSelector && organisationsStore.currentOrganisation.curriculum"
			:curriculum="organisationsStore.currentOrganisation.curriculum"
			context="theme"
			@requestUnmount="showGoalSelector = false"
			:selectedGoals="vModelEditableCopy.goals"
			@goalSelected="addGoal"
			@goalUnselected="removeGoal"
		/>

	</template>
</template>

<script lang="ts">
import { Theme } from "@/models/Theme.model";

import Drawer from "@/components/ui/Drawer.v2.vue";
import GoalTitle from "@/components/GoalTitle.vue";
import GoalSelector from "@/components/GoalSelector.vue";
import ActionConfirm from "@/components/ui/ActionConfirm.vue";
import DateRangeSelector from "@/components/ui/DateRangeSelector.vue";

import { TrashIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { MapPinIcon } from "@heroicons/vue/24/solid";
import {mapStores} from "pinia";
import {useThemesStore} from "@/stores/Themes.store";
import {useOrganisationsStore} from "@/stores/Organisations.store";
import {Goal} from "@/models/Goal.model";
import {UnsavedChanges} from "@/utils/UnsavedChanges.util";


export default {

	props: {
		themePeriod: {
			type: Theme,
			required: false,
		},
	},

	components: {
		Drawer,
		GoalSelector,
		GoalTitle,
		ActionConfirm,
		DateRangeSelector,
		MapPinIcon, TrashIcon, PlusIcon,
	},

	data() {
		return {
			saving: false,
			deleting: false,
			vModelEditableCopy: null,
			showGoalSelector: false,
			dateRange: null,
			invalidName: false,
		}
	},

	beforeMount() {
		this.vModelEditableCopy = this.themePeriod.clone();
	},

	mounted() {
		UnsavedChanges.observeModel(this.vModelEditableCopy, this.themePeriod);
	},

	unmounted() {
		UnsavedChanges.unobserveModel();
	},

	computed: {
		...mapStores(useThemesStore, useOrganisationsStore),
	},

	methods: {
		hide() {
			this.$emit('requestClose');
		},

		async save() {
			if (!this.vModelEditableCopy.name) {
				this.invalidName = true;
				this.$refs.themeNameFld.focus();
				return;
			}

			this.saving = true;

			try {
				await this.themesStore.store(this.vModelEditableCopy);
			} catch (e) {
				this.saving = false;
				return;
			}

			this.saving = false;
			this.hide();
		},

		async handleDeleteIntent(confirmationDialog) {
			confirmationDialog.setMessage( this.$t('Are you sure?') );
			const confirmed = await confirmationDialog.show();
			if(!confirmed) {
				return;
			}

			this.deleting = true;

			await this.themesStore.delete(this.vModelEditableCopy);
			this.hide();
		},

		addGoal(goal: Goal) {
			// avoid adding duplicates
			const goalAlreadyAdded = this.vModelEditableCopy.goals.some(
				(item) => item.equals(goal)
			);
			if(!goalAlreadyAdded) {
				this.vModelEditableCopy.goals.push(goal);
			}
		},

		removeGoal(goal: Goal) {
			// in case of a 'main goal', also remove any potential 'sub goals'
			if(goal.sourcePathId) {
				this.vModelEditableCopy.goals = this.vModelEditableCopy.goals.filter(
					(item) => item.sourceParentPath != goal.fullPath
				)
			}

			this.vModelEditableCopy.goals = this.vModelEditableCopy.goals.filter(
				(item) => !item.equals(goal)
			);
		},
	}
}

</script>
