import { Activity } from "./Activity.model";
import { Pupil } from "./Pupil.model";
import { LessonItem } from "@/models/LessonItem.model";
import { Observation } from "@/models/Observation.model";
import { Duration } from "luxon";
import { usePupilsStore } from "@/stores/Pupils.store";

export class PupilActivity {

	static mapFromServer(data: any, pupil: Pupil = null): PupilActivity {
		return (new PupilActivity()).setFromServerData(data, pupil);
	}

	public id ?: String;
	public checkin ?: Date;
	public checkout ?: Date;
	public activity ?: Activity;
	public pupil ?: Pupil;
	public lessonItem ?: LessonItem;
	public observations ?: Observation[];
	public feedback ?: number;
	public duration ?: Duration;

	setFromServerData(data: any, pupil: Pupil = null) {

		this.id = data.id;
		this.checkin = data.checkin;
		this.checkout = data.checkout;

		if (data.activity) {
			this.activity = Activity.mapFromServer(data.activity);
		}

		if (data.lessonItem) {
			this.lessonItem = LessonItem.mapFromServer(data.lessonItem);
		}

		if (pupil) {
			this.pupil = pupil;
		} else if (data.pupil) {
			this.pupil = usePupilsStore().findByIdOrMap(data.pupil);
		}

		if (data.observations) {
			this.observations = data.observations.map((observation: any) => {
				return Observation.mapFromServer(observation, this.pupil);
			});
		}

		if (data.feedback !== null) {
			this.feedback = data.feedback;
		}

		if (data.duration) {
			this.duration = Duration.fromMillis(data.duration * 1000);
		}

		return this;
	}

	removeObservation(observation: Observation) {
		this.observations = this.observations.filter((o: Observation) => o.id !== observation.id);
	}
}
