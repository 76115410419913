import { defineStore } from "pinia";
import { OrderDirection, OrderParameter } from "@/models/OrderParameter.model";

interface DefaultOrderStoreState {
	organisationsOrder: OrderParameter,
	pupilsOrder: OrderParameter,
	usersOrder: OrderParameter,
	iconLibrariesOrder: OrderParameter,
	lessonPlansOrder: OrderParameter,
}

export const useDefaultOrderStore = defineStore('defaultOrder', {

	/**
	 *
	 */
	state: (): DefaultOrderStoreState => {

		const state = {
			organisationsOrder: new OrderParameter('name', OrderDirection.ASC),
			pupilsOrder: new OrderParameter('firstName', OrderDirection.ASC),
			usersOrder: new OrderParameter('name', OrderDirection.ASC),
			iconLibrariesOrder: new OrderParameter('name', OrderDirection.ASC),
			lessonPlansOrder: new OrderParameter('createdAt', OrderDirection.DESC),
		}

		try {
			const localStorageDetails = localStorage.getItem('default_orders');
			if (localStorageDetails) {
				const details = JSON.parse(localStorageDetails);
				if (details.organisationsOrder) {
					state.organisationsOrder = new OrderParameter(details.organisationsOrder.property, details.organisationsOrder.direction);
				}

				if (details.pupilsOrder) {
					state.pupilsOrder = new OrderParameter(details.pupilsOrder.property, details.pupilsOrder.direction);
				}

				if (details.usersOrder) {
					state.usersOrder = new OrderParameter(details.usersOrder.property, details.usersOrder.direction);
				}

				if (details.iconLibrariesOrder) {
					state.iconLibrariesOrder = new OrderParameter(details.iconLibrariesOrder.property, details.iconLibrariesOrder.direction);
				}

				if (details.lessonPlansOrder) {
					state.lessonPlansOrder = new OrderParameter(details.lessonPlansOrder.property, details.lessonPlansOrder.direction);
				}
			}
		} catch (e) {
			console.error(e);
		}

		return state;

	},

	getters: {

	},

	actions: {

		setDefaultPupilsOrder(order: OrderParameter) {
			this.pupilsOrder = order;
			this.saveToLocalStorage();
		},

		setDefaultOrganisationsOrder(order: OrderParameter) {
			this.organisationsOrder = order;
			this.saveToLocalStorage();
		},

		setDefaultUsersOrder(order: OrderParameter) {
			this.usersOrder = order;
			this.saveToLocalStorage();
		},

		setDefaultIconLibrariesOrder(order: OrderParameter) {
			this.iconLibrariesOrder = order;
			this.saveToLocalStorage();
		},

		setDefaultLessonPlansOrder(order: OrderParameter) {
			this.lessonPlansOrder = order;
			this.saveToLocalStorage();
		},

		saveToLocalStorage() {
			localStorage.setItem('default_orders', JSON.stringify({
				organisationsOrder: this.organisationsOrder,
				pupilsOrder: this.pupilsOrder,
				usersOrder: this.usersOrder,
				iconLibrariesOrder: this.iconLibrariesOrder,
				lessonPlansOrder: this.lessonPlansOrder,
			}));
		},

	}

});
