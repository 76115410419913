import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";


class GoogleDriveAccessTokenClass {

	store(token: {
		access_token: string,
		expires_at: number,
		expires_in: number
		first_issued_at: number
		scope: string,
		session_state: any,
		token_type: string
	}) {

		const now = new Date();

		SmaSyLocalStorage.setItem("googleDriveAccessToken", token.access_token);
		SmaSyLocalStorage.setItem("googleDriveAccessTokenExpiry", now.setTime(now.getTime() + (token.expires_in * 1000)).toString());

	}

	getAccessToken() {
		if (this.isAccessTokenValid()) {
			return SmaSyLocalStorage.getItem("googleDriveAccessToken");
		}
		return null;
	}

	isAccessTokenValid() {
		const expiry = SmaSyLocalStorage.getItem("googleDriveAccessTokenExpiry");
		if (expiry) {
			return (parseInt(expiry) > Date.now());
		}
		return false;
	}

	clear() {
		SmaSyLocalStorage.removeItem("googleDriveAccessToken");
		SmaSyLocalStorage.removeItem("googleDriveAccessTokenExpiry");
	}

}

const GoogleDriveAccessToken = new GoogleDriveAccessTokenClass();

export {
	GoogleDriveAccessToken
};
