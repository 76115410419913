<template>

	<label v-if="customTrigger" :for="'file-input-' + inputId" class="cursor-pointer ml-4">
		<slot></slot>
	</label>
    <label v-else-if="label" class="block input-label">
		{{ label }}
	</label>
    <input
		type="file"
		v-bind="$attrs"
		:id="'file-input-' + inputId"
		class="file-input file-input-bordered w-full max-w-xs"
		:class="[customTrigger? 'hidden' : '']"
		:disabled="disabled"
		ref="fileInput"
		@input="updateValue()"
		:accept="accept"
	/>

    <span v-if="validationError" class="text-red-600 text-xs">
        {{ validationError.toString() }}
    </span>
</template>

<script lang="ts">

import { FieldValidationError } from "@/stores/errors/FieldValidationError";

export default {

	inheritAttrs: false,

	emits: [
    	'selectFile',
		'update:modelValue'
  	],

	props: {
		disabled: { default: false },
		modelValue: { type: String, default: 'label' },
		label: { type: String },
		clearAfterSelect: { type: Boolean, default: false },
		accept: { type: String, default: 'image/*' },
		customTrigger: { type: Boolean, default: false },
		validationError: { type: FieldValidationError }
  	},

	data() {
		return {
			value: null,
			inputId: null,
		}
	},

	mounted() {
		this.value = this.modelValue;
		this.inputId = Math.floor(Math.random() * 1000000000);
	},

  	methods: {
		updateValue() {
			this.$emit('update:modelValue', this.$refs.fileInput.files[0])
			this.$emit('selectFile', this.$refs.fileInput.files[0]);

			if (this.clearAfterSelect) {
				this.$refs.fileInput.value = null;
			}
		},

		openFileDialog() {
			this.$refs.fileInput.click();
		}
	}
}
</script>
