import {DateTime, Interval} from "luxon";
import { AbstractAgendaItemModel } from "@/models/AbstractAgendaItem.model";
import { Goal } from "@/models/Goal.model";

export class Theme extends AbstractAgendaItemModel{
	public name: string;
	public token: string;
	public goals: Goal[] = [];

	public setFromServerData(data: any) {
		this.id = data.id;
		this.name = data.name;
		this.token = data.token;
		this.start = new Date(data.start);
		this.end = new Date(data.end);

		if (data.goals) {
			this.goals = data.goals.map((goalData: any) => Goal.mapFromServer(goalData));
		}

		return this;
	}

	public setFromModel(theme: Theme) {
		this.id = theme.id;
		this.name = theme.name;
		this.token = theme.token;
		this.start = new Date(theme.start);
		this.end = new Date(theme.end);
		this.goals = [ ...theme.goals ];
		return this;

	}

	public get dateRange() {
		if (!this.start || !this.end) {
			return null;
		}

		return Interval.fromDateTimes(this.start, this.end);
	}

	public set dateRange(range: Interval) {

		if (!range) {
			this.start = null;
			this.end = null;
			return;
		}

		this.start = range.start.toJSDate();
		this.end = range.end.toJSDate();

	}

	public static mapFromServer(data: any) {
		return (new Theme()).setFromServerData(data);
	}

	public asApiObject() {
		const out: any = {
			name: this.name,
			start: this.start.toISOString(),
			end: this.end.toISOString(),
		};

		if (this.goals) {
			out.goals = this.goals.map(goal => goal.getLinkData());
		}

		return out;
	}

	public asFullCalendarEvent(): any {

		let startDate = DateTime.fromJSDate(this.start);
		let endDate = DateTime.fromJSDate(this.end);

		// We're going to do some hacking.
		// As we're using allDay events, we need to make sure that the dates are the actual dates; so we're going to add 12 hours to each date, and then call startOf('date')
		startDate = startDate.plus({ hour: 12 }).startOf('day');
		endDate = endDate.plus({ hour: 12 }).startOf('day');

		const out = {
			id: 'thema-' + this.id,
			title: this.name,

			start: startDate.toFormat('yyyy-MM-dd'),
			end: endDate.toFormat('yyyy-MM-dd'),

			extendedProps: {
				color: 'yellow',
				type: 'theme'
			},
			allDay: true,
			editable: false,
		};

		return out;
	}

	public clone() {
		return (new Theme()).setFromModel(this);
	}

	public equals(other: Theme): boolean {
		return this.name === other.name;
	}
}
