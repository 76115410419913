<template>
	<Modal @requestUnmount="$emit('cancel')">

		<template #header>
			<div class="font-semibold text-xl mb-4">{{ dialogTitle }}</div>
		</template>

		<div><p class="mb-3">{{ dialogMessage }}</p></div>

		<div class="flex gap-2">
			<button type="button" @click="$emit('confirm')" class="btn btn-danger flex-grow">{{ dialogConfirmLabel }}</button>
			<button type="button" @click="$emit('cancel')" class="btn btn-ghost flex-grow">{{ dialogCancelLabel }}</button>
		</div>
	</Modal>>
</template>

<script>

import Modal from "@/components/ui/Modal.vue";

export default {
	components: {Modal},
	props: {
		title: {
			type: String
		},
		message: {
			type: String
		},
		confirmLabel: {
			type: String
		},
		cancelLabel: {
			type: String
		}
	},
	emits: [
		'confirm',
		'cancel'
	],

	data() {
		return {
			dialogTitle: this.title,
			dialogMessage: this.message,
			dialogConfirmLabel: this.confirmLabel,
			dialogCancelLabel: this.cancelLabel
		}
	},

	created() {
		if (!this.dialogTitle) {
			this.dialogTitle = this.$t('Confirm');
		}

		if (!this.dialogMessage) {
			this.dialogMessage = this.$t('Are you sure?');
		}

		if (!this.dialogConfirmLabel) {
			this.dialogConfirmLabel = this.$t('Yes');
		}

		if (!this.dialogCancelLabel) {
			this.dialogCancelLabel = this.$t('No');
		}
	}
}

</script>
