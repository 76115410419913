<template>

	<div class="flex flex-col items-center mt-12">
		<FolderIcon class="w-10 h-10 text-base-content-light" />
		<h3 class="mt-2 text-sm font-semibold text-base-content">{{ title }}</h3>
		<p class="mt-4 text-sm text-base-content-light max-w-md" v-html="description ? description.replaceAll('\n', '<br>') : ''"></p>
		<div v-if="ctaLabel" class="mt-6">
			<button type="button" class="btn btn-sm btn-ghost" @click="ctaCallback">
				<span class="flex items-center justify-center bg-primary rounded-full p-1 mr-2">
					<PlusIcon class="w-4 h-4" />
				</span>
				{{ ctaLabel }}
			</button>
		</div>
	</div>

</template>

<script lang="ts">
import { FolderIcon, PlusIcon } from '@heroicons/vue/24/outline';

export default {
	props: [
		'title',
		'description',
		'ctaLabel',
		'ctaCallback'
	],
	components: {
		FolderIcon, PlusIcon
	},
}
</script>
