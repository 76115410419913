<template>

	<a @click="onClick" class="cursor-pointer select-none inline-flex items-center gap-2">
		<slot></slot>

		<span>
			<span v-if="isActive && localOrder.direction === 'asc'">
				<ChevronUpIcon class="w-3 h-3"/>
			</span>
			<span v-else-if="isActive" class="icon-sort-descending">
				<ChevronDownIcon class="w-3 h-3"/>
			</span>
		</span>
	</a>

</template>

<script lang="ts">
import { OrderDirection, OrderParameter } from "@/models/OrderParameter.model";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';

export default {

	data() {
		return {
			localOrder: null
		};
	},

	props: {
		order: {
			type: OrderParameter,
			required: true
		},

		property: {
			type: String,
			required: true
		},

		defaultDirection: {
			type: String,
			default: OrderDirection.ASC
		}
	},

	components: {
		ChevronDownIcon, ChevronUpIcon
	},

	created() {
		this.localOrder = this.order;
	},

	computed: {
		isActive() {
			return this.localOrder.property === this.property;
		}
	},

	methods: {
		onClick() {
			if (this.order.property === this.property) {
				this.order.toggleDirection();
			} else {
				this.order.property = this.property;
				this.order.direction = this.defaultDirection;
			}
			this.$emit('update:modelValue', this.order);
			this.$emit('change', this.order);
		}
	}

}
</script>
