export class FilterParamValue {
	id: string | number;
	label: string = null;
	meta: any = null;

	constructor(id: string | number, label: string, meta: any = {}) {
		this.id = id;
		this.label = label;
		this.meta = meta;
	}
}

export class FilterParam {
	name: string;
	values: FilterParamValue[] = [];

	constructor(name: string) {
		this.name = name;
	}
}

export abstract class FilterDef {
	filterParams: FilterParam[] = [];
	filterMap: Map<string, FilterParam> = new Map();

	public isDefault: boolean = false;

	constructor(
		params: string[]
	) {
		params.forEach((param) => {
			let filterParam = new FilterParam(param);
			this.filterParams.push(filterParam);
			this.filterMap.set(param, filterParam);
		});
	}

	clear() {
		this.filterParams.forEach((param) => {
			param.values = [];
		});
	}

	clearParam(paramName: string) {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			param.values = [];
		}
	}

	valueCount(): number {
		let count = 0;
		this.filterParams.forEach((param) => {
			count += param.values.length;
		});
		return count;
	}

	isEmpty(): boolean {
		return this.valueCount() === 0;
	}

	getParamValues(paramName: string): any[] {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			return param.values;
		} else {
			return [];
		}
	}

	setParamValues(paramName: string, values: FilterParamValue[]) {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			param.values = values;
		}
	}

	addParamValue(paramName: string, paramValue: FilterParamValue) {
		let filterParam = this.filterParams.find((param) => param.name === paramName);
		if (filterParam) {
			// check if already exists
			let exists = filterParam.values.find((value) => value.id === paramValue.id);
			if (!exists) {
				filterParam.values.push(paramValue);
			} else {
				console.warn(`FilterDef.addParamValue: value id '${paramValue.id}' already exists`);
			}
		} else {
			console.warn(`FilterDef.addParamValue: param '${paramName}' not valid`);
		}
	}

	removeParamValue(paramName: string, paramValue: FilterParamValue) {
		let filterParam = this.filterParams.find((param) => param.name === paramName);
		if (filterParam) {
			let index = filterParam.values.findIndex((value) => value.id === paramValue.id);
			if (index > -1) {
				filterParam.values.splice(index, 1);
			}
		} else {
			console.warn(`FilterDef.removeParamValue: param '${paramName}' not valid`);
		}
	}

	merge(filterDef: FilterDef) {
		filterDef.filterParams.forEach((param) => {
			let filterParam = this.filterParams.find((p) => p.name === param.name);
			if (filterParam) {
				param.values.forEach((value) => {
					this.addParamValue(param.name, value);
				});
			}
		});
	}

	toApiParams(): any {
		let params = {};
		this.filterParams.forEach((param) => {
			if (param.values.length > 0) {
				params[param.name] = param.values.map((value) => value.id).join(',');
			}
		});
		return params;
	}

	toJson(): any {
		return JSON.stringify(this);
	}

	fromJson(json: any) {
		if(!json.filterParams) {
			return;
		}

		this.clear();
		json.filterParams.forEach((param) => {
			param.values?.forEach((value) => {
				this.addParamValue(param.name, new FilterParamValue(value.id, value.label, value.meta));
			});
		});
	}

	clone(): FilterDef {
		return Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
	}

	equals(filterDef: FilterDef): boolean {

		if (this.valueCount() !== filterDef.valueCount()) {
			return false;
		}

		for (let k in this.filterMap) {
			let filterParam = this.filterMap.get(k);
			let otherFilterParam = filterDef.filterMap.get(k);
			if (filterParam.values.length !== otherFilterParam.values.length) {
				return false;
			}
			for (let i = 0; i < filterParam.values.length; i++) {
				if (filterParam.values[i].id !== otherFilterParam.values[i].id) {
					return false;
				}
			}
		}

		return true;

	}

	markDefault() {
		this.isDefault = true;
		return this;
	}
}
