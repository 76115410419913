<template>
<TheStdLayout>

	<template #pageheader>{{ $tc('Classroom', 2) }}</template>

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<FloatingPlusButton @push="handleCreateRequest" />

		<EmptyState v-if="loaded && classroomsStore.classrooms.length === 0"
			:title="$t('No classrooms yet')"
			:description="$t('Start with creating your first classroom')"
			:cta-label="$t('New classroom')"
			:cta-callback="handleCreateRequest"
		/>

		<table v-else class="divide-y divide-base-200 w-full">

			<thead>
				<tr>
					<th scope="col" class="table-col-title">
						{{ $t('Name') }}
					</th>
					<th></th>
					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr v-show="!loaded && classroomsStore.classrooms.length === 0">
					<td colspan="3">
						<Skeleton content-type="userlist" :items="3" class="m-4" thumbnail-classes="mask mask-squircle" />
					</td>
				</tr>

				<tr v-for="(classroom, key) in classroomsStore.classrooms" :key="key">
    				<td class="px-4 py-3 print:py-1">
						<span class="whitespace-nowrap">{{ classroom.name }}</span>
						<div class="text-sm text-base-content-light">
                            {{ $tc('Teacher', 2) }}:
							<template v-if="getAllTeachers(classroom).length > 0">{{ getAllTeachers(classroom).map(teacher => teacher.getNameIdentifier()).join(', ') }}</template>
							<template v-else>{{ $t('none') }}</template>
                        </div>
					</td>
					<td>
						<span class="badge badge-success">
							<UserIcon class="w-4 h-4 mr-1" />
							{{ classroom.pupilCount }}
						</span>
					</td>
    				<td class="px-4 py-3 whitespace-nowrap text-right text-sm font-medium flex justify-end gap-2 sm:gap-4 print:py-1">
      					<div class="flex-row print:hidden flex items-center gap-2">
							<button class="btn btn-ghost rounded-full bg-base-100 text-xs" @click="goToClassroom(classroom)">
								{{ $t('Go to classroom') }}
								<ArrowSmallRightIcon class="w-4 h-4 ml-2" />
							</button>
							<button class="btn btn-ghost btn-circle bg-base-100" @click="handleEditRequest(classroom)">
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>

				</tr>

			</tbody>
		</table>
  	</div>

	<ClassroomEditModal v-if="showFormModal" :classroom="classroom" @requestClose="closeEditPopup" />

</TheStdLayout>
</template>

<script lang="ts">
import {mapStores} from "pinia";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {Classroom} from "@/models/Classroom.model";

import Skeleton from '@/components/ui/Skeleton.vue';
import ClassroomEditModal from "@/components/ClassroomEditModal.vue";
import { PencilIcon, ArrowSmallRightIcon, UserIcon } from '@heroicons/vue/24/outline';
import {useUsersStore} from "@/stores/Users.store";

export default {

	data() {
		return {
			loaded: false,
			classroom: null,
			showFormModal: false,
		};
	},

	components: {
		Skeleton,
		ClassroomEditModal,
		PencilIcon, ArrowSmallRightIcon, UserIcon,
	},

	computed: {
		...mapStores(useClassroomsStore, useUsersStore),
	},

	methods: {

		handleCreateRequest() {
			this.showFormModal = true;
		},

		handleEditRequest(classroom) {
			this.classroom = classroom;
			this.showFormModal = true;
		},

		goToClassroom(classroom) {
			useClassroomsStore().switchClassroom(classroom);
		},

		async loadClassrooms() {
			await this.classroomsStore.load();
			this.loaded = true;
		},

		closeEditPopup() {
			this.classroom = null;
			this.showFormModal = false;
		},

		getAllTeachers(classroom) {
			return [].concat(
				classroom.getTeachers(),
				this.usersStore.admins
			).filter(user => user.active);
		},
	},

	mounted() {
		this.loadClassrooms();
		this.usersStore.load();
	},
};

</script>
