import { Classroom } from "./Classroom.model";

export class IconLibrary {

	static mapFromServer(data: any): IconLibrary {
		return (new IconLibrary).setFromServerData(data);
	}

	public id ?: string;
	public name ?: string;
	public group ?: string;
	public classroomId ?: string;

	constructor() {
	}

	/**
	 * @param data
	 */
	setFromServerData(data: any): IconLibrary {
		this.id = '' + data.id;
		this.name = data.name;
		this.group = data.group;

		if (data.classroom) {
			this.classroomId = data.classroom.id;
		}

		return this;
	}

	getGroupName() {
		if (!this.group) {
			return '';
		}

		return this.group.substring(0, 1).toUpperCase() + this.group.substring(1);
	}

	isEditable(scope: string) {
		switch (scope) {
			case 'global':
				return !this.classroomId;

			case 'classroom':
				return !!this.classroomId;
		}

		/*
		// @todo not sure if we want to check this here, or have it returned by the api
		if (currentClassroom) {
		  return currentClassroom.id === this.classroomId;
		} else {
		  return true;
		}*/
		return true;
	}

	getServerData() {
		const out: any = {
			name: this.name,
			group: this.group
		};

		if (this.classroomId) {
			out.classroom = {
				id: this.classroomId
			};
		}

		return out;
	}

	setFromModel(iconLibrary: IconLibrary) {
		this.id = iconLibrary.id;
		this.name = iconLibrary.name;
		this.group = iconLibrary.group;
		this.classroomId = iconLibrary.classroomId;
	}

	clone(): IconLibrary {
		const iconLibrary = new IconLibrary();
		iconLibrary.setFromModel(this);

		return iconLibrary;
	}

}
