<template>

	<router-link v-if="this.to" :to="this.to" :class="classes" active-class="bg-accent text-accent-content hover:bg-accent"><slot /></router-link>
	<a v-if="!this.to && this.href" :href="this.href" :class="classes"><slot /></a>

</template>

<script lang="ts">
export default {
    name: "SideBarMenuLink",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        href: {
            type: String
        },
        to: {
        }
    },

	data() {
			return {
				classes: [
					'group flex items-center text-base-content md:text-sm px-2 py-2 font-medium rounded-md',
					this.active ?
							'bg-accent text-accent-content'
							: 'hover:bg-base'
				]
			}
	}
}
</script>
