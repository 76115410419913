<template>
	<div class="editor-wrapper">
		<!-- important that both <textarea> and overlying <div> have exact same styles applied so that text doesn't jump
		when switching between edit and view mode -->
		<div
			class="rendered-content w-full"
			:class="[readOnly? readOnlyClasses + ' hover:cursor-default' : viewClasses + ' hover:cursor-text']"
			ref="rendered-content"
			:data-replicated-value="modelValue"
			@click.stop="toggleMode($event)"
		>
		</div>
		<textarea
			v-show="!readOnly && editMode"
			class="w-full resize-none"
			:class="editClasses"
			ref="editor"
			:value="modelValue"
			:placeholder="placeholder"
			@input="(event) => { updateValue(event); }"
			@blur="toggleMode($event)"
		>
        </textarea>
    </div>
</template>

<style>
.editor-wrapper {
    @apply grid;
}
.editor-wrapper .rendered-content, .editor-wrapper > textarea {
    grid-area: 1 / 1 / 2 / 2;
    @apply h-full;
}
.editor-wrapper .rendered-content::after {
	content: attr(data-replicated-value) " ";
}
.editor-wrapper .rendered-content {
	white-space: pre-wrap;
}
.editor-wrapper .rendered-content > h1 {
	@apply text-2xl font-bold;
}
</style>

<script lang="ts">

import { FieldValidationError } from "@/stores/errors/FieldValidationError";

export default {

	emits: ['update:modelValue'],

	props: {
		modelValue: {
			type: String,
			default: ''
		},
		validationError: {
			type: FieldValidationError
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		editClasses: {
			type: String,
			default: 'textarea'
		},
		viewClasses: {
			type: String,
			default: 'textarea'
		},
		readOnlyClasses: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			editMode: true,
			// https://www.yongliangliu.com/blog/rmark/
			supportedMarkup: [
				{
					// bold (** ... **)
					pattern: /\*\*\s?([^\n]+)\*\*/g,
					replacement: '<b>$1</b>',
					prefix: '**',
					suffix: '**'
				},
				{
					// hyperlinks (no markup required)
					pattern: /(https?:\/\/[^\s]+)/g,
					replacement: '<a href="$1" target="_blank">$1</a>'
				}
			],
		}
	},

	mounted() {
		if(this.modelValue) {
			this.toggleMode();
		}
	},

  	methods: {
		updateValue(event: Event) {
			this.$emit('update:modelValue', event.target.value);
		},

		toggleMode(event = null) {

			if(this.readOnly) {
				return;
			}

			if(event && event.target.tagName === 'A') {
				return;
			}

			// entering edit mode
			if(!this.editMode) {

				const selection = window.getSelection();
				let caretPos = selection.getRangeAt(0).startOffset;	// this is the caret position within the selected node (which can be a sub tag)

				let selectedNode = null;
				if(selection.anchorNode.parentNode !== this.$refs['rendered-content']) {	// selected node is a sub tag within the rendered content
					selectedNode = selection.anchorNode.parentNode;
				} else {
					selectedNode = selection.anchorNode;	// selected node is a text node
				}

				const allNodesinRenderedText = [...selectedNode.parentNode.childNodes];	// collection to array
				const precedingNodes = allNodesinRenderedText.slice(0, allNodesinRenderedText.indexOf(selectedNode));

				const precedingTextLength = precedingNodes.reduce((length, node) => {
					if(node.nodeType === 8) {	// comment node
						return length;
					}
					if(node.tagName) {
						length += this.markupCaretOffset(node.tagName);
					}
					return length + node.textContent.length;
				}, 0);

				if(selectedNode.nodeType !== 3) {
					caretPos += this.markupCaretOffset(selectedNode.tagName, true);
				}

				caretPos += precedingTextLength;

				this.$refs['rendered-content'].innerHTML = '';
				this.$refs['rendered-content'].dataset.replicatedValue = this.modelValue;

				setTimeout(() => {
					this.$refs['editor'].focus();
					this.$refs['editor'].setSelectionRange(caretPos, caretPos);
				}, 100);
			}

			// exiting edit mode
			if(this.editMode) {
				if(!this.modelValue) {
					return;
				}
				this.$refs['rendered-content'].dataset.replicatedValue = '';
				this.$refs['rendered-content'].innerHTML = this.renderInput(this.modelValue);
			}
			this.editMode = !this.editMode;
		},

		renderInput(text) {
			if(!text) {
				return '';
			} else {
				// escape html
				let t = document.createElement('textarea');
				t.textContent = text;
				text = t.innerHTML;

				this.supportedMarkup.forEach((markup) => {
					text = text.replace(markup.pattern, markup.replacement);
				});
            	return text
			}
        },

		markupWithTagPattern(tagName) {
			return this.supportedMarkup.find((markup) => {
				return markup.replacement.search('<' + tagName.toLowerCase() + '>') !== -1;
			});
		},

		markupCaretOffset(tagName, prefixOnly = false) {
			let offset = 0;
			let markup = this.markupWithTagPattern(tagName);
			if(markup) {
				if(markup.prefix) {
					offset += markup.prefix.length;
				}
				if(markup.suffix && !prefixOnly) {
					offset += markup.suffix.length;
				}
			}
			return offset;
		},
	}
}
</script>
