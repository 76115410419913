<template>
	<form @submit.prevent="() => false" class="flex flex-col divide-y divide-base-100">
		<div v-for="(listItem, index) in modelValue" :key="listItem" class="flex gap-1 items-center py-1">
			<span class="pr-2">&bullet;</span>
			<template v-if="!readOnly">
				<input type="text" :value="listItem" class="grow input bg-base hover:ring-1 focus:bg-base-100" @change="update($event, index)" />
				<button type="button" class="btn btn-circle btn-ghost" @click="remove(index)">
					<TrashIcon class="w-4 h-4" />
				</button>
			</template>
			<template v-else>
				<span class="py-2">{{ listItem }}</span>
			</template>
		</div>
	</form>
	<form v-if="!readOnly" @submit.prevent="add" class="flex items-center gap-1 justify">
		<span class="pr-2">&bullet;</span>
		<input type="text" class="input input-bordered w-full grow" ref="new-input" @blur="add($event)" />
	</form>
	<button v-if="!readOnly" type="button" class="btn btn-sm btn-primary btn-circle mt-4 ml-6">
		<PlusIcon class="w-4 h-4" />
	</button>
</template>

<script lang="ts">
import { TrashIcon, PlusIcon } from '@heroicons/vue/24/outline';

export default {

	props: {
		modelValue: {
			type: Array,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		TrashIcon, PlusIcon,
	},

	methods: {

		add(e) {
			let target = null;
			if(e.target.length) {	// form submission (enter key)
				target = e.target[0];
			} else {	// field update event (blur event)
				target = e.target;
			}

			if(!target.value) {
				return;
			}
			let updatedList = [...this.modelValue];
			updatedList.push(
				target.value
			);
			target.focus();
			this.$emit('update:modelValue', updatedList);
			target.value = '';
		},

		remove(index) {
			let updatedList = [...this.modelValue];
			this.$emit('update:modelValue', updatedList.toSpliced(index, 1));
		},

		update(e, index) {
			if (e.target.value == '') {
				this.remove(index);
				return;
			}
			let updatedList = [...this.modelValue];
			updatedList[index] = e.target.value;
			this.$emit('update:modelValue', updatedList);
		}

	}
}
</script>