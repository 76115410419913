<template>
    <div>
        <div class="expandable-content whitespace-pre-wrap no-wrap" :class="maxHeight? 'overflow-hidden' : ''">
            {{ text }}
        </div>
        <button type="button" v-if="showToggleButton" class="mt-1 hover:underline" @click="toggle">
            <span v-if="isExpanded">
                {{ $t('Show less') }}
                <ChevronUpIcon class="w-3 h-3 inline-block" />
            </span>
            <span v-else>
                {{ $t('Show more') }}
                <ChevronDownIcon class="w-3 h-3 inline-block" />
            </span>
        </button>
    </div>
</template>

<style scoped>
/*
transition only works if max-height is set to some high value rather than 'none',
which has an annoying side effect of delayed shrinking
.expandable-content {
    transition: max-height 0.5s ease-in-out;
} */
</style>

<script lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'TextBlock',
    props: {
        text: {
            type: String,
            required: false
        },
        linkClass: {
            type: String,
            default: ''
        },
        maxHeight: {
            type: String,
            default: null
        },
        renderUrls: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ChevronDownIcon,
        ChevronUpIcon
    },
    data() {
        return {
            isExpanded: false,
            showToggleButton: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.render();
        });
    },
    watch: {
        text() {
            this.$nextTick(() => {
                this.render();
            });
        }
    },
    methods: {
        render() {
            this.shrink();
            this.determineToggleButtonVisibility();
            if(this.renderUrls) {
                this.convertUrlsToLinks();
            }
        },
        determineToggleButtonVisibility() {
            if (this.hasOverflowingContent()) {
                this.showToggleButton = true;
            } else {
                this.showToggleButton = false;
            }
        },
        hasOverflowingContent() {
            const content = this.$el.querySelector('.expandable-content');
            return (content.scrollHeight - content.offsetHeight) > 2;
        },
        convertUrlsToLinks() {
            const content = this.$el.querySelector('.expandable-content');
            content.innerHTML = content.innerHTML.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" class="' + this.linkClass + '">$1</a>');
        },
        toggle() {
            this.isExpanded ? this.shrink() : this.expand();
        },
        expand() {
            this.$el.querySelector('.expandable-content').style.maxHeight = 'none';
            this.isExpanded = true;
        },
        shrink() {
            this.$el.querySelector('.expandable-content').style.maxHeight = this.maxHeight;
            this.isExpanded = false;
        }
    }
}
</script>
