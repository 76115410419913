<template>

	<div class="flex">
		<button type="button" @click="decrease()" class="btn btn-ghost btn-circle">
			<MagnifyingGlassMinusIcon class="w-5 h-5" />
		</button>
		<button type="button" @click="increase()" class="btn btn-ghost btn-circle">
			<MagnifyingGlassPlusIcon class="w-5 h-5" />
		</button>
	</div>

</template>


<script lang="ts">
import {
	MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon,
} from '@heroicons/vue/24/outline';

export default {
	props: {
		modelValue: { type: String },
		// modelValue: { type: Number, default: 4 },
		// min: { type: Number, default: 1 },
		// max: { type: Number, default: 10 },
		values: { type: Array, default: () => [] }
	},

	components: {
		MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon,
	},

	data() {
		return {
			size: this.modelValue,
			valueIndex: 0
		};
	},

	mounted() {
		this.valueIndex = Math.max(0, this.values.indexOf(this.modelValue));
	},

	methods: {
		updateValue() {
			this.$emit('update:modelValue', this.size)
		},

		increase() {
			if (this.values.length > 0) {
				if (this.valueIndex < this.values.length - 1) {
					this.valueIndex ++;
				}
				this.size = this.values[this.valueIndex];
				this.updateValue();
				return;
			}

			// if(this.size < this.max) {
			// 	this.size ++;
			// }
			this.updateValue();
		},

		decrease() {
			if (this.values.length > 0) {
				if (this.valueIndex > 0) {
					this.valueIndex --;
				}
				this.size = this.values[this.valueIndex];
				this.updateValue();
				return;
			}

			// if(this.size > this.min) {
			// 	this.size --;
			// }
			this.updateValue();
		}
	}
}
</script>
