<template>
<TheStdLayout :title="$t('Activity board')">

	<div class="mt-12 flex flex-col items-center gap-4">

		{{ $t('Open activity board') }}...

		<router-link :to="{ name: 'activity-board-select', query: { code: activityBoardConnectStore.token } }" target="_blank" class="btn btn-primary">
			{{ $t('On this device') }}
			<ArrowTopRightOnSquareIcon class="w-5 h-5 ml-2 mb-1" />
		</router-link>

		<span class="text-md font-semibold uppercase">{{ $t('or') }}</span>

		<div>
			<button type="button" class="btn" @click="infoCollapsed = !infoCollapsed">
				{{ $t('On another device') }}
				<ChevronDownIcon class="w-4 h-4 ml-2" v-show="!infoCollapsed" />
				<ChevronUpIcon class="w-4 h-4 ml-2" v-show="infoCollapsed" />
			</button>
			<p class="text-center italic pt-1">{{ $t('No login required') }}</p>
		</div>

		<div class="flex flex-col items-center">

			<div class="collapse collapse-arrow">
				<input type="checkbox" :checked="infoCollapsed" style="min-height: auto;" />

				<div class="collapse-content flex justify-center">

					<div class="border border-base-200 rounded-lg p-4 flex flex-col gap-8  w-full sm:w-11/12">
						<div class="alert alert-info flex gap-2 items-start">
							<InformationCircleIcon class="w-8 h-8 inline-block shrink-0" />
							<div>
								<p>{{ $t('The steps below allow you to connect a new device') }}</p>
								<p>{{ $t('These steps need to be executed only once for each new device.') }}</p>
							</div>
						</div>
						<div class="grid sm:grid-cols-9">
							<div class="sm:col-span-4">
								<ul class="steps steps-vertical">
									<li class="step step-primary">
										{{ $t('Open the browser on the other device') }}
									</li>
									<li class="step step-primary">
										<span class="block text-left">
											{{ $t('Navigate to') }} <span class="inline-block border-b-2 border-accent px-1 font-semibold">{{connectUrl }}</span>
										</span>
									</li>
									<li class="step step-primary h-24">
										<span class="block text-left">
											<p>{{ $t('Enter the code below and hit the \'Connect\' button') }}</p>
											<p class="flex justify-center">
												<span class="font-semibold text-lg py-1 tracking-widest">{{ activityBoardConnectStore.token }}</span>
											</p>
											<p class="text-sm text-base-content-light">{{ $t('This code is only valid for {x} days', { x: '7' }) }}</p>
										</span>
									</li>
									<li class="step step-primary">
										{{ $t('Done') }}!
									</li>
								</ul>
							</div>
							<div class="flex items-start justify-center mt-6">
								<span class="inline-block p-2 rounded-full bg-base-200 text-md font-semibold uppercase">{{ $t('or') }}</span>
							</div>
							<div class="sm:col-span-4 mt-8">
								<p class="font-semibold">{{ $t('Scan the QR code with the camera app on the other device.') }}</p>
								<p class="text-sm text-base-content-light mt-2">({{ $t('Older devices may not be able to scan QR codes with the camera app.') }})</p>

								<div class="flex justify-center p-4">
									<div class="QR-MOCK w-48 h-48 border mt-5 mb-5">
										<qrcode-vue v-if="loaded" :value="connectUrlWithToken" :size="200" level="L"></qrcode-vue>
									</div>
								</div>

								{{ $t('The camera app should present you a link to the activity board and allow you to open it in your browser.') }}
							</div>
						</div>
						<div class="text-center">
							{{ $t('If you wish to connect another device, just repeat the steps above.') }}
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>

</TheStdLayout>
</template>


<script lang="ts">
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline';
import { mapStores } from "pinia";
import { useActivityBoardConnectStore } from "@/stores/ActivityBoardConnect.store";
import QrcodeVue from 'qrcode.vue';


export default {
	components: {
		InformationCircleIcon, ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon,
		QrcodeVue
	},

	data() {
		return {
			loaded: false,
			infoCollapsed: false,
		}
	},

	computed: {
		...mapStores(useActivityBoardConnectStore),

		connectUrl() {
			return `${window.location.origin}/board`;
		},

		connectUrlWithToken() {
			return `${window.location.origin}/board?code=${this.activityBoardConnectStore.token}`;
		}
	},

	async mounted() {
		this.loaded = false;
		await this.activityBoardConnectStore.generateActivityBoardConnectToken();
		this.loaded = true;
	},
}
</script>
