import {IconLibrary} from "./IconLibrary.model";

/**
 * An icon is a custom uploaded icon (ie: not a symbol.)
 */
export class Icon {

  static mapFromServer(data: any): Icon {
    return (new Icon()).setFromServerData(data);
  }

  public id: number|null = null;
  public group: string = '';
  public url: string = '';
  public library: IconLibrary|null = null;

  constructor() {}

  setFromServerData(data: any) {
    this.id = data.id;
    this.group = data.group;
    this.url = data.url;

    if (data.library) {
      this.library = IconLibrary.mapFromServer(data.library);
    }

    return this;
  }

  getServerData() {
    return {
      id: this.id
    };
  }

  equals(icon: Icon | Symbol) {
    if (icon instanceof Symbol) {
    	return false;
    }

    return this.id === icon.id;
  }
}
