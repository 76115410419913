<template>

	<svg v-if="type === 'bad'" class="text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.71 15.3C7.42 15.6 7.43 16.07 7.72 16.36C8.02 16.65 8.49 16.65 8.78 16.35C9.63 15.49 10.77 15 12 15C13.22 15 14.37 15.49 15.21 16.34C15.5 16.64 15.98 16.64 16.27 16.35C16.57 16.06 16.57 15.59 16.28 15.29C15.16 14.15 13.63 13.5 12 13.5C10.37 13.5 8.83 14.16 7.71 15.3ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" id="e2TMzgzsCi" />
	</svg>

	<svg v-else-if="type === 'neutral'" class="text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM9 11.25C8.31 11.25 7.75 10.69 7.75 10C7.75 9.31 8.31 8.75 9 8.75C9.69 8.75 10.25 9.31 10.25 10C10.25 10.69 9.69 11.25 9 11.25ZM15 11.25C14.31 11.25 13.75 10.69 13.75 10C13.75 9.31 14.31 8.75 15 8.75C15.69 8.75 16.25 9.31 16.25 10C16.25 10.69 15.69 11.25 15 11.25ZM8.25 15C9 15 15 15 15.75 15C16.16 15 16.5 15.34 16.5 15.75C16.5 16.16 16.16 16.5 15.75 16.5C15 16.5 9 16.5 8.25 16.5C7.84 16.5 7.5 16.16 7.5 15.75C7.5 15.34 7.84 15 8.25 15Z" />
	</svg>

	<svg v-else-if="type === 'good'" class="text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
		<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.41 14.66C7.08 14.92 7.03 15.39 7.28 15.71C8.41 17.15 10.14 18 12 18C13.86 18 15.58 17.15 16.71 15.72C16.97 15.39 16.91 14.92 16.59 14.66C16.26 14.41 15.79 14.46 15.53 14.79C14.69 15.86 13.4 16.5 12 16.5C10.6 16.5 9.31 15.86 8.46 14.78C8.21 14.46 7.73 14.4 7.41 14.66ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" />
	</svg>

	<svg v-else-if="type === 'unknown'" class="text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
		<path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
	</svg>

</template>


<script lang="ts">
export default {
	props: {
		type: {
			type: String,
			required: true,
		},
	}
}
</script>
