<template>

	<template v-for="(item, index) in items" :key="index">
		<slot :item="item" :index="index" />
	</template>

	<div v-if="loading"  class="flex justify-center mt-2 mb-2">
		<span class="loading loading-spinner loading-sm"></span>
	</div>

	<div v-if="!loading && hasMore" class="text-center">
		<button @click="loadMore" class="btn btn-sm">{{ $t('Load more') }}</button>
	</div>

</template>

<script lang="ts">

export default {

	emits: [ 'loadMore' ],

	props: {
		items: {
			type: Array,
			required: true,
		},

		loading: {
			type: Boolean,
			default: false,
		},

		hasMore: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		onScroll(event) {
			const element: HTMLElement = event.target;
			if (element.scrollHeight - element.scrollTop === element.clientHeight) {
				this.loadMore();
			}
		},

		loadMore() {
			this.$emit('loadMore');
		}

	},

}

</script>
