import { ErrorMessage } from "./ErrorMessage";
import { AxiosResponse } from "axios";
import { FieldValidationError } from "./FieldValidationError";
import { GenericApiError } from "./GenericApiError";

export class ApiErrors extends ErrorMessage {

	public genericError ?: GenericApiError;

	public fields: { [fieldName: string]: FieldValidationError } = {};

	public response: AxiosResponse;

	static fromAxiosException(exception: any) {

		if (exception instanceof ApiErrors) {
			return exception;
		}

		const error = new ApiErrors();
		const response: AxiosResponse = exception.response;
		error.response = response;

		if (exception.code === 'ERR_NETWORK') {
			error.genericError = new GenericApiError(exception.message);
			return error;
		}

		switch (response?.status) {
			case 400:
			case 409:

				const validationErrors = response.data.errors;

				for (let fieldName in validationErrors) {
					if (!validationErrors.hasOwnProperty(fieldName)) {
						continue;
					}

					if (fieldName === '') {
						error.genericError = new GenericApiError(validationErrors[fieldName]);
						continue;
					}

					error.fields[fieldName] = new FieldValidationError(fieldName, validationErrors[fieldName]);
				}
				break;

			case 403:
				error.genericError = new GenericApiError('This action is unauthorized.')
				break;

			case 500:
				if (typeof (response.data.errors) !== 'undefined') {
					error.genericError = new GenericApiError('Server error: ' + response?.data.errors['']);
				} else {
					error.genericError = new GenericApiError('Server error: ' + response?.data.message)
				}
				break;

			// don't process anything that is not in this list.
			default:
				throw exception;
		}

		return error;
	}

	getForField(... path: string[]) {
		return this.fields[path.join('.')] || null;
	}

	toString() {
		let errors = [];
		if (this.genericError) {
			errors.push(this.genericError.toString());
		}

		for (let fieldName in this.fields) {
			if (!this.fields.hasOwnProperty(fieldName)) {
				continue;
			}
			errors.push(this.fields[fieldName].toString());
		}
		return errors.join('\n');
	}
}
