<template>
	<TheStdLayout>

		<template #pageheader>
			{{ $tc('Icon library', 2) }}
			<span v-if="library" class="inline-flex items-center">
			<ChevronRightIcon class="w-4 h-4 mx-2"/>
			{{ library.name }}
		</span>
		</template>

		<div class="py-2 px-6 border-b border-base-200 flex justify-between gap-2 print:hidden">

			<router-link :to="returnUrl" class="flex items-center gap-1 shrink-0">
				<button class="btn btn-ghost">
					<ArrowSmallLeftIcon class="w-4 h-4 mr-2"/>
					{{ $t('Return') }}
				</button>
			</router-link>

			<div class="flex gap-3 items-center text-sm overflow-hidden" v-if="scope === 'classroom'">
				<label class="text-right whitespace-nowrap overflow-hidden text-ellipsis" @click="showUsedIconsOnly = false">{{ $t('All icons') }}</label>
				<input type="checkbox" class="toggle toggle-sm bg-neutral" v-model="showUsedIconsOnly"/>
				<label class="whitespace-nowrap overflow-hidden text-ellipsis" @click="showUsedIconsOnly = true">{{ $t('Used icons') }}</label>
			</div>

			<div class="flex gap-8">
				<!-- w-[28rem] w-[32rem] => making sure these classes are not purged -->
				<Zoom v-model="size"
					  :values="[ '4', '8', '12', '16', '20', '24', '28', '32', '36', '40', '44', '48', '52', '56', '60', '64', '72', '80', '96', '[28rem]', '[32rem]' ]"/>
				<button type="button" class="btn btn-ghost" onclick="window.print();">
					<PrinterIcon class="w-6 h-6"/>
				</button>
			</div>

		</div>

		<div class="px-4 pb-6 pt-5 mx-auto sm:px-6 md:px-8">

			<div>
				<FloatingPlusButton v-if="library && library.isEditable(this.scope)" @push="openNewIconUploader"/>

				<EmptyState v-if="loaded && unfilteredIcons.length === 0"
							:title="$t('No icons yet')"
							:description="$t('Start with adding your first icon')"
							:cta-label="$t('New icon')"
							:cta-callback="openNewIconUploader"
				/>

				<p v-if="loaded && unfilteredIcons.length > 0 && icons.length === 0">
					{{ $t('No icons match the filter') }}
				</p>

				<p v-if="loaded && unfilteredStdIcons.length > 0 && stdIcons.length === 0">
					{{ $t('No icons match the filter') }}
				</p>

				<LoadingScreen v-if="loading" />

				<div class="flex flex-wrap gap-4 items-center">

					<div v-for="icon in icons" class="p-7" style="page-break-inside: avoid;">
						<button type="button" @click="openIconEditor(icon)" :class="'w-' + size"
								class="aspect-square rounded-lg hover:bg-base-100 hover:border border-base-100">
							<img :src="icon.url" class="w-11/12 aspect-square rounded-lg m-auto"/>
						</button>
					</div>

					<div v-for="icon in stdIcons" class="p-7 flex items-center justify-center"
						 style="page-break-inside: avoid;">
						<svg :class="'w-' + size" class="aspect-square" fill="currentColor" viewBox="0 0 24 24"
							 xmlns="http://www.w3.org/2000/svg">
							<use :href="icon.url"></use>
						</svg>
					</div>

				</div>
			</div>
		</div>

		<IconEditModal v-if="showIconDialog" :icon="selectedIcon" :library="library"
					   :editable="library.isEditable(this.scope)" @requestClose="closeIconPopup"/>

	</TheStdLayout>
</template>


<script lang="ts">
import {mapStores} from "pinia";
import {Icon} from "@/models/Icon.model";
import {useIconsStore} from "@/stores/Icons.store";
import {useIconLibrariesStore} from "@/stores/IconLibraries.store";

import {useClassroomsStore} from "@/stores/Classrooms.store";
import IconEditModal from "@/components/IconEditModal.vue";
import iconLibrary from "@/components/IconLibrary.vue";
import Zoom from "@/components/ui/base/Zoom.component.vue";
import EmptyState from "./ui/EmptyState.vue";

import {
	ArrowSmallLeftIcon, PlusIcon, ChevronRightIcon, PrinterIcon
} from '@heroicons/vue/24/outline';
import {usePupilsStore} from "@/stores/Pupils.store";
import {useActivitiesStore} from "@/stores/Activities.store";
import LoadingScreen from "@/components/ui/LoadingScreen.vue";

export default {
	props: {
		create: {
			type: String,
			default: '0'
		},

		scope: String,
	},

	components: {
		LoadingScreen,
		IconEditModal,
		Zoom,
		EmptyState,
		// 'custom-icon' : CustomIcon,
		ArrowSmallLeftIcon, PlusIcon, ChevronRightIcon, PrinterIcon
	},

	data() {
		return {
			loading: false,
			loaded: false,
			library: null,
			classroomId: null,
			size: '16',
			svgIcons: [],
			breadcrumbs: [],
			showIconDialog: false,
			selectedIcon: null,
			showUsedIconsOnly: false,
		}
	},

	async mounted() {

		this.loadIcons(this.$route.params.libraryId);

		this.activitiesStore.load();
		this.pupilsStore.load();

		if (this.$route.params.classroomId) {
			this.classroomId = this.$route.params.classroomId;
		}

		this.$watch(
			() => this.$route.params,
			(toParams, previousParams) => {
				// react to route changes...
				this.loadIcons(toParams.libraryId);

				if (this.$route.params.classroomId) {
					this.classroomId = this.$route.params.classroomId;
				}
			}
		);

	},

	methods: {
		iconLibrary() {
			return iconLibrary
		},

		async loadIcons(id) {

			this.loaded = false;
			this.loading = true;

			switch (id) {

				case 'activities':
					await this.loadStandardActivities();
					break;

				case 'pupils':
					await this.loadStandardPupils();
					break;

				default:

					await this.iconsStore.loadAllIcons();

					this.library = await this.iconLibrariesStore.getFromId(id);
					this.loaded = true;

					// automatically open create popup
					try {
						if (window.history.state && window.history.state.create == '1') {
							this.openNewIconUploader();
						}
					} catch (e) {
						// ignore.
					}
					break;
			}

			this.loading = false;

		},

		openNewIconUploader() {
			this.selectedIcon = null;
			this.showIconDialog = true;
		},

		openIconEditor(icon) {
			this.showIconDialog = true;
			this.selectedIcon = icon;
		},

		closeIconPopup() {
			this.showIconDialog = false;
			this.selectedIcon = null;
		},

		async loadStandardActivities() {
			this.svgIcons = await this.iconsStore.loadStandardActivityIcons();

			this.breadcrumbs = [
				{name: this.$t('Custom icons'), path: '/icon-libraries'},
				{name: this.$tc('Activity', 2), path: '/icon-libraries/activities'}
			];
		},

		async loadStandardPupils() {
			this.svgIcons = await this.iconsStore.loadStandardPupilIcons();

			this.breadcrumbs = [
				{name: this.$t('Custom icons'), path: '/icon-libraries'},
				{name: this.$tc('Pupil', 2), path: '/icon-libraries/pupils'}
			];
		},

		isInUse(icon: Icon | Symbol) {
			return this.pupilsStore.isIconUsed(icon) ||
				this.activitiesStore.isIconUsed(icon)
			;
		}
	},

	computed: {
		...mapStores(useIconsStore, useIconLibrariesStore, usePupilsStore, useActivitiesStore),

		classroom() {
			return useClassroomsStore().currentClassroom
		},

		unfilteredIcons() {
			if (
				!this.library ||
				!this.iconsStore.allIcons
			) {
				return [];
			}

			return this.iconsStore.allIcons.filter(v => v.library.id === this.library.id);
		},

		icons() {
			let icons = this.unfilteredIcons;
			if (this.showUsedIconsOnly) {
				icons = icons.filter(icon => this.isInUse(icon));
			}

			return icons;
		},

		unfilteredStdIcons() {
			return this.svgIcons;
		},

		stdIcons() {
			let icons = this.unfilteredStdIcons;
			if (this.showUsedIconsOnly) {
				icons = icons.filter(icon => this.isInUse(icon));
			}

			return icons;
		},

		returnUrl() {
			switch (this.scope) {
				case 'classroom':
					if (!this.classroomId) {
						return {
							name: 'icon-library.list',
							params: {}
						};
					}

					return {
						name: 'classroom.icon-library.list',
						params: {
							classroomId: this.classroomId
						}
					};

				case 'global':
				default:
					return {
						name: 'icon-library.list',
						params: {}
					};
			}
		}
	}
}

</script>
