import { defineStore } from "pinia";
import { orgApi } from "@/utils/Api.util";
import { UploadingFile } from "@/models/UploadingFile.model";
import { File } from "@/models/File.model";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { FileExternal } from "@/models/FileExternal.model";

export const useUploadStore = defineStore('upload', {
	state: () => ({
		currentUpload: null,
		uploading: []
	}),

	actions: {
		upload(file: any,  callback: Function, errorCallback: Function) {
			const formData = new FormData();
			formData.append('file', file);

			this.currentUpload = new UploadingFile();
			this.uploading.push(this.currentUpload);
			const upload = this.currentUpload;

			upload.file = file;
			upload.uploading = true;
			upload.progress = 0;

			orgApi.post('files', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (progressEvent) => {
					upload.progress = progressEvent.progress
				}
			}).then((response) => {
				upload.uploading = false;
				upload.error = false;

				try {
					response.data.data.forEach((file) => {
						callback(File.mapFromServer(file));
					});
				} catch (e) {
					console.error(e);
				}
			}).catch((error) => {

				upload.uploading = false;
				upload.error = true;

				try {
					const validationError = ApiErrors.fromAxiosException(error);
					upload.errorMessage = validationError.toString();
				} catch (e) {
					upload.errorMessage = error.response.data.message;
				}

				errorCallback(upload.errorMessage);

			});

			return this.currentUpload;
		},

		/**
		 * @param service
		 * @param file
		 */
		async addFromExternalStorage(file: FileExternal) {

			const response = await orgApi.post('external-files', {

				name: file.name,
				url: file.url,
				mimeType: file.mimeType,
				service: file.service

			});

			return File.mapFromServer(response.data.data[0]);

		}
	}
});
