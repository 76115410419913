<template>

	<pupil-custom-icon v-bind="$attrs"
		v-if="pupilClassroomAttributes && pupilClassroomAttributes.icon"
		:color="pupilClassroomAttributes?.color"
		:icon="pupilClassroomAttributes?.icon"
		style="display: block"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

	<pupil-standard-icon v-bind="$attrs"
		v-else-if="pupilClassroomAttributes && !pupilClassroomAttributes.icon && pupilClassroomAttributes?.symbol"
		:color="pupilClassroomAttributes?.color"
		:symbol="pupilClassroomAttributes.symbol"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

	<pupil-standard-icon v-bind="$attrs"
		v-else
		:symbol="null"
		:color="pupilClassroomAttributes?.color"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

</template>

<script lang="ts">

import PupilCustomIcon from '@/components/ui/symbols/PupilSymbolCustomIcon.vue';
import PupilStandardIcon from '@/components/ui/symbols/PupilSymbolStandardIcon.vue';
import { Classroom } from '@/models/Classroom.model';
import {Pupil} from "@/models/Pupil.model";


export default {
	inheritAttrs: false,
	props: {
		pupil: {
			type: Pupil,
			required: true
		},
		classroom: {
			type: Classroom,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		withBorder: {
			type: Boolean,
			default: true
		}
	},

	components: {
		"pupil-custom-icon": PupilCustomIcon,
		"pupil-standard-icon": PupilStandardIcon
	},

	computed: {
		pupilClassroomAttributes() {
			if (!this.pupil) {
				return null;
			}
			return this.pupil.getClassroomRelation(this.classroom) ?? null;
		}
	}
}
</script>
