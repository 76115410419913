<template>
	<span class="tag">
		<span class="tag-label">
			<slot></slot>
		</span>
		<button type="button" v-if="removeable" @click="$emit('remove')" class="ml-1 rounded hover:bg-black/10 p-0.5">
			<XMarkIcon class="w-4 h-4" />
		</button>
	</span>
</template>


<style scoped>
.tag {
	@apply rounded-md inline-flex self-start items-center px-2 py-1 text-sm ring-1 ring-inset bg-violet-100 ring-violet-600/10;
}
.tag.tag-xs {
	@apply text-xs py-0.5;
}
.tag.tag-long span.tag-label {
	@apply max-w-sm md:max-w-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
}
.tag.tag-activity, .tag.tag-sky {
	@apply bg-sky-100 ring-sky-600/10;
}
.tag.tag-goal {
	@apply bg-green-100 ring-green-600/10;
}
.tag.tag-method {
	@apply bg-red-100 ring-red-600/10;
}
.tag.tag-theme {
	@apply bg-yellow-100 ring-yellow-600/10;
}
.tag.tag-keyword {
	@apply bg-orange-100 ring-orange-600/10;
}
.tag.tag-with-icon::before {
	/* https://heroicons.com/ - tag (https://yoksel.github.io/url-encoder/) */
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M5.25 2.25a3 3 0 0 0-3 3v4.318a3 3 0 0 0 .879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 0 0 5.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 0 0-2.122-.879H5.25ZM6.375 7.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}
.tag.tag-keyword.tag-with-icon::before {
	/* https://heroicons.com/ - magnifying-glass */
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}
.tag-xs.tag-with-icon::before, .tag-xs.tag-keyword.tag-with-icon::before {
	width: 0.75rem;
	height: 0.75rem;
}
</style>


<script lang="ts">

import { XMarkIcon } from '@heroicons/vue/24/outline';

export default {

	emits: ['remove'],

	props: {
		removeable: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		XMarkIcon
	},
}
</script>