<template>
	<ul class="rounded p-2 flex flex-wrap gap-1 focus-within:border focus-within:ring-accent focus-within:ring-2" :class="[readOnly? '' : 'border bg-base-100']">
		<li v-for="(tag, index) in modelValue" :key="index">
			<Tag :class="tagClasses" :removeable="!readOnly" @remove="removeTag(index)">{{ tag.name }}</Tag>
		</li>
		<li class="inline-block relative grow">
			<input type="text"
				v-model="inputValue"
				@keydown.tab="event => handleTab(event)"
				@keydown.enter="event => handleEnter(event)"
				@input="handleChangedInput"
				@blur="event => handleLostFocus(event)"
				class="w-full h-full text-sm border-0 focus:outline-none bg-transparent"
				:disabled="readOnly"
			/>
			<Dropdown v-show="suggestions.length" position="top-start" class="suggestions-dd">
				<div class="bg-base px-1 py-1 mt-1 text-sm shadow-md border rounded">
					<p class="border-b text-xs uppercase font-bold px-2 py-1 mb-1">{{ $t('Suggestions') }}</p>
					<ul class="" style="min-width: 8rem;">
						<li v-for="suggestion in suggestions" class="py-1 px-2 rounded cursor-pointer w-full hover:bg-base-100 whitespace-nowrap text-ellipsis overflow-hidden" style="max-width: 10rem;" @click="addTag(suggestion)">
							{{ suggestion }}
						</li>
					</ul>
				</div>
			</Dropdown>
		</li>
	</ul>
</template>


<script lang="ts">

import { Tag as TagModel } from "@/models/Tag.model";
import Tag from "@/components/ui/Tag.vue";
import Dropdown from "@/components/ui/Dropdown.v2.vue";
import { FieldValidationError } from "@/stores/errors/FieldValidationError";


export default {

	emits: ['update:modelValue'],

	props: {
		modelValue: {
			type: Array<Tag>,
			default: () => []
		},
		tagPool: {
			type: Array<Tag>,
			default: () => []
		},
		tagClasses: {
			type: String,
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: ''
		},
		validationError: {
			type: FieldValidationError
		}
	},

	components: {
		Tag,
		Dropdown,
	},

	data() {
		return {
			inputValue: '',
			suggestions: [],
			suggestionTimer: null,
		}
	},

	methods: {

		handleTab(event: KeyboardEvent) {
			if(!this.inputValue.length) {
				// default tab behavior (jump to next input) if no input value
				return;
			}
			event.preventDefault();
			if (this.inputValue.length > 0) {
				this.addTag(this.inputValue.trim());
			}
		},

		handleEnter(event: KeyboardEvent) {
			event.preventDefault();
			if (this.inputValue.length > 0) {
				this.addTag(this.inputValue.trim());
			}
		},

		handleLostFocus(event: FocusEvent) {
			// don't do anything if focus is lost to the dropdown with suggestions
			if(event.relatedTarget && event.relatedTarget.closest('.suggestions-dd')) {
				return;
			}

			if (this.inputValue.length > 0) {
				this.addTag(this.inputValue.trim());
			}
		},

		addTag(tagName) {
			this.resetSuggestions();
			let updatedTags = [...this.modelValue];
			// check if tag already exists
			if(updatedTags.find(existingTag => existingTag.name.toLowerCase() === tagName.toLowerCase())) {
				this.inputValue = '';
			} else {

				const tag = new TagModel();
				tag.name = tagName;
				// tag.type = this.tagType;

				updatedTags.push(tag);

				this.inputValue = '';
				this.$emit('update:modelValue', updatedTags);
			}
		},

		removeTag(index) {
			let updatedTags = [...this.modelValue];
			updatedTags.splice(index, 1);
			this.$emit('update:modelValue', updatedTags);
			// TODO sugestions are not updated when removing a tag
		},

		handleChangedInput() {
			if(!this.tagPool.length) {
				return;
			}
			if(this.suggestionTimer) {
				clearTimeout(this.suggestionTimer);
			}
			this.suggestionTimer = setTimeout(() => {
				this.updateSuggestions();
			}, 200);
		},

		resetSuggestions() {
			this.suggestions = [];
		},

		updateSuggestions() {
			if(!this.tagPool.length) {
				return;
			}

			if(this.inputValue.length < 2) {
				this.resetSuggestions();
				return;
			} else {
				let suggestions = this.tagPool
					.filter(tag => tag.name.toLowerCase().includes(this.inputValue.toLowerCase()))
					.filter(tag => !this.modelValue.find(existingTag => existingTag.name.toLowerCase() === tag.name.toLowerCase()))
					.map(tag => tag.name);

				this.suggestions = suggestions;
			}
		}
	}
}
</script>
